import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import {
    Resolve,
    RouterStateSnapshot,
    ActivatedRouteSnapshot
} from '@angular/router';
import { EventService } from '../services/event/event.service';

@Injectable()
export class EventDivisionsResolver implements Resolve<Observable<any>> {

    constructor(private eventService: EventService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.eventService.getDivisions(route.params.eventId);
    }
}
