import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AdminUserService } from '../../services/admin/user/user.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from '../../interfaces';

@Component({
    selector: 'bss-manage-user',
    templateUrl: './manage-user.component.html',
    styleUrls: ['./manage-user.component.scss']
})
export class ManageUserModalComponent implements OnInit {
    loading = false;
    saving = false;
    userId: number;
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    operatorId: number;
    public manageUserForm = new FormGroup({
        firstName: new FormControl(null, {
            validators: Validators.required
        }),
        lastName: new FormControl(null, {
            validators: Validators.required
        }),
        phone: new FormControl(null, {
            validators: [Validators.required, Validators.pattern(/^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$/im)],
            updateOn: 'blur'
        }),
        email: new FormControl(null, {
            validators: [Validators.required, Validators.email],
            updateOn: 'blur'
        }),
        operatorId: new FormControl(null, {}),
        role: new FormControl(null, {
            validators: [Validators.required]
        })
    });

    constructor(public activeModal: NgbActiveModal, public userService: AdminUserService) {
    }

    ngOnInit() {
        if (this.userId) {
            this.getUser();
        }
    }

    /**
     *  Retrieves an individual user via user service
     *  On http success, sets form values of class property `manageUserForm`
     */
    getUser() {
        this.loading = true;
        this.userService.getById(this.userId)
        .subscribe(
            (data: User) => {
                const role = data.roles[0];
                this.manageUserForm.setValue({
                    firstName: data.first_name,
                    lastName: data.last_name,
                    phone: data.phone,
                    email: data.email,
                    operatorId: data.operator_id,
                    role: role ? role.name : null
                });
            },
            err => {
            },
            () => {
                this.loading = false;
            }
        );
    }

    /**
     * Event handler for manageUserForm submission
     */
    onSubmit() {
        if (this.manageUserForm.valid) {
            this.userId ? this.updateUser() : this.createUser();
        } else {
            // trigger control validation if form is not valid
            (<any>Object).values(this.manageUserForm.controls).forEach((control: FormControl) => {
                control.markAsDirty();
            });
        }
    }

    /**
     * Creates a new user via the user service.
     * On http success, closes the modal
     */
    createUser() {
        this.saving = true;
        const formValues = this.manageUserForm.value;
        this.userService.create(formValues.firstName, formValues.lastName, formValues.email, formValues.phone, formValues.operatorId, formValues.role)
        .subscribe(
            (userId: number) => {
                this.activeModal.close(userId);
            },
            (err: any) => {
                console.log('Create User failed', err);
            },
            () => {
                this.saving = false;
            }
        );
    }

    /**
     * Updates an existing user via the user service.
     * On http success, closes the modal
     */
    updateUser() {
        this.saving = true;
        const formValues = this.manageUserForm.value;
        this.userService.update(this.userId, formValues.firstName, formValues.lastName, formValues.email, formValues.phone, formValues.operatorId, formValues.role)
        .subscribe(
            () => {
                this.activeModal.close(this.userId);
            },
            (err: any) => {
                console.log('Update User failed', err);
            },
            () => {
                this.saving = false;
            }
        );
    }
}
