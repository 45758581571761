import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';

@Component({
    selector: 'bss-templates',
    templateUrl: './templates.page.html',
    styleUrls: ['./templates.page.scss']
})
export class TemplatesPage implements OnInit {
    isBracketsPage: (url) => any;

    constructor(public router: Router) {
        // use memoize to limit calculations
        this.isBracketsPage = _.memoize((url) => {
            const urlSplit = url.split('/');

            return (urlSplit[3] && urlSplit[3] === 'brackets');
        }, (url) => {
            return url;
        });
    }

    ngOnInit() {
    }
}
