import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragAndDropModule } from 'angular-draggable-droppable';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NguiDatetimePickerModule } from '@ngui/datetime-picker';
import { SchedulerRoutingModule } from './scheduler-routing.module';
import { MatSlideToggleModule } from '@angular/material';
import { ResizableModule } from 'angular-resizable-element';
import { SharedModule } from '../shared/shared.module';
import { MatMenuModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// Pages
import { LoginPage } from './pages/login/login.page';
import { MasterPage } from './pages/master/master.page';
import { TeamsAndDivisionsPage } from './pages/teams-and-divisions/teams-and-divisions.page';
import { DivisionsPage } from './pages/divisions/divisions.page';
import { EventListPage } from './pages/event-list/event-list.page';
import { DivisionsMainPage } from './pages/divisions-main/divisions-main.page';
import { TemplatesPage } from './pages/templates/templates.page';
import { TemplatesHomePage } from './pages/templates-home/templates-home.page';
import { DivisionSeedingPage } from './pages/division-seeding/division-seeding.page';
import { AdminSettingsPage } from './pages/admin/settings/settings.page';
import { OperatorSettingsPage } from './pages/operator/settings/settings.page';
import { AdminUsersPage } from './pages/admin/users/users.page';
import { BracketTemplatePage } from './pages/bracket-template/bracket-template.page';
import { BracketTemplateDetailsPage } from './pages/bracket-template-details/bracket-template-details.page';
import { PoolSchedulesPage } from './pages/pool-schedules/pool-schedules.page';
import { TemplatePoolsPage } from './pages/template-pools/template-pools.page';
import { SchedulerPage } from './pages/scheduler/scheduler.page';
import { AdminOperatorsPage } from './pages/admin/operators/operators.page';
import { TemplateLayoutPage } from './pages/template-layout/template-layout.page';
import { SchedulerSettingsPage } from './pages/scheduler-settings/scheduler-settings.page';
import { FacilitiesPage } from './pages/facilities/facilities.page';
import { DivisionLayoutPage } from './pages/division-layout/division-layout.page';
import { EventReportsPage } from './pages/event-reports/event-reports.page';
import { ScoringByDivisionPage } from './pages/scoring-by-division/scoring-by-division.page';
import { ScoringByCourtPage } from './pages/scoring-by-court/scoring-by-court.page';
import { ScheduleByDivisionPage } from './pages/schedule-by-division/schedule-by-division.page';
import { DivisionRestrictionsPage } from './pages/division-restrictions/division-restrictions.page';
import { EventSettingsPage } from './pages/event-settings/event-settings.page';

// Components
import { PopoverBasicComponent } from './components/popover-basic/popover-basic.component';
import { SourceSelectorComponent } from './components/source-selector/source-selector.component';
import { SetTieBreakersComponent } from './components/set-tie-breakers/set-tie-breakers.component';
import { CustomPointsStandingsViewComponent } from './components/custom-points-standings-view/custom-points-standings-view.component';
import { MainNavComponent } from './components/main-nav/main-nav.component';
import { LoadingComponent } from './components/loading/loading.component';
import { AddDivisionModalComponent } from './components/add-division/add-division.component';
import { AddTeamModalComponent } from './components/add-team/add-team.component';
import { ManageUserModalComponent } from './components/manage-user/manage-user.component';
import { BracketGameBlockComponent } from './components/bracket-game-block/bracket-game-block.component';
import { CreateTemplateComponent } from './components/create-template/create-template.component';
import { CopyTemplateComponent } from './components/copy-template/copy-template.component';
import { TemplatePoolComponent } from './components/template-pool/template-pool.component';
import { SettingsComponent } from './components/admin/settings/settings.component';
import { OperatorSettingsComponent } from './components/operator/settings/settings.component';
import { SchedulerComponent } from './components/scheduler/scheduler.component';
import { EditGameBlockComponent } from './components/edit-game-block/edit-game-block.component';
import { AddTemplatePoolComponent } from './components/add-template-pool/add-template-pool.component';
import { AddTemplatePoolGroupComponent } from './components/add-template-pool-group/add-template-pool-group.component';
import { DivisionCombineWithComponent } from './components/division-combine-with/division-combine-with.component';
import { DivisionTeamsComponent } from './components/division-teams/division-teams.component';
import { CreateBracketComponent } from './components/create-bracket/create-bracket.component';
import { CopyFolderComponent } from './components/copy-folder/copy-folder.component';
import { AddFacilityComponent } from './components/add-facility/add-facility.component';
import { ManageCourtsComponent } from './components/manage-courts/manage-courts.component';
import { GridSettingsBlockComponent } from './components/grid-settings-block/grid-settings-block.component';
import { GridSettingsCourtUnavailabilityComponent } from './components/grid-settings-court-unavailability/grid-settings-court-unavailability.component';
import { CustomStandingsColumnsComponent } from './components/custom-standings-columns/custom-standings-columns.component';

// Services
import { TieBreakerService } from './services/tie-breaker/tie-breaker.service';
import { TemplateService } from './services/template/template.service';
import { AdminSettingsService } from './services/admin/settings/settings.service';
import { OperatorSettingsService } from './services/operator/settings/settings.service';
import { EventService } from './services/event/event.service';
import { PoolService } from './services/pool/pool.service';
import { FacilityService } from './services/facility/facility.service';
import { GameService } from './services/game/game.service';
import { AuthService } from './services/auth/auth.service';
import { TemplatePoolService } from './services/template-pool/template-pool.service';
import { TeamService } from './services/team/team.service';
import { DivisionService } from './services/division/division.service';
import { AuthResolver } from './guards/auth-resolver.guard';
import { TemplateGroupService } from './services/template-group/template-group.service';
import { BracketTemplateService } from './services/bracket-template/bracket-template.service';
import { ConfigService } from './services/config/config.service';
import { AdminUserService } from './services/admin/user/user.service';
import { AdminOperatorService } from './services/admin/operator/operator.service';

// Guards and Resolvers
import { AdminGuard } from './guards';
import { OperatorGuard } from './guards';
import { TemplateResolver } from './guards/template-resolver.guard';
import { EventDivisionsResolver } from './guards/event-divisions-resolver.guard';
import { PendingChangesGuard } from './guards/pending-changes.guard';
import { DivisionResolver } from './guards/division-resolver.guard';

// Interceptors
import { ApiRequestsInterceptor } from './interceptors/api-requests.interceptor';

// Pipes
import { AbbrPipe } from './pipes/abbr.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { GameSortPipe } from './pipes/game-sort.pipe';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        DragAndDropModule,
        NgbModule,
        NguiDatetimePickerModule,
        BrowserAnimationsModule,
        MatMenuModule,
        SchedulerRoutingModule,
        MatSlideToggleModule,
        ResizableModule,
        SharedModule
    ],
    declarations: [
        // Pages
        LoginPage,
        MasterPage,
        TeamsAndDivisionsPage,
        DivisionsPage,
        EventListPage,
        DivisionsMainPage,
        TemplatesPage,
        TemplatesHomePage,
        DivisionSeedingPage,
        AdminSettingsPage,
        OperatorSettingsPage,
        AdminUsersPage,
        BracketTemplatePage,
        BracketTemplateDetailsPage,
        TemplatePoolsPage,
        PoolSchedulesPage,
        SchedulerPage,
        SchedulerSettingsPage,
        AdminOperatorsPage,
        TemplateLayoutPage,
        FacilitiesPage,
        DivisionLayoutPage,
        EventReportsPage,
        ScoringByDivisionPage,
        ScoringByCourtPage,
        ScheduleByDivisionPage,
        DivisionRestrictionsPage,
        EventSettingsPage,
        // Components
        SetTieBreakersComponent,
        MainNavComponent,
        PopoverBasicComponent,
        LoadingComponent,
        CustomPointsStandingsViewComponent,
        AddDivisionModalComponent,
        AddTeamModalComponent,
        ManageUserModalComponent,
        BracketGameBlockComponent,
        CreateBracketComponent,
        CreateTemplateComponent,
        CopyFolderComponent,
        CopyTemplateComponent,
        TemplatePoolComponent,
        GridSettingsBlockComponent,
        SettingsComponent,
        OperatorSettingsComponent,
        SchedulerComponent,
        EditGameBlockComponent,
        AddTemplatePoolComponent,
        AddTemplatePoolGroupComponent,
        SourceSelectorComponent,
        DivisionCombineWithComponent,
        DivisionTeamsComponent,
        AddFacilityComponent,
        ManageCourtsComponent,
        GridSettingsCourtUnavailabilityComponent,
        CustomStandingsColumnsComponent,
        // Pipes
        AbbrPipe,
        OrderByPipe,
        GameSortPipe,
    ],
    entryComponents: [
        AddDivisionModalComponent,
        AddTeamModalComponent,
        ManageUserModalComponent,
        CreateBracketComponent,
        CreateTemplateComponent,
        CopyFolderComponent,
        CopyTemplateComponent,
        AddTemplatePoolComponent,
        AddTemplatePoolGroupComponent,
        DivisionCombineWithComponent,
        EditGameBlockComponent,
        AddFacilityComponent,
        ManageCourtsComponent
    ],
    providers: [
        TieBreakerService,
        EventService,
        PoolService,
        AuthService,
        TemplateService,
        TeamService,
        DivisionService,
        FacilityService,
        GameService,
        AdminSettingsService,
        OperatorSettingsService,
        AdminUserService,
        AdminOperatorService,
        TemplateGroupService,
        BracketTemplateService,
        ConfigService,
        TemplatePoolService,
        // Interceptors
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiRequestsInterceptor,
            multi: true
        },
        // Guards & Resolvers
        OperatorGuard,
        AdminGuard,
        AuthResolver,
        TemplateResolver,
        EventDivisionsResolver,
        PendingChangesGuard,
        DivisionResolver,
    ],
    exports: [
        BracketGameBlockComponent
    ]
})
export class SchedulerModule {
}
