import { ChangeDetectorRef, Component, DoCheck, EventEmitter, Input, KeyValueDiffers, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { SourceSelectorOptions, GameBlock, Team, TemplateSeed } from '../../interfaces';
import { GAME_BLOCK_TYPES } from '../../constants/brackets';

@Component({
    selector: 'bss-bracket-game-block',
    templateUrl: './bracket-game-block.component.html',
    styleUrls: ['./bracket-game-block.component.scss']
})
export class BracketGameBlockComponent implements OnInit, DoCheck {
    private _block: GameBlock = <any>{};
    @Input() sourceSelectionOptions: SourceSelectorOptions;
    @Input() color = '#009bdf';
    @Input() selected = false;
    @Input() readonly = false;
    @Input() bracketsRef = [];
    @Input() teamsByPoints: { count: number; teams: any[] };
    @Output() change = new EventEmitter();
    @Output() delete = new EventEmitter();
    sourceSelectionLabel = 'Team...';
    blockDiffer: any;
    readonly GAME_BLOCK_TYPES = GAME_BLOCK_TYPES;
    isReady = false;

    get block() {
        return this._block;
    }

    @Input('block')
    set block(value: GameBlock) {
        this._block = { ...value };
    }

    constructor(public changeRef: ChangeDetectorRef, private kvDiffer: KeyValueDiffers) {
        this.blockDiffer = this.kvDiffer.find({}).create();
    }

    ngOnInit() {
        if (this.sourceSelectionOptions) {
            this.isReady = true;
        }
    }

    /**
     * Method to retrieve game block type object by type number from local storage of game block types
     * @param {string} type
     */
    getGameBlockTypeById(type: string) {
        return this.GAME_BLOCK_TYPES.find(t => t.value === type);
    }

    getTeamName(block: GameBlock, teams: Team[], n: number) {
        const foundTeam = teams.find(team => {
            try {
                const seed = block.game['seed' + n] as TemplateSeed;
                const teamId = seed ? seed.team_id : block.game['team_' + n];
                return team.id === teamId;
            } catch (err) {
                console.error(err);
            }
            return false;
        });
        return foundTeam ? foundTeam.name : 'Unknown';
    }

    getSourceName(n) {
        if (this._block.game && this._block.game['team_' + n]) {
            return this.getTeamName(this._block, this.sourceSelectionOptions.sources.teams, n);
        }
        return this._block['participantLabel' + n];
    }

    /**
     * Method that handles the click event of the Delete Game Block action
     * @param gameBlockId
     */
    onDeleteGameBlock(gameBlockId) {
        this.delete.emit(gameBlockId);
    }

    onBlockChange() {
        this.change.emit(this._block);
        this.changeRef.detectChanges();
    }

    /**
     * Handle change event on bracket selector
     * @param event
     * @param n
     */
    bracketChange(event, n) {
        if (event.type === 'team' && this._block.game) {
            this._block.game['team_' + n] = event.value;
        } else {
            if (this._block.game) {
                this._block.game['team_' + n] = null;
            }
            this._block['participantLabel' + n] = event.label;
        }
        this._block['participantSource' + n] = event.type;
        this._block['participantUuid' + n] = event.value;
    }

    ngDoCheck() {
        const blockChanges = this.blockDiffer.diff(this._block);
        if (blockChanges) {
            this.onBlockChange();
        }
    }

    get gameBlockWrapperClass() {
        return {
            'game-block-left': this._block.type === 'left',
            'game-block-right': this._block.type === 'right',
            'game-block-double': this._block.type === 'double',
            'game-block-double-block': this._block.type === 'double-block',
            'game-block-final-left': this._block.type === 'final-left',
            'game-block-final-right': this._block.type === 'final-right',
            'selected': this.selected,
            'if-necessary': this._block.ifNecessary,
            'readonly': this.readonly
        };
    }

    /**
     * @param n
     */
    getOptions(n: number) {
        return {
            sourceSelection: this.sourceSelectionLabel,
            sources: this.sourceSelectionOptions.sources,
            showTeamSubDdl: this.sourceSelectionOptions.showTeamSubDdl,
            disablePoolAndPoints: this.sourceSelectionOptions.disablePoolAndPoints,
        };
    }
}
