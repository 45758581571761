import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DivisionService } from '../../services/division/division.service';
import { Division } from '../../interfaces';

@Component({
    selector: 'bss-division-combine-with',
    templateUrl: './division-combine-with.component.html',
    styleUrls: ['./division-combine-with.component.scss']
})
export class DivisionCombineWithComponent implements OnInit {
    sourceDivisionId: number = null;
    @Input() targetDivisionId;
    divisions = [];

    constructor(
        private divisionService: DivisionService,
        private activeModal: NgbActiveModal) { }

    ngOnInit() {}

    setup(sourceDivisionId: number, divisions: Division[]) {
        this.sourceDivisionId = sourceDivisionId;
        const sourceDivIndex = divisions.findIndex(d => d.id === sourceDivisionId);
        this.divisions = [... divisions];
        this.divisions.splice(sourceDivIndex, 1);
    }

    cancel() {
        this.activeModal.close(false);
    }

    onSubmit() {
        this.divisionService
        .combineWith(this.sourceDivisionId, this.targetDivisionId)
        .subscribe(
            data => {
                this.activeModal.close(true);
            },
            err => {
                console.error(err);
            }
        );
    }

}
