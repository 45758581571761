import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EventService } from '../../services/event/event.service';

@Component({
    selector: 'bss-add-division',
    templateUrl: './add-division.component.html',
    styleUrls: ['./add-division.component.scss']
})
export class AddDivisionModalComponent implements OnInit {
    @Input() name;
    @Input() eventId;

    constructor(
        private activeModal: NgbActiveModal,
        private eventService: EventService) {}

    ngOnInit() { }

    setup(eventId: number) {
        this.eventId = eventId;
    }

    save() {
        this.eventService.addDivision(this.eventId, {
            name: this.name,
            gender_id: 1
        })
        .subscribe(data => {
            this.activeModal.close(data);
        });
    }

    close() {
        this.activeModal.close(false);
    }
}
