import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'abbr'
})
export class AbbrPipe implements PipeTransform {
    transform(value: string, args?: any): any {
        const split = value.split('/');
        return split[0];
    }
}
