import { Component, OnInit, Input, ViewChild } from '@angular/core';
import * as moment from 'moment';

import { CourtUnavailabilityTimeFrame as TimeFrame, Facility, Event } from '../../interfaces';
import { FacilityService, EventService } from '../../services';
import { DateTimePickerComponent } from '../../../shared/components/datetime-picker/datetime-picker.component';

@Component({
    selector: 'bss-grid-settings-court-unavailability',
    templateUrl: './grid-settings-court-unavailability.component.html',
    styleUrls: ['./grid-settings-court-unavailability.component.scss']
})
export class GridSettingsCourtUnavailabilityComponent implements OnInit {
    @Input() eventId: number;
    @ViewChild('fromDateCtrl') fromDateCtrl: DateTimePickerComponent;
    @ViewChild('toDateCtrl') toDateCtrl: DateTimePickerComponent;
    minDate: string;
    maxDate: string;
    timeframes: TimeFrame[] = [];
    facilities: Facility[] = [];
    timeFrameInEdition: TimeFrame;
    timeFrameInEditionBackup: TimeFrame[];
    isLoading = true;
    event: Event;
    validity = true;

    constructor(
        private facilityService: FacilityService,
        private eventService: EventService) {}

    ngOnInit() {
        this.refresh();
        setInterval(() => {
            this.validity = !this.validity;
        }, 1000);
    }

    /**
     * Load Court Unavailabilities
     * @returns {Promise<void>}
     */
    private async refresh() {
        this.isLoading = true;
        try {
            const resp: any = await this.facilityService.getUnavailability(this.eventId).toPromise();
            this.timeframes = resp.timeframes;
            this.facilities = resp.facilities;
            this.event = await this.eventService.getById(this.eventId + '').toPromise() as Event;
            this.minDate = moment.utc(this.event.start_date).startOf('day').toISOString();
            this.maxDate = moment.utc(this.event.end_date).endOf('day').toISOString();
        } catch (err) {
            console.error(err);
        }
        this.timeFrameInEdition = null;
        this.isLoading = false;
    }

    /**
     * Return true if the court is unavailable
     * @param {CourtUnavailabilityTimeFrame} timeframe
     * @param {number} courtId
     * @returns {boolean}
     */
    isUnavailable(timeframe: TimeFrame, courtId: number) {
        return timeframe.courts.indexOf(courtId) > -1;
    }

    /**
     * Filter and retrieve unavailables courts grouped by facilities
     * @param {CourtUnavailabilityTimeFrame} timeframe
     * @param {Facility[]} facilities
     * @returns {Array}
     */
    getUnavailables(timeframe: TimeFrame, facilities: Facility[]) {
        const filteredFacilities = [];
        facilities.forEach(f => {
            const courts = f.courts.filter(c => timeframe.courts.indexOf(c.id) > -1);
            if (courts.length) {
                filteredFacilities.push({
                    name: f.name,
                    courts: courts
                });
            }
        });
        return filteredFacilities;
    }

    /**
     * Change unavailability of a Court
     * @param {CourtUnavailabilityTimeFrame} timeframe
     * @param {number} courtId
     */
    toggle(timeframe: TimeFrame, courtId: number) {
        const index = timeframe.courts.indexOf(courtId);
        if (index === -1) {
            timeframe.courts.push(courtId);
        } else {
            timeframe.courts.splice(index, 1);
        }
    }

    /**
     * Add a new TimeFrame
     */
    addTimeFrame() {
        if (this.timeFrameInEdition) {
            return;
        }
        const newTimeFrame = {
            from_date: moment.utc(),
            to_date: moment.utc(),
            courts: []
        };
        this.timeframes.push(newTimeFrame);
        this.edit(newTimeFrame);
    }

    /**
     * Change an existing TimeFrame to editing mode
     * @param {CourtUnavailabilityTimeFrame} timeframe
     */
    edit(timeframe: TimeFrame) {
        this.timeFrameInEditionBackup = JSON.parse(JSON.stringify(timeframe));
        this.timeFrameInEdition = timeframe;
    }

    /**
     * Delete a TimeFrame and their unavailable courts
     * @param {CourtUnavailabilityTimeFrame} timeframe
     */
    delete(timeframe: TimeFrame) {
        this.isLoading = true;
        this.facilityService
            .deleteUnavailabilityTimeFrame(this.eventId, timeframe.id)
            .subscribe(
                () => {
                    const index = this.timeframes.findIndex((tf: TimeFrame) => tf.id === timeframe.id);
                    this.timeframes.splice(index, 1);
                },
                err => console.error(err),
                () => this.isLoading = false
            );
    }

    /**
     * Save a TimeFrame through the FacilityService
     * @param {CourtUnavailabilityTimeFrame} timeframe
     */
    save(timeframe: TimeFrame) {
        this.isLoading = true;
        const payload = this.cleanPayload(timeframe);
        this.facilityService
            .setUnavailabilityTimeFrame(this.eventId, payload, timeframe.id)
            .subscribe(
                () => this.refresh(),
                err => console.error(err),
                () => this.isLoading = false
            );
    }

    /**
     * Discard changes and turn editing mode off
     * @param {CourtUnavailabilityTimeFrame} timeframe
     */
    cancel(timeframe: TimeFrame) {
        this.reset(timeframe);
        if (!timeframe.id) {
            this.timeframes.splice(this.timeframes.length - 1, 1);
        }
        this.timeFrameInEdition = null;
    }

    /**
     * Reset status of a TimeFrame and courts
     * @param {CourtUnavailabilityTimeFrame} timeframe
     */
    reset(timeframe: TimeFrame) {
        const tf = JSON.parse(JSON.stringify(this.timeFrameInEditionBackup));
        timeframe.courts = tf.courts;
        timeframe.from_date = tf.from_date;
        timeframe.to_date = tf.to_date;
    }

    /**
     * Prepare TimeFrame to be persisted
     * @param {CourtUnavailabilityTimeFrame} timeframe
     * @returns {CourtUnavailabilityTimeFrame}
     */
    private cleanPayload(timeframe: TimeFrame): TimeFrame {
        return {
            from_date: this.fromDateCtrl.datetime.toISOString(),
            to_date: this.toDateCtrl.datetime.toISOString(),
            courts: timeframe.courts
        };
    }

    /**
     * Getter that returns true if the TimeFrame is valid
     * @returns {Boolean}
     */
    get isValid(): Boolean {
        if (!this.timeFrameInEdition || this.timeFrameInEdition.courts.length === 0) {
            return false;
        }

        if (!this.fromDateCtrl || !this.fromDateCtrl.isValid) {
            return false;
        }

        if (!this.toDateCtrl || !this.toDateCtrl.isValid) {
            return false;
        }

        return this.fromDateCtrl.datetime <= this.toDateCtrl.datetime;
    }

    setFromDate($event) {
        this.timeFrameInEdition.from_date = $event.toISOString();
    }

    setToDate($event) {
        this.timeFrameInEdition.to_date = $event.toISOString();
    }
}
