import { Component } from '@angular/core';
import { FiltersBarService } from '../../services/index';

@Component({
    selector: 'bss-filters-bar',
    templateUrl: './filters-bar.component.html',
    styleUrls: ['./filters-bar.component.scss']
})
export class FiltersBarComponent {
    filters: any = null;

    constructor(public filtersBarService: FiltersBarService) {
        this.filtersBarService.filters.subscribe(filters => {
            this.filters = filters;
        });
    }
}
