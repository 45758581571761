import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs/Observable';
import { Team } from '../../interfaces';
import { EventService } from '../../services/event/event.service';

@Component({
    selector: 'bss-add-team',
    templateUrl: './add-team.component.html',
    styleUrls: ['./add-team.component.scss']
})
export class AddTeamModalComponent implements OnInit {
    public teamName = '';
    public divisionId = null;
    public divisionName = '';
    public existingDivisionMode = true;
    public teams: any[];
    @Input() divisions: any[];
    @Input() eventId: number;

    constructor(
        private activeModal: NgbActiveModal,
        private eventService: EventService) {}

    ngOnInit() {}

    public setup(eventId: number, divisions,  divisionId: number) {
        this.eventId = eventId;
        this.divisions = divisions;
        this.teams = [].concat.apply([], this.divisions.map(d => d.teams));
        this.divisionId = divisionId;
    }

    search(text$: Observable<string>) {
        return text$
        .debounceTime(200)
        .distinctUntilChanged()
        .map(term => term.length < 2 ? []
            : this.teams.map(t => t.name).filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10));
    }

    formatter(x: { name: string }) {
        return x.name;
    }

    toggleDivMode() {
        this.existingDivisionMode = !this.existingDivisionMode;
    }

    clear() {
        this.teamName = '';
        this.divisionName = '';
    }

    async create() {
        const team: Team = { name: this.teamName };
        if (this.existingDivisionMode) {
            team.division_id = parseInt(this.divisionId, 10);
        } else {
            const div: any = await this.eventService.addDivision(this.eventId, {
                name: this.divisionName,
                gender_id: 1,
            }).toPromise();
            team.division_id = div.id;
        }
        const data: any = await this.eventService
        .addTeam(this.eventId, team)
        .toPromise();
        return data.id ? { id: data.id, ...team } : false;
    }

    save() {
        this.create()
        .then(data => {
            if (data) {
                this.activeModal.close(data);
            }
        });
    }

    saveAndContinue() {
        this.create()
        .then(data => {
            if (data) {
                this.clear();
            }
        });
    }

    close() {
        this.activeModal.close(false);
    }
}
