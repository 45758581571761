import { Component, OnInit } from '@angular/core';
import { User } from '../../../interfaces/user';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs/Observable';

import { AdminOperatorService } from '../../../services/admin/operator/operator.service';
import { ManageUserModalComponent } from '../../../components/manage-user/manage-user.component';

interface AdminUserView extends User {
    rolesLabel?: string;
    events?: any;
}

@Component({
    selector: 'bss-operators',
    templateUrl: './operators.page.html',
    styleUrls: ['./operators.page.scss']
})
export class AdminOperatorsPage implements OnInit {
    loading = false;
    users: AdminUserView[];
    search = '';
    sortAsc = true;
    sortField = 'last_name';
    usersTotal: number;
    pageLimit = 25;
    page = 1;
    public searchFormGroup = this.fb.group({
        search: [''],
        entriesShownAmount: [25, [Validators.required, Validators.min(25), Validators.max(100)]]
    });

    constructor(private userService: AdminOperatorService, private fb: FormBuilder, private modalService: NgbModal) {}

    ngOnInit() {
        this.getUsers();
        this.searchFormGroup
            .controls['search']
            .valueChanges
            .debounceTime(500)
            .subscribe(value => {
                this.search = value;
                this.getUsers();
            });
        this.searchFormGroup
            .controls['entriesShownAmount']
            .valueChanges
            .debounceTime(500)
            .subscribe(value => {
                this.pageLimit = value;
                this.getUsers();
            });
    }

    /**
     *  Retrieves list of users via the user service.
     *  On http success, assigns class properties `users` and `usersTotals`
     */
    getUsers() {
        this.userService.get(this.sortAsc, this.sortField, this.search, this.page, this.pageLimit)
            .subscribe((data: any) => {
                this.usersTotal = this.pageLimit * data.count;
                this.users = data.users.map((user: User) => {
                    return {...user, rolesLabel: user.roles.map(role => role.name).join(', ')};
                });
            });
    }

    switchTo(email) {
        // Set new token.
        this.userService.impersonate(email)
        .subscribe((data: any) => {
            localStorage.setItem('JWT', data.token);
            window.open('/events');
        });
    }

    /**
     * Display popup modal to create/edit a user.
     * On modal result event, re-retrieves the user list
     * if the modal result is a {userId}
     */
    manageUser(userId?: number) {
        const modalRef: NgbModalRef = this.modalService.open(ManageUserModalComponent);
        if (userId) {
            modalRef.componentInstance.userId = userId;
        }

        Observable.fromPromise(modalRef.result)
            .subscribe(
                (modalResult: any) => {
                    // modalResult userId
                    if (!isNaN(parseInt(modalResult, 10))) {
                        this.getUsers();
                    }
                }
            );
    }

    /**
     * Event handler to sort the user list by fields & order
     * @param {string} field
     * @param {boolean} order
     */
    onUserSort(field: string, order: boolean) {
        this.sortField = field;
        this.sortAsc = order;
        this.getUsers();
    }

    /**
     * Event handler to switch pages for the user list
     * @param {number} page
     */
    onPageChange(page: number) {
        this.page = page;
        this.getUsers();
    }
}
