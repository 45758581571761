import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'bss-bracket-template',
    templateUrl: './bracket-template.page.html',
    styleUrls: ['./bracket-template.page.scss']
})
export class BracketTemplatePage implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }
}
