import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'bss-copy-folder',
    templateUrl: './copy-folder.component.html',
    styleUrls: ['./copy-folder.component.scss']
})
export class CopyFolderComponent implements OnInit {
    constructor(public activeModal: NgbActiveModal) {
    }
    @Input() sourceFolder = '';
    folder = { name: null };
    ngOnInit() {
    }
}
