import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import * as _ from 'lodash';
import {
    GameService,
    PoolService,
    DivisionService,
    BracketTemplateService
} from '../../services';
import {PoolSchedule, Bracket, Event} from '../../interfaces';

@Component({
    selector: 'bss-scoring-by-division',
    templateUrl: './scoring-by-division.page.html',
    styleUrls: ['./scoring-by-division.page.scss']
})
export class ScoringByDivisionPage implements OnInit {
    poolSchedules: PoolSchedule[];
    divisions: any = {};
    brackets: Bracket[];
    event = <Event>{};
    selectedBracket: any;
    bracketGameBlocks: any;
    eventId: number;
    divisionSeeds = [];
    isSchdulesReady = false;
    isBracketsReady = false;
    isLoading = false;
    currentDivision: any;

    constructor(
        private route: ActivatedRoute,
        private poolService: PoolService,
        private bracketTemplateService: BracketTemplateService,
        private gameService: GameService,
        private divisionService: DivisionService) {
    }

    ngOnInit() {
        this.route.parent.data.subscribe((data) => {
            this.divisions = _.filter(data.eventsAndDivisions.divisions, {active: true});
            this.eventId = data.eventsAndDivisions.event.id;
            this.event = data.eventsAndDivisions.event;
        });
        this.currentDivision = this.divisions[0];
        this.getSchedulesAndBrackets(this.currentDivision);
    }

    async getSchedulesAndBrackets(division) {
        this.isSchdulesReady = true;
        this.isBracketsReady = true;
        this.currentDivision = division;
        this.isLoading = true;
        try {
            this.divisionSeeds = await this.divisionService.getSeeds(this.currentDivision.id).toPromise();
            const schedulesData: any = await this.poolService.getSchedules(division.template_id).toPromise();
            this.poolSchedules = schedulesData.schedules;
            this.isSchdulesReady = false;
            const bracketsData: any = await this.bracketTemplateService.getBracketById(division.template_id).toPromise();
            this.brackets = bracketsData.brackets;
            this.selectedBracket = this.brackets[0];
            if (this.selectedBracket) {
                this.bracketGameBlocks = _.sortBy(this.selectedBracket.gameBlocks, [gameBlock => gameBlock.game.game_number]);
            }
            this.isBracketsReady = false;
        } catch (err) {
            console.error(err);
        }
        this.isLoading = false;
    }

    onScoreGame($event, id, source): void {
        const games = {
            games: [
                {
                    game_id: id,
                    team_1_score: $event[0],
                    team_2_score: $event[1]
                }
            ]
        };
        this.gameService.scoreGames(this.eventId, this.currentDivision.id, games).subscribe(
          () => {
              if (source === 'bracket') {
                  this.selectedBracket.gameBlocks.forEach(gB => {
                      if (gB.game && gB.game.id && gB.game.id === id) {
                          gB.game.team_1_score = $event[0];
                          gB.game.team_2_score = $event[1];
                      }
                  });
              }
          },
          err => console.log('error', err)
        );
    }

    getTeamByTeamId(teamId: number) {
        return this.divisionSeeds.find(t => t.id === teamId) || {};
    }
}
