import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { HotjarModule } from 'ng-hotjar';
import { SchedulerModule } from './scheduler/scheduler.module';
import { AppRoutingModule } from './app-routing.module';
import { PublicModule } from './public/public.module';
import { ClipboardModule } from 'ngx-clipboard';

@NgModule({
    imports: [
        HotjarModule,
        BrowserModule,
        SchedulerModule,
        PublicModule,
        AppRoutingModule,
        ClipboardModule,
    ],
    declarations: [
        AppComponent,
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
