import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../../auth/auth.service';
import { environment as env } from '../../../../../environments/environment';

@Injectable()
export class AdminUserService {

    constructor(private http: HttpClient, private auth: AuthService) {
    }

    /**
     * GET /users - Retrieve filtered user list
     * @param {boolean} sortAsc
     * @param {string} sortField
     * @param {string} search
     * @param {number} page
     * @param {number} limit
     * @returns {Observable<Object>}
     */
    public get(sortAsc = true, sortField = 'last_name', search = '', page = 1, limit = 25) {
        const params: any = {};

        params.sort = (sortAsc ? '' : '-') + sortField;

        if (search) {
            params.search = search.trim().replace(/\s+/g, '+');
        }

        params.page = page;
        params.limit = limit;

        const headers = new HttpHeaders(
            {'Authorization': `Bearer ${this.auth.getJWT()}`}
        );

        return this.http.get(env.API_BASE_URL + '/users', {params: params, headers: headers});
    }

    /**
     * POST /users - creates a new user
     * @param {string} firstName
     * @param {string} lastName
     * @param {string} email
     * @param {string} phone
     * @param {number} operatorId
     * @param {string} role
     * @returns {Observable<Object>}
     */
    public create(firstName: string, lastName: string, email: string, phone: string, operatorId: number, access: string) {
        const headers = new HttpHeaders(
            {'Authorization': `Bearer ${this.auth.getJWT()}`}
        );

        return this.http
        .post(env.API_BASE_URL + '/users',
            {
                first_name: firstName,
                last_name: lastName,
                email,
                phone,
                operator_id: operatorId ? operatorId : undefined,
                access: [{name: access}]
            },
            {headers: headers}
        )
        .map((data: any) => {
            console.log('post user data', data);
            return data.id;
        });
    }

    /**
     * GET /users/{id} - Retreives an individual user by userId
     * @param {number} userId
     * @returns {Observable<Object>}
     */
    public getById(userId: number) {
        const headers = new HttpHeaders(
            {'Authorization': `Bearer ${this.auth.getJWT()}`}
        );
        return this.http.get(env.API_BASE_URL + '/users/' + userId, {headers: headers});
    }

    /**
     * DELETE /users/{id} - Removes an individual user by userId
     * @param {number} userId
     * @returns {Observable<Object>}
     */
    public delete(userId: number) {
        const headers = new HttpHeaders(
            {'Authorization': `Bearer ${this.auth.getJWT()}`}
        );
        return this.http.delete(env.API_BASE_URL + '/users/' + userId, {headers: headers});
    }

    /**
     * PUT /users/{id} - Updates an individual user by userId
     * @param {number} userId
     * @param {string} firstName
     * @param {string} lastName
     * @param {string} email
     * @param {string} phone
     * @param {number} operatorId
     * @param {string} role
     * @returns {Observable<any>}
     */
    public update(userId: number, firstName: string, lastName: string, email: string, phone: string, operatorId: number, access: string) {
        const headers = new HttpHeaders(
            {'Authorization': `Bearer ${this.auth.getJWT()}`}
        );
        return this.http
        .put(env.API_BASE_URL + '/users/' + userId,
            {
                first_name: firstName,
                last_name: lastName,
                email,
                phone,
                operator_id: operatorId ? operatorId : undefined,
                access: [{name: access}]
            },
            {headers: headers}
        );
    }
}
