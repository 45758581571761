import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    AuthService,
    GameService,
    DivisionService,
    EventService,
    PoolService,
} from '../../../scheduler/services';
import {
    FiltersBarService,
    PrintDataService,
} from '../../services';
import {
    PoolSchedule,
    Standings,
    Division,
} from '../../../scheduler/interfaces';
import { ScoringConfig } from '../../../shared/interfaces';
import { ScorerComponent } from '../../../shared/components/scorer/scorer.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GameScoringPage } from '../../classes/game-scoring.page';

@Component({
    selector: 'bss-public-pool-schedules',
    templateUrl: './pool-schedules.page.html',
    styleUrls: ['./pool-schedules.page.scss']
})
export class PoolSchedulesPage extends GameScoringPage implements OnInit, OnDestroy {
    divisionId: number;
    division: Division;
    eventId: number;
    poolSchedules: PoolSchedule[];
    filteredSchedules: PoolSchedule[];
    filters: any;
    isAuthenticated = false;
    public schedules: Array<any>;
    public standings: StandingsView[];
    standingsConfig: any;
    scoringConfig: ScoringConfig;
    isLoading = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private poolService: PoolService,
        private divisionService: DivisionService,
        private eventService: EventService,
        private filtersBarService: FiltersBarService,
        private _authService: AuthService,
        private _printDataService: PrintDataService,
        _gameService: GameService,
        nbgModal: NgbModal) {
        super(nbgModal, _gameService);
    }

    ngOnInit() {
        this.isAuthenticated = this._authService.isAuthenticated();
        this.route.parent.params.subscribe(params => {
            this.divisionId = params.divisionId;
            this.refresh();
        });
        this.route.parent.parent.params.subscribe(params => {
            this.eventId = params.eventId;
        });
        this.filters = {
            pool: null,
            game_date: null,
            team: null
        };
    }

    ngOnDestroy() {
        this.filtersBarService.clearFilters();
    }

    async refresh(withLoadingEffect = true) {
        this.isLoading = withLoadingEffect;
        try {
            this.division = await this.divisionService.getById(this.divisionId + '').toPromise() as Division;
            const scheduleData = await this.poolService.getSchedules(this.division.template_id).toPromise();
            this.poolSchedules = scheduleData.schedules;
            this.scoringConfig = await this.eventService.getScoringConfig(this.division.template_id).toPromise();
            this.filteredSchedules = this.poolSchedules;
            this.schedules = this.filteredSchedules;
            this._printDataService.setScheduleData(this.schedules);
            this.setFilters();
            this.standingsConfig = await this.divisionService.getStandingsConfig(this.divisionId).toPromise();
            const data = await this.divisionService.getDivisionStandings(this.divisionId).toPromise();
            this.standings = data.map((pool: StandingsView) => {
                if (pool.seeds.length === 0) {
                    return;
                }
                const duplicate = (() => {
                    const seen = [];
                    for (let i = 0; i < pool.seeds.length; i++) {
                        if (seen.indexOf(pool.seeds[i].pool_rank) > -1) {
                            return true;
                        }
                        seen.push(pool.seeds[i].pool_rank);
                    }
                    return false;
                })();
                if (duplicate) {
                    pool.editableSeeds = true;
                }
                return pool;
            });

            this._printDataService.setStandingsData(this.standings);
        } catch (err) {
            console.error(err);
        }
        this.isLoading = false;
    }

    setFilters() {
        const teamsAndDates = this.extractTeamsAndDates(this.poolSchedules);
        this.filtersBarService.setFilters({
            teams: teamsAndDates.teams,
            dates: teamsAndDates.dates,
            pools: this.poolSchedules.map(pool => {
                return { id: pool.id, name: pool.name };
            })
        });

        this.filtersBarService.dataEmitters.subscribe((filter) => {
            this.filters[filter.type] = filter.value;
            this.filteredSchedules = this.applyFilter(this.filters);
        });
    }

    extractTeamsAndDates(poolSchedules: PoolSchedule[]) {
        const teams = [];
        const dates = [];
        this.poolSchedules.forEach(ps => {
            ps.games.forEach(g => {
                if (g.home_seed.team && teams.findIndex(t => t.id === g.home_seed.team.id) === -1) {
                    teams.push(g.home_seed.team);
                }
                if (g.away_seed.team && teams.findIndex(t => t.id === g.away_seed.team.id) === -1) {
                    teams.push(g.away_seed.team);
                }
                const date = new Date(g.start_date).setHours(0, 0, 0, 0);
                if (dates.indexOf(date) === -1) {
                    dates.push(date);
                }
            });
        });
        return { teams, dates };
    }

    applyFilter(filters) {
        let schedules: PoolSchedule[] = JSON.parse(JSON.stringify(this.poolSchedules));
        if (filters.pool) {
            schedules = schedules.filter(ps => {
                return ps.id === parseInt(filters.pool, 10);
            });
        }
        if (filters.team) {
            schedules
                .map(ps => {
                    ps.games = ps.games
                        .filter(g => {
                            return g.home_seed.team && g.home_seed.team.id === parseInt(filters.team, 10) ||
                                g.away_seed.team && g.away_seed.team.id === parseInt(filters.team, 10);
                        });
                });
        }
        if (filters.game_date) {
            schedules
                .map(ps => {
                    ps.games = ps.games
                        .filter(g => {
                            const leftDate = new Date(parseInt(filters.game_date, 10)).setHours(0, 0, 0, 0);
                            const rightDate = new Date(g.start_date).setHours(0, 0, 0, 0);
                            return leftDate === rightDate;
                        });
                });
        }
        return schedules.filter(ps => ps.games.length > 0);
    }
    onRowClick($event, gameid) {
        this.router.navigate([`/schedules/${this.eventId}/divisions/${this.divisionId}/game-schedule/${gameid}`]);
    }

    /**
     * Handle save event of the scorer component
     * @param $event
     * @param game
     * @param pool
     * @param $cmp
     */
    async onScoreGame($event, game, pool, $cmp: ScorerComponent) {

        this.isLoading = true;
        try {
            await this.scoreGame(this.eventId, this.divisionId, game, $event, this.standingsConfig.allow_ties, pool.name);
            this.refresh();
        } catch (err) {
            console.error(err);
            $cmp.reset();
        }
        this.isLoading = false;
    }
}

interface StandingsView extends Standings {
    editableSeeds?: boolean;
    invalidSeed?: boolean;
}
