import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import {
    Router,
    Resolve,
    RouterStateSnapshot,
    ActivatedRouteSnapshot
} from '@angular/router';
import { AuthService } from '../services/auth/auth.service';

@Injectable()
export class AuthResolver implements Resolve<Observable<any>> {
    constructor(private authService: AuthService,
                private router: Router) {
    }

    resolve(route: ActivatedRouteSnapshot,
            state: RouterStateSnapshot): Observable<any[]> {
        return this.authService.signIn({email: 'admin@admin', password: ''});
    }
}
