import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'bss-loading-effect',
    templateUrl: './loading-effect.component.html',
    styleUrls: ['./loading-effect.component.scss']
})
export class LoadingEffectComponent implements OnInit {
    @Input() active = false;
    constructor() { }

    ngOnInit() {}
}
