import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
    name: 'teamPoolRankSort'
})

export class TeamPoolRankSortPipe implements PipeTransform {
    transform(array: Array<any>): Array<any> {
        return _.sortBy(array, [team => team.seed.order]);
    }
}
