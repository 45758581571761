export class PointLabels {

    private static baseballIds = [4, 5];

    private static standingLabels = {
        baseball: {
            differential: {
                name: 'Run Differential',
                short_name: 'RD',
                alias: 'rd'
            },
            pointsFor: {
                name: 'Runs For',
                short_name: 'RF',
                alias: 'rf'
            },
            pointsAgainst: {
                name: 'Runs Against',
                short_name: 'RA',
                alias: 'ra'
            }
        },
        default: {
            differential: {
                name: 'Goal Differential',
                short_name: 'GD',
                alias: 'gd',
            },
            pointsFor: {
                name: 'Goal For',
                short_name: 'GF',
                alias: 'gf'
            },
            pointsAgainst: {
                name: 'Goal Against',
                short_name: 'GA',
                alias: 'ga'
            }
        }
    };

    private static customPointStandings = {
        baseball: {
            pointsPerScore: 'Points per Run',
            maxScoreForPoints: 'Maximum Runs for Points'
        },
        default: {
            pointsPerScore: 'Points per Goal',
            maxScoreForPoints: 'Maximum Goals for Points'
        }
    };

    private static tieBreakers = {
        headToHead: 'Head to Head',
        mostTotalWins: 'Most Total Wins',
        mostShutouts: 'Most Shutouts',
        minusPointsForCards: 'Minus Points for Red/Yellow Cards',
        shootouts: 'Shootouts',
        coinToss: 'Coin Toss'
    };

    private static baseballTieBreakers = {
        bestPointDifferentialOverall: 'Best Run Differential Overall',
        mostPointFor: 'Most Runs For',
        leastPointsAllowed: 'Least Runs Allowed',
    };

    private static defaultTieBreakers = {
        bestPointDifferentialOverall: 'Best Goal Differential Overall',
        mostPointFor: 'Most Goals For',
        leastPointsAllowed: 'Least Goals Allowed',
    };

    static getStandingLabels(sportId: number) {
        return this.baseballIds.includes(sportId) ? this.standingLabels.baseball : this.standingLabels.default;
    }

    static getCustomPointStandings(sportId: number) {
        return this.baseballIds.includes(sportId) ? this.customPointStandings.baseball : this.customPointStandings.default;
    }

    static getCustomPointStandingBools(sportId: number) {
        return {
            eventIsBaseballOrSoftball: this.baseballIds.includes(sportId)
        };
    }

    static getTieBreakers(sportId: number) {
        let tieBreakers;
        if (this.baseballIds.includes(sportId)) {
            tieBreakers = {...this.tieBreakers, ...this.baseballTieBreakers};
        } else {
            tieBreakers = {...this.tieBreakers, ...this.defaultTieBreakers};
        }
        return tieBreakers;
    }
}
