import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomPoints, Event } from '../../interfaces';
import { EventService } from '../../services';
import { PointLabels } from '../../../shared/utils/point-labels';
import * as _ from 'lodash';

@Component({
    selector: 'bss-custom-points-standings-view',
    templateUrl: './custom-points-standings-view.component.html',
    styleUrls: ['./custom-points-standings-view.component.scss']
})
export class CustomPointsStandingsViewComponent implements OnInit {
    @Input() eventId: number;
    @Input() sportId: number;
    customPoints: CustomPoints;
    negativeNumbers: number[];
    positiveNumbers: number[];
    isLoading = false;
    customPointsForm: FormGroup;
    eventIsBaseballOrSoftball = false;
    defaultCustomPoints: CustomPoints;
    pointsPerScore: string;
    maxScoreForPoints: string;

    constructor(
        private eventService: EventService,
        private fb: FormBuilder) {
        this.negativeNumbers = [-10, -9, -8, -7, -6, -5, -4, -3, -2, -1];
        this.positiveNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
        this.defaultCustomPoints = {
            win_points: 3,
            tie_points: 1,
            loss_points: 0,
            goal_points: 1,
            max_point_per_goal: 3,
            shutout_points: 1,
            red_cards_points: -1,
            yellow_cards_points: -1
        };
        this.customPointsForm = this.fb.group(this.defaultCustomPoints);
        this.pointsPerScore = PointLabels.getCustomPointStandings(+this.sportId).pointsPerScore;
        this.maxScoreForPoints = PointLabels.getCustomPointStandings(+this.sportId).maxScoreForPoints;

    }

    ngOnInit() {
        this.eventService
        .getEvent(this.eventId)
        .subscribe((event: Event) => {
            this.eventIsBaseballOrSoftball = PointLabels.getCustomPointStandingBools(+event.sport.id).eventIsBaseballOrSoftball;
            this.pointsPerScore = PointLabels.getCustomPointStandings(+event.sport.id).pointsPerScore;
            this.maxScoreForPoints = PointLabels.getCustomPointStandings(+event.sport.id).maxScoreForPoints;
        });

        this.isLoading = true;
        this.eventService
        .getPoints(this.eventId)
        .subscribe((customPoints: CustomPoints) => {
            this.customPoints = customPoints;
            this.reset();
            this.isLoading = false;
        });
    }

    /**
     * Restore the initial amounts of points for all CustomPoints objects
     */
    reset() {
        this.customPointsForm.setValue(this.customPoints);
        this.customPointsForm.markAsPristine();
    }

    /**
     * Persist CustomPoints for Pool Standings
     */
    onSubmit() {
        const payload = this.getSetValuesFromObject(this.customPointsForm.getRawValue());
        if (payload === {}) {
            return;
        }
        this.isLoading = true;
        this.eventService
        .updatePoints(this.eventId, payload)
        .subscribe(
            () => {
                this.customPoints = this.customPointsForm.getRawValue();
                this.customPointsForm.markAsPristine();
                this.isLoading = false;
            },
            err => {
                this.isLoading = false;
                console.error(err);
            }
        );
    }

    /**
     * Return an object with only properties that are set with a value
     * @param obj
     * @returns {any}
     */
    getSetValuesFromObject(obj: any) {
        const newObj = {};
        for (const i in obj) {
            if (obj[i] !== null) {
                newObj[i] = obj[i];
            }
        }
        return newObj;
    }
}
