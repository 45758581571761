import { Injectable } from '@angular/core';

@Injectable()
export class NotificationsService {
    private _400 = false;
    private _401 = false;
    private _404 = false;
    private _500 = false;
    private autoHideOn = 10000;

    constructor() { }

    public set400() {
        this._400 = true;
        setTimeout(this.clear400.bind(this), this.autoHideOn);
    }

    public clear400() {
        this._400 = false;
    }

    public set401() {
        this._401 = true;
        setTimeout(this.clear401.bind(this), this.autoHideOn);
    }

    public clear401() {
        this._401 = false;
    }

    public set404() {
        this._404 = true;
        setTimeout(this.clear404.bind(this), this.autoHideOn);
    }

    public clear404() {
        this._404 = false;
    }

    public set500() {
        this._500 = true;
        setTimeout(this.clear500.bind(this), this.autoHideOn);
    }

    public clear500() {
        this._500 = false;
    }

    public get $400() {
        return this._400;
    }

    public get $401() {
        return this._401;
    }

    public get $404() {
        return this._404;
    }

    public get $500() {
        return this._500;
    }

    public setAutoHideOnTime(miliseconds: number) {
        this.autoHideOn = miliseconds;
    }
}
