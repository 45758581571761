import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'bss-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialog {
    @Input() title = 'Confirm action?';
    @Input() yesLabel = 'Yes';
    @Input() noLabel = 'No';

    constructor(private activeModal: NgbActiveModal  ) { }

    /**
     * Close dialog.
     * @param {Boolean} answer
     */
    select(answer = true) {
        return this.activeModal.close(answer);
    }
}
