import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'bss-division-layout',
    templateUrl: './division-layout.page.html',
    styleUrls: ['./division-layout.page.scss']
})
export class DivisionLayoutPage implements OnInit {
    selectedTab: string;

    constructor(
        private route: ActivatedRoute,
        private router: Router) { }

    public ngOnInit() {
        const match = this.router.url.match(/[\-\w]*$/);
        this.selectedTab = match ? match[0] : null;
    }

    public tabChanged($event: NgbTabChangeEvent) {
        const baseUrl = this.router.url.replace(/[\-\w]*$/, '');
        this.router.navigate([baseUrl, $event.nextId]);
    }
}
