import { Injectable } from '@angular/core';
import {
    Router,
    CanActivate,
    CanActivateChild,
    RouterStateSnapshot,
    ActivatedRouteSnapshot
} from '@angular/router';
import { AuthService } from '../services/auth/auth.service';

@Injectable()
export class AdminGuard implements CanActivate, CanActivateChild {

    constructor(private auth: AuthService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.auth.isAuthenticated() && this.auth.isAdmin() ) {
            return true;
        } else {
            localStorage.setItem('after-login-redirect-url', state.url);
            window.location.href = this.auth.getSsoUrl();
            return false;
        }
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }
}
