import { Directive, Input } from '@angular/core';
import { AbstractControl, Validator, NG_VALIDATORS } from '@angular/forms';

@Directive({
    selector: '[bssInRange]',
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: InRangeValidatorDirective,
        multi: true
    }]
})
export class InRangeValidatorDirective implements Validator {
    @Input() bssInRange: Array<number>;

    validate(control: AbstractControl): { [key: string]: any } {
        const resp = { 'bssInRange': { value: '' } };
        if (this.bssInRange.length !== 2) {
            resp.bssInRange.value = 'You must provide minimum and maximum values';
            return resp;
        }
        if (!(control.value >= this.bssInRange[0] && control.value <= this.bssInRange[1])) {
            resp.bssInRange.value = 'Values are out of range';
            return resp;
        }
        return null;
    }
}
