import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { TemplatePool, TemplateSeed, Division } from '../../interfaces';

@Component({
    selector: 'bss-template-pool',
    templateUrl: './template-pool.component.html',
    styleUrls: ['./template-pool.component.scss']
})
export class TemplatePoolComponent implements OnInit {
    @Input() pool: TemplatePool;
    @Input() pools: TemplatePool[] = [];
    @Input() withHeader = true;
    @Input() withAddGroupOption = false;
    @Input() deletable = true;
    @Input() division: Division;
    @ViewChild('popoverCrossPool') popoverCrossPool: any;
    @Output() deleteSeed: EventEmitter<{ seed: TemplateSeed }> = new EventEmitter();
    @Output() addSeed: EventEmitter<{ seed: TemplateSeed, sourcePool: TemplatePool, index: number }|any> = new EventEmitter();
    @Output() editPool: EventEmitter<any> = new EventEmitter();
    @Output() deletePool: EventEmitter<any> = new EventEmitter();
    @Output() addToGroup: EventEmitter<any> = new EventEmitter();
    @Output() removeFromGroup: EventEmitter<any> = new EventEmitter();
    @Output() crossPoolPlayChange: EventEmitter<TemplatePool[]> = new EventEmitter();
    draggingSeed: TemplateSeed;

    constructor() { }

    ngOnInit() {}

    getCrossPoolOptions() {
        return this.pools.filter(pool => {
            return pool.id !== this.pool.id
            && !pool.cross_pool
            && pool.seeds.length === this.pool.seeds.length
            && (!this.pool.group || pool.group && this.pool.group.name === pool.group.name)
            && (!pool.group || this.pool.group && this.pool.group.name === pool.group.name);
        });
    }

    onDeleteSeed(seed: TemplateSeed) {
        this.deleteSeed.emit({ seed });
    }

    onAddSeed(seed: TemplateSeed, sourcePool: TemplatePool, index = null) {
        if (seed) {
            const localSeedIndex = this.pool.seeds.findIndex(s => s.id === seed.id);
            if (localSeedIndex === index) {
                return;
            }
            index = index || 0;
            this.addSeed.emit({seed, sourcePool, index: index });
        } else {
            this.addSeed.emit(null);
        }
    }

    onDragStart(seed: TemplateSeed) {
        this.draggingSeed = seed;
    }

    onDragEnd() {
        this.draggingSeed = null;
    }

    onDeletePool() {
        this.deletePool.emit(this.pool);
    }

    onEditPool() {
        this.editPool.emit(this.pool);
    }

    onAddToPoolGroup() {
        this.addToGroup.emit(this.pool);
    }

    onRemoveFromGroup() {
        this.pool.group = null;
        this.removeFromGroup.emit(this.pool);
    }

    onOpenPopoverCrossPool() {
        if (this.popoverCrossPool) {
            this.popoverCrossPool.open();
        }
    }

    onClosePopoverCrossPool() {
        if (this.popoverCrossPool) {
            this.popoverCrossPool.close();
        }
    }

    addCrossPoolPlay(pool: TemplatePool) {
        this.pool.cross_pool = pool;
        pool.cross_pool = this.pool;
        // Fire event
        this.crossPoolPlayChange.emit([this.pool, pool]);
    }

    removeCrossPoolPlay() {
        const crossPool = this.pools.find(pool => {
            if (pool.cross_pool) {
                return pool.cross_pool.id === this.pool.id;
            }
        });
        if (crossPool) {
            crossPool.cross_pool = null;
        }
        this.pool.cross_pool = null;

        // Fire event
        this.crossPoolPlayChange.emit([this.pool, crossPool]);
    }
}
