import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { environment as env } from '../../../../environments/environment';
import { DataService } from '../common/data.service';
import { AuthService } from '../auth/auth.service';
import { PoolSchedule } from '../../interfaces';

@Injectable()
export class PoolService extends DataService {

    constructor(http: HttpClient, private authService: AuthService) {
        super(`${env.API_BASE_URL}/pools`, http, authService);
    }

    /**
     * Move a game to another position in the Pool
     * @param {number} poolId
     * @param {number} sourceGameId
     * @param {number} targetGameId
     * @returns {Observable<Object>}
     */
    move(poolId: number, sourceGameId: number, targetGameId: number) {
        const headers = new HttpHeaders(
            { 'Authorization': `Bearer ${this.authService.getJWT()}` }
        );
        return this.http
            .put(
                `${this.url}/${poolId}/games/${sourceGameId}/move/to/${targetGameId}`,
                null,
                { headers },
            );
    }

    /**
     * advances a pool for a given poolId
     * @param {number} poolId
     */
    advancePool(poolId: number) {
        const headers = new HttpHeaders(
            { 'Authorization': `Bearer ${this.authService.getJWT()}` }
        );
        return this.http
            .put(
                `${this.url}/pools/${poolId}/advance`,
                null,
                { headers },
            );
    }


    /**
     * Update properties of team standings on a played Pool
     * @param {number} eventId
     * @param {number} divisionId
     * @param {number} poolId
     * @param {number} teamId
     * @param {any} payload
     * @returns {Observable<Object>}
     */
    updateTeamStandings(
        eventId: number,
        divisionId: number,
        poolId: number,
        teamId: number,
        payload: any) {
        const headers = new HttpHeaders(
            { 'Authorization': `Bearer ${this.authService.getJWT()}` }
        );
        return this.http
            .patch(
                `${env.API_BASE_URL}/events/${eventId}/divisions/${divisionId}/pools/${poolId}/teams/${teamId}/standings`,
                payload,
                { headers },
            );
    }

    /**
     * Retrieve a collection PoolSchedule objects
     * @param templateId
     */
    public getSchedules(templateId: number): Observable<{ schedules: PoolSchedule[] }> {
        return this.http
            .get(`${env.API_BASE_URL}/templates/${templateId}/schedules`)
            .map((resp: any) => {
                return {
                    schedules: resp.schedules
                };
            });
    }
}
