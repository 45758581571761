import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FacilityService } from '../../services/facility/facility.service';
import { EventService } from '../../services/event/event.service';
import { Facility, State } from '../../interfaces';
import { AddFacilityComponent } from '../../components/add-facility/add-facility.component';
import { ConfirmationDialog } from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { Observable } from 'rxjs';
import { ManageCourtsComponent } from '../../components/manage-courts/manage-courts.component';

@Component({
    selector: 'bss-facilities',
    templateUrl: './facilities.page.html',
    styleUrls: ['./facilities.page.scss']
})
export class FacilitiesPage implements OnInit {
    private event: any;
    private eventId: number;
    facilities: Facility[];
    states: State[];
    isLoading = true;
    preDraggingItemPosition: number;
    draggingItem: Facility;

    constructor(
        private modalService: NgbModal,
        private eventService: EventService,
        private facilityService: FacilityService,
        private route: ActivatedRoute) { }

    ngOnInit() {
        this.route.parent.params.subscribe(({ eventId }) => {
            this.eventId = eventId;
            this.refresh();
        });
    }

    /**
     * Request facilities from API
     * @returns {Promise<void>}
     */
    async refresh() {
        this.isLoading = true;
        const { facilities, states } = await this.facilityService.getFacilities(this.eventId).toPromise();
        this.facilities = facilities.sort((a, b) => a.order > b.order ? 1 : -1);
        this.states = states;
        this.isLoading = false;
    }

    /**
     * Clean payload before sending POST or PUT requestS
     * @param {Facility} facility
     * @returns {any}
     */
    private cleanPayload(facility: Facility) {
        const payload = JSON.parse(JSON.stringify(facility));
        payload.courts.map(court => {
            court.name = court.name.trim();
        });
        payload.name = payload.name.trim();
        payload.abbreviation = payload.abbreviation.trim();
        payload.street = payload.street.trim();
        payload.zip = payload.zip.trim();
        payload.city = payload.city.trim();
        payload.state_id = payload.state.id;
        delete payload.state;
        delete payload.id;
        return payload;
    }

    /**
     * Send request to change name of facility
     * @param {Facility} facility
     * @param {string} newName
     */
    rename(facility: Facility, newName: string) {
        const payload = this.cleanPayload(facility);
        payload.abbreviation = newName;
        this.facilityService
            .change(this.eventId, facility.id, this.cleanPayload(facility))
            .subscribe(
                () => facility.name = newName,
                err => console.error(err),
                () => this.isLoading = false
            );
    }

    /**
     * Send request to modify the abbreviation of a Facility
     * @param {Facility} facility
     * @param {string} newAbbr
     */
    changeAbbreviation(facility: Facility, newAbbr: string) {
        const payload = this.cleanPayload(facility);
        payload.abbreviation = newAbbr;
        this.facilityService
            .change(this.eventId, facility.id, payload)
            .subscribe(
                () => facility.abbreviation = newAbbr,
                err => console.error(err),
                () => this.isLoading = false
            );
    }

    /**
     * Send request to remove a facility Facility
     * @param {Facility} facility
     */
    removeFacility(facility: Facility) {
        const modalRef = this.modalService.open(ConfirmationDialog);
        modalRef.componentInstance.title = 'Are you sure that you want to delete this Facility?';
        Observable.fromPromise(modalRef.result)
            .subscribe(yes => {
                if (yes) {
                    this.isLoading = true;
                    this.facilityService
                        .remove(this.eventId, facility.id)
                        .subscribe(
                            () => this.refresh(),
                            err => console.error(err),
                            () => this.isLoading = false
                        );
                }
            });
    }

    /**
     * Open modal for managing courts of a Facility
     * @param {Facility} facility
     */
    manageCourts(facility: Facility) {
        const modalRef = this.modalService.open(ManageCourtsComponent);
        modalRef.componentInstance.setup(this.eventId, facility, facility.courts);
        modalRef.result.then(modified => {
            if (modified) {
                this.refresh();
            }
        });
    }

    /**
     * Open modal for Adding a new facility or editing an existing one if facility param is provided
     * @param {Facility} facility
     */
    addOrEditFacility(facility: Facility = null) {
        const modalRef = this.modalService.open(AddFacilityComponent);
        modalRef.componentInstance.setup(this.eventId, facility, this.states);
        modalRef.result.then(updated => {
            if (updated) {
                this.refresh();
            }
        });
    }

    /**
     * Change order of a division in the list of divisions
     */
    move(facility: Facility, targetIndex) {
        if (facility.order !== targetIndex + 1) {
            this.isLoading = true;
            this.eventService.changeFacilityOrder(this.eventId, facility.id, targetIndex + 1)
                .subscribe(
                    () => {
                        this.updateOrderProperties();
                        this.isLoading = false;
                    },
                    err => {
                        this.revertMovement();
                        console.error(err);
                        this.isLoading = false;
                    }
                );
        }
    }

    /**
     * Update property order of facility
     */
    updateOrderProperties() {
        this.facilities.map((facility: Facility, index) => {
            facility.order = index + 1;
        });
    }

    /**
     * Prepare facility for movement
     */
    onDragStart(facility: Facility, index: number) {
        this.draggingItem = facility;
        this.preDraggingItemPosition = index;
    }

    /**
     * End dragging
     */
    onDragEnd() {
        this.draggingItem = null;
    }

    /**
     * Revert reorder of divisions
     */
    revertMovement() {
        const index = this.facilities.findIndex(item => item.id === this.draggingItem.id);
        this.facilities.splice(index, 1);
        this.facilities.splice(this.preDraggingItemPosition, 0, this.draggingItem);
    }

    /**
     * When dragging, reorder facilities on mouse over for realtime user feedback
     * @param targetPosition
     * @param facility
     */
    onDraggingMouseEnter(targetPosition, facility) {
        if (facility) {
            const currentPosition = this.facilities.findIndex(item => item.id === facility.id);
            this.facilities.splice(currentPosition, 1);
            this.facilities.splice(targetPosition, 0, facility);
        }
    }

    /**
     * When dragging, if the dragging area is leaved, revert draggingItem position
     */
    onDraggingMouseLeave() {
        if (this.draggingItem) {
            this.revertMovement();
        }
    }
}
