import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { Router } from '@angular/router';

@Component({
    selector: 'bss-main-nav',
    templateUrl: './main-nav.component.html',
    styleUrls: ['./main-nav.component.scss']
})
export class MainNavComponent implements OnInit {
    isCollapsed = true;

    constructor(private auth: AuthService, private router: Router) {
    }

    ngOnInit() {
    }

    configureMenu() {
        return this.auth.isAdmin();
    }
    isLoggedIn() {
        return this.auth.isAuthenticated();
    }

    logout() {
        this.auth.signOut();
        this.router.navigate(['/']);
    }

}
