import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Facility, Game, PoolSchedule, Division, ScheduleGame, Standings } from '../../../scheduler/interfaces';
import { FacilityService, PoolService, DivisionService } from '../../../scheduler/services';
import { NavbarService } from '../../services';
import { ClipboardService } from 'ngx-clipboard';
import { async } from 'rxjs/internal/scheduler/async';

interface ScheduleGameView extends ScheduleGame {
    facility_id: Number;
}

@Component({
    selector: 'bss-public-game-schedule',
    templateUrl: './game-schedule.page.html',
    styleUrls: ['./game-schedule.page.scss']
})
export class GameSchedulePage implements OnInit {
    gameSchedule: ScheduleGameView;
    game: Game;
    gameid: number;
    poolSchedules: PoolSchedule[];
    facilities: Facility[];
    facility: Facility = null;
    isEventReady: boolean;
    eventId: number;
    pool: PoolSchedule;
    divisionid: number;
    division: Division;
    isLoading = false;
    mapUrl: string;
    standingHome: string = null;
    standingAway: string = null;

    constructor(private route: ActivatedRoute,
        private router: Router,
        public navbarService: NavbarService,
        private facilityService: FacilityService,
        public modalService: NgbModal,
        private poolService: PoolService,
        private divisionService: DivisionService,
        private clipboardService: ClipboardService,
        private datePipe: DatePipe) {
    }


    ngOnInit() {
        this.gameid = this.route.snapshot.params.gameid;
        this.divisionid = this.route.parent.snapshot.params.divisionId;
        this.eventId = this.route.parent.parent.snapshot.params.eventId;
        this.refresh();
    }

    async refresh(withLoadingEffect = true) {
        this.isLoading = withLoadingEffect;
        try {

            this.division = await this.divisionService.getById(this.divisionid + '').toPromise() as Division;
            const scheduleData = await this.poolService.getSchedules(this.division.template_id).toPromise();
            this.poolSchedules = scheduleData.schedules;

            let bFound = false;

            for (const p of this.poolSchedules) {
                for (const g of p.games) {
                    if (Number(g.id) === Number(this.gameid)) {
                        this.pool = p;
                        this.gameSchedule = g as ScheduleGameView;

                        await this.getStandingData();

                        const facilitiesData = await this.facilityService.getFacilities(this.eventId).toPromise();
                        this.facilities = facilitiesData.facilities;

                        for (const f of this.facilities) {
                            if (Number(f.id) === Number(this.gameSchedule.facility_id)) {
                                this.facility = f;
                                this.mapUrl = this.getFacilityLink();
                                bFound = true;
                                break;
                            }
                        }
                    }
                    if (bFound) {
                        break;
                    }
                }
                if (bFound) {
                    break;
                }
            }
        } catch (err) {
            console.log(err);
        }
        finally {
            this.isLoading = false;
        }
    }

    async getStandingData() {
        const data = await this.divisionService.getDivisionStandings(this.divisionid).toPromise();
        const standings: Standings[] = data;

        for (const s of standings) {
            if (s.id === this.pool.id) {
                for (const seed of s.seeds) {
                    if (Number(seed.team.id) === Number(this.gameSchedule.home_seed.team.id)) {
                        if ((seed.team.wins || -1) === -1 || (seed.team.losses || -1) === -1) {
                            this.standingHome = '';
                        } else {
                            this.standingHome = seed.team.wins.toString() + '-' + seed.team.losses.toString();
                        }

                    }
                    if (Number(seed.team.id) === Number(this.gameSchedule.away_seed.team.id)) {
                        if ((seed.team.wins || -1) === -1 || (seed.team.losses || -1) === -1) {
                            this.standingAway = '';
                        } else {
                            this.standingAway = seed.team.wins.toString() + '-' + seed.team.losses.toString();
                        }
                    }
                }
                break;
            }
        }
    }

    onTeamAClick() {
        this.router.navigate([`/schedules/${this.eventId}/divisions/${this.divisionid}/game-schedule/${this.gameid}/team-schedule/${this.gameSchedule.away_seed.id}`]);
    }

    onTeamBClick() {
        this.router.navigate([`/schedules/${this.eventId}/divisions/${this.divisionid}/game-schedule/${this.gameid}/team-schedule/${this.gameSchedule.home_seed.id}`]);
    }

    onCopyAddressClick($event) {
        const data = this.getAddressString();

        try {
            this.clipboardService.copyFromContent(data);
        } catch (err) {
            console.log(err);
        }
    }

    getAddressString(): string {
        let data = (this.facility.street || '').trim();

        if (data !== '') {
            data += ', ';
        }

        data += (this.facility.city || '').trim() + ', ' + (this.facility.state.abbreviation || '') + ', ' + (this.facility.zip);
        return data;
    }

    getGogleUrlFromAddress(): string {
        const baseUrl = 'https://www.google.com/maps/search/?api=1&query=';
        const data = encodeURIComponent(this.getAddressString());

        return baseUrl + data;
    }

    getFacilityLink(): string {
        return this.facility.map.url || this.getGogleUrlFromAddress();
    }

    onOpenInMapClick($event) {
        window.open(this.mapUrl, '_blank');
    }
}
