import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { DivisionService, EventService } from '../../../scheduler/services';
import { BracketTemplateDataService } from '../../services';
import { Bracket, GameBlock } from '../../../scheduler/interfaces';

@Component({
    selector: 'bss-public-brackets-tree',
    templateUrl: './brackets-tree.page.html',
    styleUrls: ['./brackets-tree.page.scss']
})
export class BracketsTreePage implements OnInit {
    gameBlocks = [];
    teams = [];
    isLoading = false;

    constructor(
        public bracketTemplateDataService: BracketTemplateDataService,
        private divisionService: DivisionService,
        private eventService: EventService,
        private route: ActivatedRoute) {
        this.route.parent.parent.params.subscribe(async (params) => {
            this.isLoading = true;
            try {
                this.teams = await this.divisionService.getSeeds(params.divisionId).toPromise();
                if (this.bracketTemplateDataService.selectedBracket) {
                    this.gameBlocks = this.getPublicGameBlocks(this.bracketTemplateDataService.selectedBracket);
                }
                this.bracketTemplateDataService.selectedBracketUpdated.subscribe(bracket => {
                    this.gameBlocks = this.getPublicGameBlocks(bracket);
                });
            } catch (err) {
                console.error(err);
            }
            this.isLoading = false;
        });
    }

    getPublicGameBlocks(bracket: Bracket) {
        const offset = 50;
        const modifier = 1.2;
        const gameBlocks = _.cloneDeep(bracket.gameBlocks);

        let smallestTop;
        let smallestLeft;

        gameBlocks.forEach((block: GameBlock) => {
            const top = parseFloat(block.configs.ui.data.style.top);
            const left = parseFloat(block.configs.ui.data.style.left);
            // get smallest top
            if (!smallestTop || top < smallestTop) {
                smallestTop = top;
            }
            // get smallest left
            if (!smallestLeft || left < smallestLeft) {
                smallestLeft = left;
            }
        });

        return gameBlocks.map((block: GameBlock) => {
            const top = parseFloat(block.configs.ui.data.style.top);
            const left = parseFloat(block.configs.ui.data.style.left);
            const width = parseFloat(block.configs.ui.data.style.width);
            const height = parseFloat(block.configs.ui.data.style.height);

            if (block.game) {
                if (block.game.seed1) {
                    const team1 = this.teams.find(team => team.id === block.game.seed1.team_id);
                    block.participantLabel1 = team1 ? team1.name : block.participantLabel1;
                }
                if (block.game.seed2) {
                    const team2 = this.teams.find(team => team.id === block.game.seed2.team_id);
                    block.participantLabel2 = team2 ? team2.name : block.participantLabel2;
                }
            }

            // reduce all gameblocks by smallest left & top and add offsets
            // multiple game block style values by a modifier to enlarge block
            block.configs.ui.data.style.top = `${((top - (smallestTop * modifier)) * modifier) + offset }px`;
            block.configs.ui.data.style.left = `${((left - (smallestLeft * modifier)) * modifier) + offset }px`;
            block.configs.ui.data.style.width = `${ width * modifier }px`;
            block.configs.ui.data.style.height = `${ height * modifier }px`;

            return block;
        });
    }

    ngOnInit() {}
}


