import {Component, OnInit} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormArray, ValidatorFn, Validators, AbstractControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Facility, Court, State } from '../../interfaces';
import { FacilityService } from '../../services/facility/facility.service';

@Component({
    selector: 'bss-add-facility',
    templateUrl: './add-facility.component.html',
    styleUrls: ['./add-facility.component.scss']
})
export class AddFacilityComponent implements OnInit {
    isLoading = false;
    facility: Facility;
    facilityForm: FormGroup;
    courtsFormArray: FormArray;
    mapCtrlGroup: FormGroup;
    states: State[];
    eventId: number;

    constructor(
        public activeModal: NgbActiveModal,
        private fb: FormBuilder,
        private facilityService: FacilityService) {
        this.buildForm();
    }

    ngOnInit() {}

    buildForm() {
        this.courtsFormArray = this.fb.array([]);
        this.mapCtrlGroup = this.fb.group({
            url: ['', this.urlValidator]
        });
        this.facilityForm = this.fb.group({
            name: ['', Validators.required],
            abbreviation: ['', Validators.required],
            street: ['', Validators.required],
            city: ['', Validators.required],
            zip: ['', Validators.required],
            state_id: [0, Validators.required],
            courts: this.courtsFormArray,
            map: this.mapCtrlGroup
        });
    }

    /**
     * Setup required properties of this modal
     * @param eventId
     * @param {Facility} facility
     * @param states
     */
    setup(eventId, facility: Facility = null, states) {
        this.eventId = eventId;
        this.states = states;
        if (facility) {
            // Add as many court text fields as courts exists
            facility.courts.forEach(court => this.addCourt());
            // Set initial values
            this.facilityForm.reset({
                name: facility.name,
                abbreviation: facility.abbreviation,
                street: facility.street,
                city: facility.city,
                zip: facility.zip,
                state_id: facility.state.id,
                map: facility.map,
                courts: facility.courts
            });
        } else {
            this.autogenerateAbbr();
            this.addCourt();
        }
        this.facility = facility;
    }

    /**
     * Add form field to type a new court name
     * @param {Court} court
     */
    addCourt(court: Court = { name: ''}) {
        this.courtsFormArray.push(this.fb.group({
            id: [court.id],
            name: [court.name, Validators.required]
        }));
    }

    /**
     * Remove a Court from the array of Courts
     * @param $event
     * @param court
     * @param i
     */
    deleteCourt($event, court, i) {
        if (
            $event.key === 'Backspace' &&
            court.get('name').value === '' &&
            this.courtsFormArray.controls.length > 1) {
            this.courtsFormArray.controls.splice(i, 1);
            this.courtsFormArray.updateValueAndValidity();
        }
    }

    /**
     * Send the updates to the Courts of the Facility
     */
    onSubmit() {
        if (!this.facilityForm.valid) {
            return;
        }
        this.isLoading = true;
        const payload = this.cleanPayload(this.facilityForm.getRawValue());
        if (this.facility) {
            this.facilityService
            .change(this.eventId, this.facility.id, payload)
            .subscribe(
                () => this.activeModal.close(true),
                err => console.error(err),
                () => this.isLoading = false
            );
        } else {
            this.facilityService
            .add(this.eventId, payload)
            .subscribe(
                () => this.activeModal.close(true),
                err => console.error(err),
                () => this.isLoading = false
            );
        }
    }

    /**
     * Prepare facility object for API request
     * @param data
     * @returns {any}
     */
    private cleanPayload(data) {
        const payload = JSON.parse(JSON.stringify(data));
        payload.courts.map(court => {
            if (!court.id) {
                delete court.id;
            }
            court.name = court.name.trim();
        });
        payload.name = payload.name.trim();
        payload.abbreviation = payload.abbreviation.trim();
        payload.street = payload.street.trim();
        payload.zip = payload.zip.trim();
        payload.city = payload.city.trim();
        delete payload.state;
        delete payload.id;
        return payload;
    }

    /**
     * Validator for the image.url property of Facility
     * @param {AbstractControl} control
     * @returns {{[p: string]: any}}
     */
    private urlValidator(control: AbstractControl): { [key: string]: any} {
        if (control.value === '' || control.value === null) {
            return null;
        }
        const value = control.value.trim();
        const forbidden = /^(ftp|http|https):\/\/[^ "]+$/.test(value);
        return !forbidden ? {'Invalid URL': { value }} : null;
    }

    /**
     * Autogenerate facility abbreviation from the initials of the facility name
     */
    private autogenerateAbbr() {
        const nameCtrl = this.facilityForm.get('name') as FormControl;
        const nameCtrlWatcher = nameCtrl.valueChanges.subscribe(value => {
            const abbrCtrl = this.facilityForm.get('abbreviation') as FormControl;
            if (abbrCtrl.pristine) {
                const abbr = value
                .split(' ')
                .map(word => word[0])
                .join('').toUpperCase();
                abbrCtrl.setValue(abbr);
            } else {
                nameCtrlWatcher.unsubscribe();
            }
        });
    }

}
