import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmationDialog} from '../../shared/components/confirmation-dialog/confirmation-dialog.component';
import {GameService} from '../../scheduler/services';
import {ScheduleGame} from '../../scheduler/interfaces';

export class GameScoringPage {
    public isLoading: boolean;

    constructor(
        protected ngbModal: NgbModal,
        protected gameService: GameService) {}

    protected async scoreGame(eventId: number, divisionId: number, game: ScheduleGame, $event: any, allowTies: boolean, poolName: string) {
        // If scores are the same for both teams, validate that ties are allowed
        if ($event.team1.score === $event.team2.score && allowTies === false) {
            this.displayWarning(game, poolName);
            throw new Error('Ties are not allow');
        }
        const data = {
            game_id: game.id,
            team_1_score: $event.team1.score,
            team_1_cards_yellow: $event.team1.yellow_cards,
            team_1_cards_red: $event.team1.red_cards,
            team_1_penalty_kicks: $event.team1.penalty_kicks,
            team_2_score: $event.team2.score,
            team_2_cards_yellow: $event.team2.yellow_cards,
            team_2_cards_red: $event.team2.red_cards,
            team_2_penalty_kicks: $event.team2.penalty_kicks,
        };

        // API PUT will fail if some of the cards are null
        for (const i in data) {
            if (data[i] === null) {
                data[i] = undefined;
            }
        }

        const payload = {
            games: [
                data
            ]
        };

        return await this.gameService.scoreGames(eventId, divisionId, payload).toPromise();
    }

    displayWarning(game, poolName) {
        const modalRef = this.ngbModal.open(ConfirmationDialog);
        modalRef.componentInstance.title =
            `Invalid scores for <em>${game.home_seed.team.name}</em> vs <em>${game.away_seed.team.name}</em> in Pool ${poolName}.
            <br/><br/><strong>Ties are not allowed.</strong>`;
        modalRef.componentInstance.yesLabel = 'OK';
        modalRef.componentInstance.noLabel = null;
        return;
    }
}
