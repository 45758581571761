import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

@Component({
    selector: 'bss-teams-and-divisions',
    templateUrl: './teams-and-divisions.page.html',
    styleUrls: ['./teams-and-divisions.page.scss']
})
export class TeamsAndDivisionsPage implements OnInit {
    eventDisplayString;

    constructor(
        private route: ActivatedRoute,
        public router: Router) {}

    ngOnInit() {
        this.route.data.subscribe((data) => {
            const event = data.eventsAndDivisions.event;
            this.eventDisplayString = this.buildEventDisplayString(event);
        });
    }

    buildEventDisplayString(event) {
        const start = moment.utc(event.start_date);
        const end = moment.utc(event.end_date);
        const isSingleMonth = start.month() === end.month();
        const isSingleDay = isSingleMonth && start.date() === end.date();
        return (
            `${
                event.name
                } ${
                start.format('MMM D')
                }${
                isSingleDay ? '' : '-'
                }${(isSingleDay ?
                    start.format(', YYYY') :
                    (isSingleMonth ?
                            `${end.format('D, YYYY')}` :
                            `${end.format('MMM D, YYYY')}`
                    )
            )
                } ${
            event.location || ''
                }`);
    }

    onGridPage() {
        const urlSplit = this.router.url.split('/');
        return urlSplit[3] && urlSplit[3] === 'grid' && urlSplit.length === 4;
    }
}
