import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Event } from '../../interfaces';
import { EventService } from '../../services';

@Component({
    selector: 'bss-event-settings',
    templateUrl: './event-settings.page.html',
    styleUrls: ['./event-settings.page.scss']
})
export class EventSettingsPage implements OnInit {
    eventDisplayString;
    eventId: number;
    sportId: number;
    event: Event;

    constructor(
        private route: ActivatedRoute,
        private eventService: EventService) {}

    async ngOnInit() {
        this.refresh();
    }

    async refresh() {
        this.event = await this.eventService.getById(this.route.snapshot.params.eventId).toPromise() as Event;
        this.sportId = this.event.sport_id;
        this.eventDisplayString = this.buildEventDisplayString(this.event);
    }

    buildEventDisplayString(event) {
        const start = moment.utc(event.start_date);
        const end = moment.utc(event.end_date);
        const isSingleMonth = start.month() === end.month();
        const isSingleDay = isSingleMonth && start.date() === end.date();
        return (
            `${
                event.name
                } ${
                start.format('MMM D')
                }${
                isSingleDay ? '' : '-'
                }${(isSingleDay ?
                    start.format(', YYYY') :
                    (isSingleMonth ?
                            `${end.format('D, YYYY')}` :
                            `${end.format('MMM D, YYYY')}`
                    )
            )} ${event.location || ''}`);
    }
}
