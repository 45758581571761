import {Component, OnInit, Input} from '@angular/core';

@Component({
    selector: 'bss-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
    @Input() pathArr: string[][];

    constructor() {
    }

    ngOnInit() {
    }

}
