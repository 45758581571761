import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BracketTemplateDataService } from '../../services';
import {
    AuthService,
    DivisionService,
    EventService,
    GameService
} from '../../../scheduler/services';
import { ScorerComponent } from '../../../shared/components/scorer/scorer.component';
import { ScoringConfig } from '../../../shared/interfaces';
import { GameScoringPage } from '../../classes/game-scoring.page';

@Component({
    selector: 'bss-public-brackets-list',
    templateUrl: './brackets-list.page.html',
    styleUrls: ['./brackets-list.page.scss']
})
export class BracketsListPage extends GameScoringPage implements OnInit, OnDestroy {

    isAuthenticated = false;
    divisionSeeds = [];
    divisionId;
    division;
    eventId;
    teams: any;
    divisionIdParamSubscriber;
    eventIdParamSubscriber;
    @Output() refresh: EventEmitter<any> = new EventEmitter();
    scoringConfig: ScoringConfig;
    isLoading = false;

    constructor(
        public bracketTemplateDataService: BracketTemplateDataService,
        private _divisionService: DivisionService,
        private _eventService: EventService,
        private _authService: AuthService,
        private _route: ActivatedRoute,
        ngbModal: NgbModal,
        _gameService: GameService) {
        super(ngbModal, _gameService);
    }

    ngOnInit() {
        this.isAuthenticated = this._authService.isAuthenticated();
        this.divisionIdParamSubscriber = this._route.parent.parent.paramMap.subscribe(async (params: ParamMap) => {
            this.isLoading = true;
            try {
                this.divisionId = params.get('divisionId');
                this.division = await this._divisionService.getById(this.divisionId).toPromise();
                this.divisionSeeds = await this._divisionService.getSeeds(this.divisionId).toPromise();
                this.scoringConfig = await this._eventService.getScoringConfig(this.division.template_id).toPromise();
            } catch (err) {
                console.error(err);
            }
            this.isLoading = false;
        });
        this.eventIdParamSubscriber = this._route.parent.parent.parent.paramMap.subscribe(async (params: ParamMap) => {
            this.isLoading = true;
            try {
                this.eventId = params.get('eventId');
            } catch (err) {
                console.error(err);
            }
            this.isLoading = false;
        });
    }

    /**
     * Bracket view and bracket list shared the same bracket source of true
     */
    get gameBlocks() {
        const blocks = this.bracketTemplateDataService.selectedBracket
            ? this.bracketTemplateDataService.selectedBracket.gameBlocks.filter(gb => gb.game)
            : [];

        blocks.map(this.attachStandingsToTeams);
        return blocks;
    }

    ngOnDestroy() {
        this.divisionIdParamSubscriber.unsubscribe();
        this.eventIdParamSubscriber.unsubscribe();
    }

    /**
     * Attach an standing object to team object (if not present)
     */
    private attachStandingsToTeams(gameBlock) {
        if (!gameBlock.game) {
            return;
        }
        if (gameBlock.game.seed1 && gameBlock.game.seed1.team && !gameBlock.game.seed1.team.standings) {
            gameBlock.game.seed1.team.standings = {
                score: gameBlock.game.team_1_score,
                red_cards: gameBlock.game.team_1_cards_red,
                yellow_cards: gameBlock.game.team_1_cards_yellow,
                penalty_kicks: gameBlock.game.team_1_penalty_kicks,
            };
        }
        if (gameBlock.game.seed2 && gameBlock.game.seed2.team && !gameBlock.game.seed2.team.standings) {
            gameBlock.game.seed2.team.standings = {
                score: gameBlock.game.team_2_score,
                red_cards: gameBlock.game.team_2_cards_red,
                yellow_cards: gameBlock.game.team_2_cards_yellow,
                penalty_kicks: gameBlock.game.team_2_penalty_kicks,
            };
        }
    }

    /**
     * Update the standings object of previously updated teams
     */
    private updateTeamStandings(game, team1Standings, team2Standings) {
        game.seed1.team.standings = { ...team1Standings };
        game.seed2.team.standings = { ...team2Standings };
    }

    /**
     * Handle save event of the scorer component
     * @param $event
     * @param game
     * @param pool
     * @param $cmp
     */
    async onScoreGame($event, game, groupName, $cmp: ScorerComponent) {
        this.isLoading = true;
        try {
            await this.scoreGame(this.eventId, this.divisionId, game, $event, true, groupName);
            this.updateTeamStandings(game, $event.team1, $event.team2);
        } catch (err) {
            console.error(err);
            $cmp.reset();
        }
        this.isLoading = false;
    }

}
