import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Template } from '../../interfaces';
import { TemplateService } from '../../services/template/template.service';

@Component({
    selector: 'bss-create-template',
    templateUrl: './create-template.component.html',
    styleUrls: ['./create-template.component.scss']
})
export class CreateTemplateComponent {
    constructor(
        private templates: TemplateService,
        public modal: NgbActiveModal) {
    }

    @Input() template: Template = {
        name: '',
        team_count: null,
        is_default: true,
        pool_size: null,
        folder_name: '',
        bracket_only: 'no'
    };

    @Input() folders = [];

    isNewFolderMode = false;

    validate(form) {
        const {name, teams, bracketOnly, folder, pool} = form;
        if (!name || !folder) { return false; }
        if (teams < 3 || teams > 128) { return false; }
        if (bracketOnly === 'no') {
            if (pool < 3 || pool > teams) { return false; }
        }
        return true;
    }

    onSubmit() {
        if (this.template.bracket_only === 'yes') {
            delete this.template.pool_size;
        }
        delete this.template.bracket_only;
        this.templates.create(this.template)
            .subscribe(
                (response) => {
                    this.modal.close({ response, template: this.template });
                },
                (err) => {
                    console.error(err);
                }
            );
    }
}
