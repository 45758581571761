import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Facility } from '../../../scheduler/interfaces/index';
import { Division, Standings } from '../../../scheduler/interfaces';
import { PrintDataService } from '../../services';
import {
    DivisionService,
    FacilityService,
    PoolService
} from '../../../scheduler/services';

@Component({
    selector: 'bss-public-facilities',
    templateUrl: './facilities.page.html',
    styleUrls: ['./facilities.page.scss']
})

export class FacilitiesPage implements OnInit {

    division = <Division>{};
    divisionId: string;
    eventId: number;
    facilities: Facility[];
    isFacilitiesReady = false;
    public standings: StandingsView[];

    constructor(
        private route: ActivatedRoute,
        private divisionService: DivisionService,
        private facilityService: FacilityService,
        private poolService: PoolService,
        private _printDataService: PrintDataService) {
    }

    ngOnInit() {
        this.route.parent.parent.params.subscribe(params => {
            this.isFacilitiesReady = true;
            this.eventId = params.eventId;
            const observable = this.facilityService.getFacilities(this.eventId);
            observable.subscribe(
                (data: any) => {
                    this.facilities = data.facilities;
                    this.isFacilitiesReady = false;
                }
            );
            return observable;
        });

        this.route.parent.params.subscribe(params => {
            this.divisionId = params.divisionId;
            this.refresh();
        });
    }

    /**
     * [refresh description]
     * @return {void}
     */
    async refresh() {
        this.isFacilitiesReady = true;
        try {
            this.division = await this.divisionService.getById(this.divisionId).toPromise() as Division;
            if (this.division && this.division.template_id) {
                const scheduleData = await this.poolService.getSchedules(this.division.template_id).toPromise();
                this._printDataService.setScheduleData(scheduleData.schedules);
                const data = await this.divisionService.getDivisionStandings(Number(this.divisionId)).toPromise();
                this.standings = data.map((pool: StandingsView) => {
                    if (pool.seeds.length === 0) {
                        return;
                    }
                    const duplicate = (() => {
                        const seen = [];
                        for (let i = 0; i < pool.seeds.length; i++) {
                            if (seen.indexOf(pool.seeds[i].pool_rank) > -1) {
                                return true;
                            }
                            seen.push(pool.seeds[i].pool_rank);
                        }
                        return false;
                    })();
                    if (duplicate) {
                        pool.editableSeeds = true;
                    }
                    return pool;
                });

                this._printDataService.setStandingsData(this.standings);
            }
        } catch (err) {
            console.error(err);
        }

        this.isFacilitiesReady = false;
    }

}

interface StandingsView extends Standings {
    editableSeeds?: boolean;
    invalidSeed?: boolean;
}
