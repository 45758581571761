import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDropdownModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { NguiDatetimePickerModule } from '@ngui/datetime-picker';
import { MatMenuModule } from '@angular/material';
import { SharedModule } from '../shared/shared.module';
import { SchedulerModule } from '../scheduler/scheduler.module';
import { PublicRoutingModule } from './public-routing.module';

// Pages
import { MasterPage } from './pages/master/master.page';
import { PoolStandingsPage } from './pages/pool-standings/pool-standings.page';
import { EventListPage } from './pages/event-list/event-list.page';
import { FacilitiesPage } from './pages/facilities/facilities.page';
import { DivisionListPage } from './pages/division-list/division-list.page';
import { DivisionPage } from './pages/division/division.page';
import { EventPage } from './pages/event/event.page';
import { PoolSchedulesPage } from './pages/pool-schedules/pool-schedules.page';
import { BracketsPage } from './pages/brackets/brackets.page';
import { BracketsListPage } from './pages/brackets-list/brackets-list.page';
import { BracketsTreePage } from './pages/brackets-tree/brackets-tree.page';

// Components
import { FiltersBarComponent } from './components/filters-bar/filters-bar.component';

// Services
import { BracketTemplateDataService } from './services/index';
import { ExternalDetailsService } from './services/external-details/external-details.service';
import { FiltersBarService } from './services/index';
import { NavbarService } from './services/navbar/navbar.service';
import { PrintService } from './services/print/print.service';
import { PrintDataService } from './services/printData/print-data.service';
import { EventHistoryService } from './services/event-history/event-history.service';

// Guards and Resolvers

// Interceptors

// Pipes
import { Safe } from '../shared/pipes/safe.pipe';
import { TeamPoolRankSortPipe } from './pipes/team-pool-rank-sort.pipe';
import { GameBlockSortByGameNumberPipe } from './pipes/game-block-sort-by-game-number.pipe';
import { TieBreakerResolver } from './components/tiebreaker-resolver/tiebreaker-resolver';
import { GameSchedulePage } from './pages/game-schedule/game-schedule.page';
import { TeamSchedulePage } from './pages/team-schedule/team-schedule.page';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        ReactiveFormsModule,
        FormsModule,
        NgbDropdownModule,
        MatMenuModule,
        NgbModalModule,
        NguiDatetimePickerModule,
        PublicRoutingModule,
        SharedModule,
        SchedulerModule
    ],
    declarations: [
        // Pages
        MasterPage,
        EventListPage,
        EventPage,
        FacilitiesPage,
        DivisionListPage,
        DivisionPage,
        PoolStandingsPage,
        PoolSchedulesPage,
        BracketsPage,
        BracketsListPage,
        BracketsTreePage,
        GameSchedulePage,
        TeamSchedulePage,
        // Components
        FiltersBarComponent,
        // Pipes
        Safe,
        TeamPoolRankSortPipe,
        GameBlockSortByGameNumberPipe,
        TieBreakerResolver,
    ],
    entryComponents: [
        TieBreakerResolver,
    ],
    providers: [
        // Services
        DatePipe,
        BracketTemplateDataService,
        ExternalDetailsService,
        FiltersBarService,
        NavbarService,
        PrintService,
        PrintDataService,
        // Interceptors
        // Guards & Resolvers
        EventHistoryService
    ]
})
export class PublicModule {
}
