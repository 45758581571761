import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import * as moment from 'moment';
import { environment as env } from '../../../../environments/environment';
import {
    Event,
    TemplatePool,
    Division,
    Team,
    GameBlockSetting,
    CustomPoints,
    EventStandingsConfig,
    EventStandingsConfigItem, SportStandingsConfig,
} from '../../interfaces';
import { DataService } from '../common/data.service';
import { AuthService } from '../auth/auth.service';
import { ScoringConfig } from '../../../shared/interfaces';
import { PointLabels } from '../../../shared/utils/point-labels';

@Injectable()
export class EventService extends DataService {
    constructor(http: HttpClient, private authService: AuthService) {
        super(`${env.API_BASE_URL}/events`, http, authService);
    }

    /**
     * Retrieve a single event by eventId
     * @param {number} eventId
     * @returns {Observable<Event>}
     */
    getEvent(eventId: number) {
        return this.http.get<Event>(`${env.API_BASE_URL}/events/${eventId}`);
    }

    /**
     * Retrieve a list of events
     * @param {boolean} sortAsc
     * @param {string} search
     * @param {Date} minDate
     * @param {Date} maxDate
     * @returns {Observable<Event[]>}
     */
    public getList(sortAsc = true, search = '', minDate: Date = null, maxDate: Date = null, activeOnly = true): Observable<Event[]> {
        const params: any = {};

        params.sort = (sortAsc ? '' : '-') + 'start_date';

        if (search) {
            params.search = search.trim().replace(/\s+/g, '+');
            params.name = params.search; // Delete this when the search gets implemented in events API
        }

        if (minDate) {
            params['min_start_date'] = minDate.toISOString();
        }

        if (maxDate) {
            params['max_start_date'] = maxDate.toISOString();
        }

        if (!activeOnly) {
            params['active_only'] = false;
        }

        const operatorId = this.authService.getOperatorId();
        if (this.authService.isOperator() && operatorId) {
            params['operator_id'] = operatorId;
        }

        return this.http
            .get(env.API_BASE_URL + '/events', { params: params })
            .map((data: any) => {
                return data.events.map(item => {
                    const startDate = moment.utc(item.start_date);
                    const endDate = moment.utc(item.end_date);
                    return {
                        ...item,
                        start_date: new Date(Date.UTC(startDate.year(), startDate.month(), startDate.date(), startDate.hours(), startDate.minutes(), startDate.seconds())),
                        end_date: new Date(Date.UTC(endDate.year(), endDate.month(), endDate.date(), endDate.hours(), endDate.minutes(), endDate.seconds()))
                    };
                }).sort((itemA: Event, itemB: Event) => {
                    return itemA.start_date.getMilliseconds() - itemB.start_date.getMilliseconds();
                });
            });
    }

    /**
     * Get a list of divisions that belongs to an event
     * @param {number} eventId
     * @returns {Observable<Division[]>}
     */
    getDivisions(eventId: number): Observable<{ event: Event, divisions: Division[] }> {
        const url = `${env.API_BASE_URL}/events/${eventId}/divisions`;
        const headers = new HttpHeaders(
            { 'Authorization': `Bearer ${this.authService.getJWT()}` }
        );
        return this.http.get(url, { headers })
            .map((response: any) => {
                const divs = response.divisions;
                delete response.divisions;
                const res: { event: Event, divisions: Division[] } = {
                    event: response,
                    divisions: divs
                };
                return res;
            })
            .catch(this.handleError);
    }

    /**
     * Get a list of divisions that belongs to an event for public view
     * @param {number} eventId
     * @returns {Observable<Division[]>}
     */
    getPublicDivisions(eventId: number): Observable<{ event: Event, divisions: Division[] }> {
        const url = `${env.API_BASE_URL}/events/${eventId}/divisions/public`;
        const headers = new HttpHeaders(
            { 'Authorization': `Bearer ${this.authService.getJWT()}` }
        );
        return this.http.get(url, { headers })
            .map((response: any) => {
                const divs = response.divisions;
                delete response.divisions;
                const res: { event: Event, divisions: Division[] } = {
                    event: response,
                    divisions: divs
                };
                return res;
            })
            .catch(this.handleError);
    }

    /**
     * Get a list of pools that belongs to an event
     * @param {number} eventId
     * @returns {Observable<TemplatePool[]>}
     */
    getPools(eventId: number): Observable<any[]> {
        const url = `${env.API_BASE_URL}/events/${eventId}/pools`;
        const headers = new HttpHeaders(
            { 'Authorization': `Bearer ${this.authService.getJWT()}` }
        );
        return this.http.get(url, { headers })
            .map((response: any) => {
                return response.divisions;
            })
            .catch(this.handleError);
    }

    getGridSettings(eventId: number) {
        const url = `${env.API_BASE_URL}/events/${eventId}/settings`;
        const headers = new HttpHeaders({
            'Authorization': `Bearer ${this.authService.getJWT()}`
        });
        return this.http.get(url, { headers })
            .map(response => response)
            .catch(this.handleError);
    }

    createGridSettings(eventId: number, gridSettings: GameBlockSetting) {
        const url = `${env.API_BASE_URL}/events/${eventId}/settings`;
        const headers = new HttpHeaders({
            'Authorization': `Bearer ${this.authService.getJWT()}`
        });
        return this.http.post(url, gridSettings, { headers })
            .map(response => response)
            .catch(this.handleError);
    }

    updateGridSettings(eventId: number, gridSettingId: number, gridSettings: GameBlockSetting) {
        const url = `${env.API_BASE_URL}/events/${eventId}/settings/${gridSettingId}`;
        const headers = new HttpHeaders({
            'Authorization': `Bearer ${this.authService.getJWT()}`
        });
        return this.http.put(url, gridSettings, { headers })
            .map(response => response)
            .catch(this.handleError);
    }

    dropGridSettings(eventId: number, settingsId: number) {
        const url = `${env.API_BASE_URL}/events/${eventId}/settings/${settingsId}`;
        const headers = new HttpHeaders({
            'Authorization': `Bearer ${this.authService.getJWT()}`
        });
        return this.http.delete(url, { headers })
            .map(response => response)
            .catch(this.handleError);
    }

    /**
     * Add a new division to an event
     * @param {number} eventId
     * @param {Division} division
     */
    addDivision(eventId: number, division: Division) {
        const headers = new HttpHeaders(
            { 'Authorization': `Bearer ${this.authService.getJWT()}` }
        );
        return this.http.post(`${this.url}/${eventId}/divisions`, division, { headers })
            .map(response => response)
            .catch(this.handleError);
    }

    /**
     * Add a team to an event and associate it to a division
     * @param {number} eventId
     * @param {Team} team
     */
    addTeam(eventId: number, team: Team) {
        const headers = new HttpHeaders(
            { 'Authorization': `Bearer ${this.authService.getJWT()}` }
        );
        return this.http.post(`${this.url}/${eventId}/teams`, team, { headers })
            .map(response => response)
            .catch(this.handleError);
    }

    /**
     * Retrieve a collection of teams that belongs to the events
     * @param {number} eventId
     */
    public getTeams(eventId: number) {
        return this.http.get(`${this.url}/${eventId}/teams`)
            .map((resp: any) => resp.teams)
            .catch(this.handleError);
    }

    /**
     * Change order of a division
     * @param {number} eventId
     * @param {number} divisionId
     * @param {number} order
     * @returns {Observable<Object>}
     */
    changeDivisionOrder(eventId: number, divisionId: number, order: number) {
        const headers = new HttpHeaders(
            { 'Authorization': `Bearer ${this.authService.getJWT()}` }
        );
        return this.http
            .patch(`${env.API_BASE_URL}/events/${eventId}/divisions/${divisionId}/order`, { order }, { headers });
    }

    /**
     * Change order of a facility
     * @param {number} eventId
     * @param {number} facilityId
     * @param {number} order
     * @returns {Observable<Object>}
     */
    changeFacilityOrder(eventId: number, facilityId: number, order: number) {
        const headers = new HttpHeaders(
            { 'Authorization': `Bearer ${this.authService.getJWT()}` }
        );
        return this.http
            .patch(`${env.API_BASE_URL}/events/${eventId}/facilities/${facilityId}/order`, { order }, { headers });
    }

    /**
     * Publish or unpublish an event
     * @param active
     * @param {number} eventId
     * @returns {Observable<Object>}
     */
    publish(active, eventId: number) {
        const headers = new HttpHeaders(
            { 'Authorization': `Bearer ${this.authService.getJWT()}` }
        );
        return this.http
            .put(`${this.url}/${eventId}/publish`, { active }, { headers });
    }

    public getPoints(eventId: number) {
        return this.http
            .get(`${this.url}/${eventId}/points`);
    }

    public updatePoints(eventId: number, customPoints: CustomPoints) {
        const headers = new HttpHeaders(
            { 'Authorization': `Bearer ${this.authService.getJWT()}` }
        );
        return this.http
            .patch(`${this.url}/${eventId}/points`, customPoints, { headers });
    }

    /**
     * Retrieve the scoring configuration for the sport event
     * @param {number} templateId
     * @returns {Observable<any[]>}
     */
    getScoringConfig(templateId: number): Observable<ScoringConfig> {
        return this.http
            .get(env.API_BASE_URL + '/templates/' + templateId + '/schedules')
            .map((resp: any) => {
                return resp.score_config;
            });
    }

    /**
     * Returns the config of an event
     * @param {number} eventId
     * @returns {Observable<({id: number; name: string; alias: string; order: number} | {id: number; name: string; alias: string; order: any})[]>}
     */
    public getEventStandingsConfig(eventId: number) {
        return this.http
            .get(`${this.url}/${eventId}/standings/config`);
    }

    /**
     * Returns the standing config of an sport
     * @param sportId
     */
    public getSportStandingsConfig(sportId: number): Observable<SportStandingsConfig> {
        return this.http
            .get(`${env.API_BASE_URL}/sports/${sportId}/standings/config`)
            .map((resp: any) => resp.sportStandingsConfig);
    }



    /**
     * Set Custom Standings Columns configuration
     * @param {number} eventId
     * @param {EventStandingsConfig} config
     * @returns {Observable<Object>}
     */
    public setEventStandingsConfig(eventId: number, config: EventStandingsConfig) {
        const headers = new HttpHeaders(
            { 'Authorization': `Bearer ${this.authService.getJWT()}` }
        );
        return this.http
            .patch(`${this.url}/${eventId}/standings/config`, config, { headers });
    }

    /**
     * Get Custom Standings Columns
     * @param {number} eventId
     * @returns {Promise<EventStandingsConfigItem[]>}
     */
    public async getSortedStandingsColumns(eventId: number): Promise<EventStandingsConfigItem[]> {
        let config: EventStandingsConfig;
        let sportStandingsConfig: SportStandingsConfig;
        let standingLabels;
        try {
            const resp: any = await this.getEventStandingsConfig(eventId).toPromise();
            config = resp.standing_config;
            const event: any = await this.getEvent(eventId).toPromise();
            sportStandingsConfig = await this.getSportStandingsConfig(event.sport.id).toPromise();
            standingLabels = PointLabels.getStandingLabels(+event.sport.id);
        } catch (e) {
            config = {};
        }
        return [
            {
                id: 2,
                name: 'Wins',
                short_name: 'W',
                alias: 'w',
                long_alias: 'wins',
                order: config.wins_order || 0,
            },
            {
                id: 3,
                name: 'Losses',
                short_name: 'L',
                alias: 'l',
                long_alias: 'losses',
                order: config.losses_order || 0,
            },
            {
                id: 4,
                name: 'Ties',
                short_name: 'T',
                alias: 't',
                long_alias: 'ties',
                order: config.ties_order || 0,
            },
            {
                id: 5,
                name: standingLabels.differential.name,
                short_name: standingLabels.differential.short_name,
                alias: standingLabels.differential.alias,
                long_alias: 'goal_differential',
                order: config.goal_differential_order || 0,
            },
            {
                id: 6,
                name: standingLabels.pointsFor.name,
                short_name: standingLabels.pointsFor.short_name,
                alias: standingLabels.pointsFor.alias,
                long_alias: 'goals_for',
                order: config.goals_for_order || 0,
            },
            {
                id: 7,
                name: standingLabels.pointsAgainst.name,
                short_name: standingLabels.pointsAgainst.short_name,
                alias: standingLabels.pointsAgainst.alias,
                long_alias: 'goals_against',
                order: config.goals_against_order || 0,
            },
            {
                id: 8,
                name: 'Points',
                short_name: 'PTS',
                alias: 'pts',
                long_alias: 'total_points',
                order: config.total_points_order || 0,
            },
        ]
            // Filter the allowed standings by the sport
            .filter(item => {
                const name = item.long_alias + '_allowed';
                return (name in sportStandingsConfig) && sportStandingsConfig[name];
            })
            .sort((a, b) => a.order - b.order);
    }

    /**
     * Set Custom Standings Columns
     * @param {number} eventId
     * @param {EventStandingsConfigItem[]} columns
     * @returns {Observable<Object>}
     */
    public saveStandingColumns(eventId: number, columns: EventStandingsConfigItem[]) {
        const config: EventStandingsConfig = {};
        columns.forEach(c => {
            config[c.long_alias + '_order'] = c.order;
        });
        return this.setEventStandingsConfig(eventId, config);
    }
}
