import { Component, OnInit } from '@angular/core';
import { AdminSettingsService } from '../../../services/admin/settings/settings.service';
import { Settings } from '../../../interfaces/index';

@Component({
    selector: 'bss-admin-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

    settings: Settings;
    acceptableConfiguration = false;
    originalSettings: Settings;
    isLoading = false;

    constructor(private settingsService: AdminSettingsService) {
    }

    ngOnInit() {
        this.prepare();
    }

    async prepare() {
        this.isLoading = true;

        this.settings = await this.settingsService
            .getSettings()
            .toPromise();

        this.isLoading = false;
        this.originalSettings = JSON.parse(JSON.stringify(this.settings));
        this.validate();
    }

    validate() {
        if (this.settings.social_media.length <= 0) {
            return this.acceptableConfiguration = false;
        }

        if (this.settings.notes.length <= 0) {
            return this.acceptableConfiguration = false;
        }
        this.acceptableConfiguration = true;
    }

    reset() {
        this.clear();
        this.settings = JSON.parse(JSON.stringify(this.originalSettings));
    }

    clear() {
        Object.entries(this.settings).forEach(([key, value]) => {
            this.settings[key] = '';
        });
        this.validate();
    }

    fileChanged(fileInput, id) {
        this.settings[id] = fileInput.target.files[0];
    }

    save() {
        this.isLoading = true;
        this.settingsService
            .saveSettings(this.settings)
            .subscribe(data => {
                this.isLoading = false;
                this.originalSettings = JSON.parse(JSON.stringify(this.settings));
            });
    }

}
