import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

@Component({
    selector: 'bss-event-reports',
    templateUrl: './event-reports.page.html',
    styleUrls: ['./event-reports.page.scss']
})
export class EventReportsPage implements OnInit {
    divisions: any = {};

    constructor(private route: ActivatedRoute) {
    }

    eventDisplayString;

    ngOnInit() {
        this.route.data.subscribe((data) => {
            const event = data.eventsAndDivisions.event;
            this.eventDisplayString = this.buildEventDisplayString(event);
        });
    }

    buildEventDisplayString(event) {
        const start = moment.utc(event.start_date);
        const end = moment.utc(event.end_date);
        const isSingleMonth = start.month() === end.month();
        const isSingleDay = isSingleMonth && start.date() === end.date();
        return (
            `${
                event.name
                } ${
                start.format('MMM D')
                }${
                isSingleDay ? '' : '-'
                }${(isSingleDay ?
                    start.format(', YYYY') :
                    (isSingleMonth ?
                            `${end.format('D, YYYY')}` :
                            `${end.format('MMM D, YYYY')}`
                    )
            )
                } ${
            event.location || ''
                }`);
    }
}
