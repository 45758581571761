import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class BracketTemplateDataService {
    selectedBracketUpdated = new EventEmitter();
    selectedBracket: any;

    setBracket(bracket) {
        this.selectedBracket = bracket;
        this.selectedBracketUpdated.emit(this.selectedBracket);
    }

    getBracket() {
        return this.selectedBracket;
    }
}
