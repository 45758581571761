import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'bss-login',
    templateUrl: './login.page.html',
    styleUrls: ['./login.page.scss']
})
export class LoginPage implements OnInit {
    loginFailed = false;
    user = { email: '', password: '' };
    returnUrl: string;
    isLoading = false;
    errorMsg = '';

    constructor (public auth: AuthService, private route: ActivatedRoute, private router: Router) {
    }

    ngOnInit() {
        this.route.queryParams.subscribe(async (queryParams) => {
            this.isLoading = true;

            if (queryParams.code) {
                try {
                    const auth0Response = await this.auth.AuthToken(queryParams.code).toPromise();                    
                    if(auth0Response.id_token) {
                        const response = await this.auth.validateToken(auth0Response.access_token).toPromise();
                    
                        if (response.auth) {
                            // SSO by design does not accepts some urls as redirect_uri
                            // Workaround: Setting a redirect url in localStorage right before redirecting to SSO and clearing it after redirect back
                            localStorage.setItem('id_token', auth0Response.id_token);
                            localStorage.setItem('access_token', auth0Response.access_token);
                            localStorage.setItem("logoutFlag", "false");
                            const returnUrl = localStorage.getItem('after-login-redirect-url');
                            localStorage.setItem('after-login-redirect-url', '');
                            if (returnUrl) {
                                return this.router.navigate([returnUrl]);
                            } else if (this.auth.isScorer()) {
                                return this.router.navigate(['/schedules']);
                            }
                            return this.router.navigate(['/templates']);
                        }
                    }    
                } catch (err) {
                    if (err.error && err.error.error) {
                        this.errorMsg = err.error.error;
                    } else {
                        this.errorMsg = 'An unknown error occured.';
                    }
                    console.error(err);
                }
            }
            this.isLoading = false;
        });
    }

    onSubmit() {
        this.auth.signIn(this.user).subscribe((data) => {
            this.loginFailed = false;
            if (this.returnUrl) {
                return this.router.navigate([this.returnUrl]);
            } else if (this.auth.isScorer()) {
                return this.router.navigate(['/schedules']);
            }
            return this.router.navigate(['/templates']);
        }, (error) => {
            this.loginFailed = true;
        });
    }

}
