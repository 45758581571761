import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
    name: 'gameSort',
    pure: false
})

export class GameSortPipe implements PipeTransform {
    transform(array: Array<any>): Array<any> {
        // for reasons I don't know, ordering `game.game_type` as `desc` or as `asc` was always ordering bracket games first. the game.game_type ternary code below fixes that
        // ZZZ to put undefined pool names last in the ordering in cases where game.pool is undefined
        return _.chain(array)
            .orderBy((game => [(game.division || {}).name, game.game_type === 'pool' ? 1 : 2, (game.pool || {}).name || 'ZZZ', game.game_number]), ['asc', 'asc', 'asc', 'asc'])
            .value();
    }
}
