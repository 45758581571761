import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { EventService } from '../../services';
import { Event } from '../../interfaces';

@Component({
    selector: 'bss-event-list',
    templateUrl: './event-list.page.html',
    styleUrls: ['./event-list.page.scss']
})
export class EventListPage implements OnInit {
    events: Event[];
    isLoading = true;
    ascOrDesc = false;
    searchCtrlGroup: FormGroup;
    searchTerms = '';
    minDate: Date = null;
    maxDate: Date = null;

    constructor(private eventService: EventService) {
        const date = new Date();
        this.minDate = new Date(date.setHours(0, 0, 0, 0));
        this.maxDate = new Date(date.setMonth(date.getMonth() + 1));
    }

    ngOnInit() {
        this.searchCtrlGroup = new FormGroup({
            search: new FormControl()
        });
        this.getEvents();
        this.searchCtrlGroup
            .controls['search']
            .valueChanges
            .debounceTime(500)
            .subscribe(value => {
                this.searchTerms = value;
                this.getEvents();
            });
    }

    changeDateFilters() {
        this.getEvents();
    }

    /**
     * Tooggle sorting of the events
     */
    sort() {
        this.ascOrDesc = !this.ascOrDesc;
        this.getEvents();
    }

    /**
     * Retrieve a list of events
     */
    getEvents() {
        this.isLoading = true;
        this.eventService
            .getList(
                this.ascOrDesc,
                this.searchTerms,
                this.minDate,
                this.maxDate,
                false
            )
            .subscribe(
                (data: Event[]) => {
                    this.events = data;
                    this.isLoading = false;
                },
                err => {
                    console.error(err);
                    this.isLoading = false;
                }
            );
    }

    /**
     * Publish an event
     * @param {boolean} active
     * @param {Event} event
     */
    publish(active: boolean, event: Event) {
        this.isLoading = true;
        this.eventService
            .publish(active, event.id)
            .subscribe(
                () => {
                    this.isLoading = false;
                },
                err => {
                    setTimeout(() => {
                        console.error(err);
                        // If event.active could not be persisted revert event.active state
                        event.active = !active;
                        this.isLoading = false;
                    }, 2000);
                }
            );
    }
}
