import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'bss-sub-section-nav',
    templateUrl: './sub-section-nav.component.html',
    styleUrls: ['./sub-section-nav.component.scss']
})
export class SubSectionNavComponent implements OnInit {

    @Input() linkArr: string[][3];

    constructor() {
    }

    ngOnInit() {
    }

}
