import * as jQuery from 'jquery';
import * as uuid from 'uuid/v4';
import * as randomColor from 'randomcolor/randomColor';
import * as _ from 'lodash';
import 'jquery-ui-dist/jquery-ui.js';
import {Component, OnInit, ViewChildren, QueryList, ElementRef, AfterViewInit, ChangeDetectorRef, ViewChild} from '@angular/core';
import {forkJoin, from} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {getOrdinal} from '../../helpers';

import {
    BracketTemplateService,
    ConfigService,
    PoolService,
    GameService,
    DivisionService,
    EventService,
    TemplateService,
    TemplatePoolService,
} from '../../services';

import {
    Division,
    TemplatePool,
    Event,
    GameBlock,
} from '../../interfaces';

import {BracketGameBlockComponent } from '../../components/bracket-game-block/bracket-game-block.component';
import {CreateBracketComponent } from '../../components/create-bracket/create-bracket.component';
import {InternalBreadcrumbItem } from '../../../shared/interfaces';
import {DivisionLayoutPage } from '../division-layout/division-layout.page';

import {
    PageBreak,
    MultiGameBlockSelection,
    TeamsByPoints,
    TemplateBracketsView,
    TemplateView,
} from './intefaces';

import {
    ACTION_CREATE,
    ACTION_UPDATE,
    ACTION_DELETE,
    GAMEBLOCK_RESIZE_MIN_WIDTH,
    GAMEBLOCK_RESIZE_MIN_HEIGHT,
    GAMEBLOCK_DEFAULT_WIDTH,
    GAMEBLOCK_DEFAULT_HEIGHT,
    GAMEBLOCK_RESIZE_FINAL_MIN_WIDTH,
    GAME_BLOCK_TYPES,
} from '../../constants/brackets';

@Component({
    selector: 'bss-bracket-template-details',
    templateUrl: './bracket-template-details.page.html',
    styleUrls: ['./bracket-template-details.page.scss']
})
export class BracketTemplateDetailsPage implements OnInit, AfterViewInit {
    @ViewChildren('bracketRef') bracketChildren: QueryList<ElementRef>;
    @ViewChildren('gameBlockRef') gameBlockChildren: QueryList<ElementRef>;
    @ViewChildren(BracketGameBlockComponent) bracketGameBlockChildren: QueryList<BracketGameBlockComponent>;
    @ViewChildren('pageBreakRef') pageBreakChildren: QueryList<ElementRef>;
    @ViewChild('printRef') printChild: ElementRef;

    dragAndResizeGrid = [5, 5]; // [x, y]
    multiGameBlockSelection = <MultiGameBlockSelection>{ bracketId: null, gameBlockIds: [] };
    templatePools = <TemplatePool[]>[];
    sourceSelectionLabel = 'Team...';
    changes = {
        configs: {},
        gameBlocks: {},
        brackets: {}
    };
    games = {};
    saving = false;
    ObjectRef = Object;
    isLoading = false;
    templateId: number;
    eventId: number;
    divisionId: number;
    division: Division;
    event: Event|any;
    divisionSeeds = [];
    breadcrumbItems: InternalBreadcrumbItem[];
    standings = [];
    teamsByPoints = {teams: [], count: 0};
    template: TemplateView;
    templateBracketLength: number;
    readonly GAME_BLOCK_TYPES = GAME_BLOCK_TYPES;

    constructor(
        private eventService: EventService,
        private templateService: TemplateService,
        private templateBracketService: BracketTemplateService,
        private templatePoolService: TemplatePoolService,
        private gameService: GameService,
        private poolService: PoolService,
        private configsService: ConfigService,
        private divisionService: DivisionService,
        private modalService: NgbModal,
        private route: ActivatedRoute) {}

    ngOnInit() {
        if (this.route.parent.component === DivisionLayoutPage) {
            this.route.parent.params.subscribe(async (params) => {
                this.divisionId = params.divisionId;
                this.division = this.route.parent.parent.snapshot.data.division;
                this.eventId = this.route.parent.parent.parent.snapshot.params.eventId;
                this.templateId = this.division.template_id;
                this.isLoading = true;
                try {
                    // Service requests to API
                    this.divisionSeeds = await this.divisionService.getSeeds(this.divisionId).toPromise();
                    this.event = await this.eventService.getEvent(this.eventId).toPromise();
                    this.standings = await this.divisionService.getDivisionStandings(this.divisionId).toPromise();
                } catch (err) {
                    console.error(err);
                }
                this.isLoading = false;
                this.refresh();
            });
        } else {
            this.route.parent.params.subscribe(async (params) => {
                this.templateId = params.templateId;
                this.refresh();
            });
        }
    }

    async refresh() {
        this.isLoading = true;
        try {
            const {brackets} = await this.templateBracketService.getBracketById(this.templateId).toPromise() as TemplateBracketsView;
            this.template = {
                ... await this.templateService.getById(this.templateId + '').toPromise() as TemplateView,
                brackets
            };
            this.templateBracketLength = brackets.length;
            this.templatePools = await this.templatePoolService.getPools(this.templateId).toPromise();
            this.setTeamsByPoints();
        } catch (err) {
            console.error(err);
        }
        this.isLoading = false;
        this.setBreadcrumbsItems();
    }

    ngAfterViewInit() {
        this.makeBracketsInteractable();
        this.makeGameBlocksInteractable();
        this.makePageBreaksInteractable();

        this.bracketChildren.changes.subscribe(
            () => {
                this.makeBracketsInteractable();
            }
        );
        this.gameBlockChildren.changes.subscribe(
            () => {
                this.makeGameBlocksInteractable();
            }
        );

        this.pageBreakChildren.changes.subscribe(
            () => {
                this.makePageBreaksInteractable();
            }
        );
    }

    /**
     * Method to retrieve bracket object by bracketId from local storage of brackets
     * @param {string} bracketId
     * @returns {any}
     */
    getBracketById(bracketId: string) {
        return this.template.brackets.find(b => b.uuid === bracketId);
    }

    /**
     * Method to retrieve game block object by bracketId & gameBlockId from local storage of game blocks
     * @param {string} bracketId
     * @param {string} gameBlockId
     * @returns {any}
     */
    getGameBlockById(bracketId: string, gameBlockId: string) {
        const bracket = this.template.brackets.find(b => b.uuid === bracketId);

        if (bracket !== undefined) {
            return bracket.gameBlocks.find(gb => gb.uuid === gameBlockId);
        }
    }

    /**
     * Method to calculate and return the next incremental Game Block number
     * @returns {number}
     */
    getNextGameBlockNumber() {
        let gameBlockCount = 0;

        this.template.brackets.forEach(b => {
            gameBlockCount += b.gameBlocks.length;
        });

        return gameBlockCount + 1;
    }

    /**
     * Method to clear existing multi-selections
     */
    clearGameBlockSelection() {
        this.multiGameBlockSelection = { bracketId: null, gameBlockIds: [] };
    }

    /**
     * Method to handle adding/removing a game block to the multi selection object
     * @param {string} bracketId
     * @param {string} gameBlockId
     */
    gameBlockSelection(bracketId: string, gameBlockId: string) {
        if (this.multiGameBlockSelection.bracketId !== bracketId) {
            this.multiGameBlockSelection.bracketId = bracketId;
            this.multiGameBlockSelection.gameBlockIds = [];
        }

        const gameBlockIdIndex = this.multiGameBlockSelection.gameBlockIds.indexOf(gameBlockId);
        if (gameBlockIdIndex === -1) {
            this.multiGameBlockSelection.gameBlockIds.push(gameBlockId);
        } else {
            this.multiGameBlockSelection.gameBlockIds.splice(gameBlockIdIndex, 1);
        }

    }

    /**
     * Method to add a bracket object to the local storage of brackets
     * @param bracket
     * @returns {any}
     */
    addBracket(bracket: any) {
        const bracketIndex = this.template.brackets.findIndex(b => b.uuid === bracket.uuid);
        if (bracketIndex === -1) {
            this.template.brackets.push(bracket);

            const bracketClone = _.cloneDeep(bracket);
            bracketClone.gameBlocks.forEach(gb => {
                // track game block config
                this.changes.configs[gb.configs.ui.uuid] = {
                    action: ACTION_CREATE,
                    data: _.cloneDeep(gb.configs.ui)
                };
                delete gb.configs;

                // track game block
                this.changes.gameBlocks[gb.uuid] = {
                    action: ACTION_CREATE,
                    data: _.cloneDeep(gb)
                };
            });
            delete bracketClone.gameBlocks;

            // track bracket config
            this.changes.configs[bracketClone.configs.ui.uuid] = {
                action: ACTION_CREATE,
                data: _.cloneDeep(bracketClone.configs.ui)
            };

            delete bracketClone.configs;
            // track bracket
            this.changes.brackets[bracketClone.uuid] = {
                action: ACTION_CREATE,
                data: bracketClone
            };

            return this.template.brackets[this.template.brackets.length - 1];
        }

    }

    /**
     * Method to add a game block object to the local storage of game blocks
     * @param {string} bracketId
     * @param gameBlock
     */
    addGameBlock(bracketId: string, gameBlock: any) {
        const bracketIndex = this.template.brackets.findIndex(b => b.uuid === bracketId);

        if (bracketIndex !== -1) {
            this.template.brackets[bracketIndex].gameBlocks.push(gameBlock);

            const gameBlockClone = _.cloneDeep(gameBlock);

            // track game block config
            this.changes.configs[gameBlockClone.configs.ui.uuid] = {
                action: ACTION_CREATE,
                data: gameBlockClone.configs.ui
            };
            delete gameBlockClone.configs;

            // track game block
            this.changes.gameBlocks[gameBlockClone.uuid] = {
                action: ACTION_CREATE,
                data: gameBlockClone
            };
            return this.template.brackets[bracketIndex].gameBlocks[this.template.brackets[bracketIndex].gameBlocks.length - 1];
        }
    }

    /**
     * Method to add a page break to the local storage of page breaks
     * @param {number} top
     */
    addPageBreak(top: number) {
        const pageBreakId = uuid();
        const pageBreakStyle = {
            style: {
                top: `${top}px`
            }
        };

        if (!this.template.configs || !this.template.configs.pageBreak) {
            const pageBreakConfig = {
                uuid: pageBreakId,
                entityUuid: this.template.uuid,
                type: 'PageBreak',
                data: [pageBreakStyle]
            };
            this.template.configs = {};
            this.template.configs.pageBreak = pageBreakConfig;
            this.changes.configs[pageBreakConfig.uuid] = {
                action: ACTION_CREATE,
                data: pageBreakConfig
            };
        } else {
            this.template.configs.pageBreak.data.push(pageBreakStyle);
            this.changes.configs[this.template.configs.pageBreak.uuid] = {
                action: ACTION_UPDATE,
                data: _.cloneDeep(this.template.configs.pageBreak)
            };
        }
    }

    /**
     * Method that triggers a prompt to update a specific bracket name
     * @param bracketId
     */
    updateBracketName(bracketId) {
        const bracket = this.getBracketById(bracketId);

        if (bracket) {
            const name = prompt('Enter a new Bracket name', bracket.name) || bracket.name;

            if (bracket.name !== name) {
                bracket.name = name;

                if (this.changes.brackets[bracket.uuid]) {
                    this.changes.brackets[bracket.uuid].name = name;
                } else {
                    const bracketClone = _.cloneDeep(bracket);

                    delete bracketClone.gameBlocks;
                    delete bracketClone.configs;
                    this.changes.brackets[bracket.uuid] = {
                        action: ACTION_UPDATE,
                        data: bracketClone
                    };
                }

            }
        }
    }

    /**
     * Method to remove a bracket object from the local storage of brackets
     * @param bracketId
     */
    deleteBracket(bracketId) {
        const bracketIndex = this.template.brackets.findIndex(b => b.uuid === bracketId);

        if (bracketIndex !== -1) {
            const bracket = _.cloneDeep(this.template.brackets[bracketIndex]);

            // delete game blocks
            bracket.gameBlocks.forEach(b => {
                this.deleteGameBlock(bracket.uuid, b.uuid, false);
            });

            this.template.brackets.splice(bracketIndex, 1);

            const bracketChange = this.changes.brackets[bracketId];
            if (bracketChange) {
                if (bracketChange.action === ACTION_CREATE) {
                    delete this.changes.brackets[bracketId];
                } else {
                    bracketChange.action = ACTION_DELETE;
                    bracketChange.data = {
                        bracketId: bracket.id
                    };
                }
            } else {
                this.changes.brackets[bracketId] = {
                    action: ACTION_DELETE,
                    data: {
                        bracketId: bracket.id
                    }
                };
            }
        }
    }

    /**
     * Method to remove a game block object from the local storage of game blocks
     * @param bracketId
     * @param gameBlockId
     */
    deleteGameBlock(bracketId, gameBlockId, triggerDetection = true) {
        const bracket = this.getBracketById(bracketId);

        if (bracket) {
            const gameBlockIndex = bracket.gameBlocks.findIndex(b => b.uuid === gameBlockId);

            if (gameBlockIndex !== -1) {
                const gameBlock = _.cloneDeep(this.getGameBlockById(bracketId, gameBlockId));

                bracket.gameBlocks.splice(gameBlockIndex, 1);

                const gameBlockChange = this.changes.gameBlocks[gameBlockId];
                if (gameBlockChange) {
                    if (gameBlockChange.action === ACTION_CREATE) {
                        delete this.changes.gameBlocks[gameBlockId];
                    } else {
                        gameBlockChange.action = ACTION_DELETE;
                        gameBlockChange.data = {
                            gameBlockId: gameBlock.id
                        };
                    }
                } else {
                    this.changes.gameBlocks[gameBlockId] = {
                        action: ACTION_DELETE,
                        data: {
                            gameBlockId: gameBlock.id
                        }
                    };
                }

                if (gameBlock.configs && gameBlock.configs.ui && gameBlock.configs.ui.id) {
                    const configChange = this.changes.configs[gameBlock.configs.ui.uuid];
                    if (configChange) {
                        configChange.action = ACTION_DELETE;
                        configChange.data = {
                            configId: gameBlock.configs.ui.id
                        };
                    } else {
                        this.changes.configs[gameBlock.configs.ui.uuid] = {
                            action: ACTION_DELETE,
                            data: {
                                configId: gameBlock.configs.ui.id
                            }
                        };
                    }
                }

                if (triggerDetection) {
                }
            }
        }
    }

    bracketStagingUpdate(bracketId) {
        const bracket = this.getBracketById(bracketId);

        if (bracket) {
            const bracketClone = _.cloneDeep(bracket);

            delete bracketClone.gameBlocks;
            delete bracketClone.configs;

            const bracketChange = this.changes.brackets[bracketClone.uuid];

            if (bracketChange) {
                this.changes.brackets[bracketClone.uuid].data = bracketClone;
            } else {
                this.changes.brackets[bracketClone.uuid] = {
                    action: ACTION_UPDATE,
                    data: bracketClone
                };
            }
        }
    }

    gameBlockStagingUpdate(bracketId, gameBlockId) {
        const gameBlock = this.getGameBlockById(bracketId, gameBlockId);
        if (gameBlock && gameBlock.game) {
            const game = {
                id: gameBlock.game.id,
                team_1: {
                    id: gameBlock.game.team_1
                },
                team_2: {
                    id: gameBlock.game.team_2
                },
                game_number: gameBlock.number
            };
            const gameBlockClone = _.cloneDeep(gameBlock);
            const gameClone = _.cloneDeep(game);
            delete gameBlockClone.configs;

            const gameBlockChange = this.changes.gameBlocks[gameBlockClone.uuid];

            if (gameBlockChange) {
                this.changes.gameBlocks[gameBlockClone.uuid].data = gameBlockClone;
                this.games[gameClone.id] = gameClone;
            } else {
                this.changes.gameBlocks[gameBlockClone.uuid] = {
                    action: ACTION_UPDATE,
                    data: gameBlockClone
                };
                this.games[gameClone.id] = gameClone;
            }
        } else {
            const gameBlockClone = _.cloneDeep(gameBlock);
            delete gameBlockClone.configs;

            const gameBlockChange = this.changes.gameBlocks[gameBlockClone.uuid];

            if (gameBlockChange) {
                this.changes.gameBlocks[gameBlockClone.uuid].data = gameBlockClone;
            } else {
                this.changes.gameBlocks[gameBlockClone.uuid] = {
                    action: ACTION_UPDATE,
                    data: gameBlockClone
                };
            }
        }
    }

    /**
     * Method to generate a bracket object
     * @param {string} name
     * @param {string} color
     * @param {number} width
     * @param {number} height
     * @param {number} left
     * @param {number} top
     * @returns {any}
     */
    generateBracket(name: string, color: string = null, width = 1200, height = 1000, left = 50, top = 50) {
        const bracketId = uuid();
        const configUUID = uuid();
        return <any>{
            name: name || 'ChampionShip Bracket',
            color: color || randomColor({ luminosity: 'dark' }),
            uuid: bracketId,
            templateId: this.template.id,
            configs: {
                ui: {
                    uuid: configUUID,
                    entityUuid: bracketId,
                    type: 'UI',
                    data: {
                        style: {
                            width: `${width}px`,
                            height: `${height}px`,
                            left: `${left}px`,
                            top: `${top}px`
                        }
                    }
                }
            },
            gameBlocks: []
        };
    }

    /**
     * Method to generate a game block object
     * @param {string} bracketId
     * @param {number} type
     * @param {number} left
     * @param {number} top
     * @param {number} width
     * @param {number} height
     * @param {string} participantLabel1
     * @param {string} participantLabel2
     * @param {number} number
     * @returns {any}
     */
    generateGameBlock(bracketId: string,
                      type = 'right',
                      left: number,
                      top: number,
                      width = GAMEBLOCK_DEFAULT_WIDTH,
                      height = GAMEBLOCK_DEFAULT_HEIGHT,
                      participantLabel1: string = null,
                      participantLabel2: string = null,
                      number = this.getNextGameBlockNumber()) {
        const gameBlockId = uuid();
        const configUUID = uuid();
        return {
            type,
            title: 'string',
            winnerId: null,
            loserId: null,
            winnerText: 'Champion',
            participantSource: null,
            bracketId: bracketId,
            bracket_id: bracketId,  // Last  API update expect this instead of the camelCase version
            uuid: gameBlockId,
            number,
            participantLabel1,
            participantLabel2,
            ifNecessary: false,
            configs: {
                ui: {
                    uuid: configUUID,
                    entityUuid: gameBlockId,
                    type: 'UI',
                    data: {
                        style: {
                            width: `${width}px`,
                            height: `${height}px`,
                            left: `${left}px`,
                            top: `${top}px`
                        }
                    }
                }
            }
        };
    }

    /**
     * Method to generate a bracket object with game blocks and positioning
     * @param {string} name
     * @param {string} style
     * @param {number} teamCount
     * @param {number} blockWidth
     * @param {number} blockHeight
     */
    generateBracketWithGameBlocks(name: string, style: string, teamCount: number, blockWidth = GAMEBLOCK_DEFAULT_WIDTH, blockHeight = GAMEBLOCK_DEFAULT_HEIGHT) {
        const bracket = this.generateBracket(name);

        let largestGameBlockHeightPosition = 200;
        let largestGameBlockWidthPosition = 300;

        const topOffset = 50;
        const leftOffset = 50;
        const textHeight = 23;
        const textModifier = textHeight * 2;
        // initial block size
        blockHeight += textModifier;
        const baseGameNumber = this.getNextGameBlockNumber(); // 2
        // will store the final x,y,blockWidth,blockHeight of each block
        const blocks = [];

        if (style === 'right' || style === 'left') {
            // will store the number of games in each column
            const columns = [];
            // will itterate for the number of column and push the number of games in that column. should be 6 for 64 games
            const count = Math.log2(teamCount);
            const bracketRight = count * blockWidth;
            for (let i = 0; i < count; i++) {
                teamCount /= 2;
                columns.push(teamCount);
            }

            columns.map((column, index) => {
                for (let i = 0; i < column; i++) {
                    const gameNumber = baseGameNumber + blocks.length;
                    let width = blockWidth;
                    let left = 0,
                        top = 0,
                        height = blockHeight,
                        type = 'left';

                    const isLastBlock = index === columns.length - 1 && i === column - 1;

                    if (style === 'right') {
                        left = index * blockWidth + leftOffset;
                        top = (index === 0 ? i * blockHeight : i * (Math.pow(2, index) * blockHeight) + (Math.pow(2, index - 2) * blockHeight - textHeight)) + topOffset;
                        height = (index === 0 ? blockHeight : (Math.pow(2, index - 1) * blockHeight) + (2 * textHeight));
                    } else if (style === 'left') {
                        if (isLastBlock) {
                            left = bracketRight - blockWidth - (index * blockWidth) + leftOffset;
                        } else {
                            left = bracketRight - blockWidth - (index * blockWidth) + (GAMEBLOCK_RESIZE_FINAL_MIN_WIDTH - blockWidth) + leftOffset;
                        }
                        top = (index === 0 ? i * blockHeight : i * (Math.pow(2, index) * blockHeight) + (Math.pow(2, index - 2) * blockHeight - textHeight)) + topOffset;
                        height = (index === 0 ? blockHeight : (Math.pow(2, index - 1) * blockHeight) + (2 * textHeight));
                    }

                    if (isLastBlock && style === 'right') {
                        width = GAMEBLOCK_RESIZE_FINAL_MIN_WIDTH;
                        type = 'final-right';
                    } else if (isLastBlock && style === 'left') {
                        width = GAMEBLOCK_RESIZE_FINAL_MIN_WIDTH;
                        type = 'final-left';
                    } else if (style === 'right') {
                        type = 'right';
                    } else if (style === 'left') {
                        type = 'left';
                    }

                    let participantLabel1 = null;
                    let participantLabel2 = null;

                    // calculate participant 1 & 2
                    if (index !== 0) {
                        const participant1Number = gameNumber - (columns[index - 1]) + i;
                        const participant2Number = participant1Number + 1;

                        participantLabel1 = `Winner of G${participant1Number}`;
                        participantLabel2 = `Winner of G${participant2Number}`;
                    }
                    blocks.push(this.generateGameBlock(bracket.id || bracket.uuid, type, left, top, width, height - textModifier, participantLabel1, participantLabel2, gameNumber));

                    const gameBlockWidthPosition = left + width;
                    if (gameBlockWidthPosition > largestGameBlockWidthPosition) {
                        largestGameBlockWidthPosition = gameBlockWidthPosition;
                    }

                    const gameBlockHeightPosition = (top + (height - textModifier));
                    if (gameBlockHeightPosition > largestGameBlockHeightPosition) {
                        largestGameBlockHeightPosition = gameBlockHeightPosition;
                    }
                }
            });
        } else if (style === 'butterfly') {
            const count = Math.log2(teamCount / 2);
            const bracketRight = (2 * count + 1) * blockWidth;
            const columns = [];
            teamCount /= 2;
            for (let i = 0; i < count; i++) {
                teamCount /= 2;
                columns.push(teamCount);
            }
            // left side
            columns.map((column, index) => {
                for (let i = 0; i < column; i++) {
                    const gameNumber = baseGameNumber + blocks.length;
                    const width = blockWidth;
                    const type = 'right';

                    const left = index * blockWidth + leftOffset;
                    const top = (index === 0 ? i * blockHeight : i * (Math.pow(2, index) * blockHeight) + (Math.pow(2, index - 2) * blockHeight - textHeight)) + topOffset;
                    const height = (index === 0 ? blockHeight : (Math.pow(2, index - 1) * blockHeight) + (2 * textHeight));

                    let participantLabel1 = null;
                    let participantLabel2 = null;

                    // calculate participant 1 & 2
                    if (index !== 0) {
                        const participant1Number = gameNumber - (columns[index - 1]) + i;
                        const participant2Number = participant1Number + 1;

                        participantLabel1 = `Winner of G${participant1Number}`;
                        participantLabel2 = `Winner of G${participant2Number}`;
                    }

                    blocks.push(this.generateGameBlock(bracket.id || bracket.uuid, type, left, top, width, height - textModifier, participantLabel1, participantLabel2, gameNumber));

                    const gameBlockWidthPosition = left + width;
                    if (gameBlockWidthPosition > largestGameBlockWidthPosition) {
                        largestGameBlockWidthPosition = gameBlockWidthPosition;
                    }

                    const gameBlockHeightPosition = (top + (height - textModifier));
                    if (gameBlockHeightPosition > largestGameBlockHeightPosition) {
                        largestGameBlockHeightPosition = gameBlockHeightPosition;
                    }
                }
            });
            // right side
            columns.map((column, index) => {
                for (let i = 0; i < column; i++) {
                    const gameNumber = baseGameNumber + blocks.length;
                    const width = blockWidth;
                    const type = 'left';

                    const left = (bracketRight - width - index * width) + leftOffset;
                    const top = (index === 0 ? i * blockHeight : i * (Math.pow(2, index) * blockHeight) + (Math.pow(2, index - 2) * blockHeight - textHeight)) + topOffset;
                    const height = (index === 0 ? blockHeight : (Math.pow(2, index - 1) * blockHeight) + (2 * textHeight));

                    let participantLabel1 = null;
                    let participantLabel2 = null;

                    // calculate participant 1 & 2
                    if (index !== 0) {
                        const participant1Number = gameNumber - (columns[index - 1]) + i;
                        const participant2Number = participant1Number + 1;

                        participantLabel1 = `Winner of G${participant1Number}`;
                        participantLabel2 = `Winner of G${participant2Number}`;
                    }
                    blocks.push(this.generateGameBlock(bracket.id || bracket.uuid, type, left, top, width, height - textModifier, participantLabel1, participantLabel2, gameNumber));

                    const gameBlockWidthPosition = left + width;
                    if (gameBlockWidthPosition > largestGameBlockWidthPosition) {
                        largestGameBlockWidthPosition = gameBlockWidthPosition;
                    }

                    const gameBlockHeightPosition = (top + (height - textModifier));
                    if (gameBlockHeightPosition > largestGameBlockHeightPosition) {
                        largestGameBlockHeightPosition = gameBlockHeightPosition;
                    }
                }
            });

            // add last butterfly game block
            const finalGameNumber = baseGameNumber + blocks.length;
            const finalParticipant1Number = finalGameNumber - 1;
            const finalParticipant2Number = finalParticipant1Number - (blocks.length / 2);
            const finalParticipant1Text = `Winner of G${finalParticipant1Number}`;
            const finalParticipant2Text = `Winner of G${finalParticipant2Number}`;
            const finalBlockLeft = count * blockWidth + leftOffset;
            const finalBlockTop = ((Math.pow(2, count - 2) * blockHeight) - (blockHeight / 2)) + topOffset;
            blocks.push(this.generateGameBlock(bracket.id || bracket.uuid,
                'double',
                finalBlockLeft,
                finalBlockTop,
                blockWidth,
                blockHeight - textModifier,
                finalParticipant1Text,
                finalParticipant2Text,
                finalGameNumber));
        }

        bracket.gameBlocks = blocks;

        // set bracket dimensions to fit game blocks
        bracket.configs.ui.data.style.width = `${largestGameBlockWidthPosition + 100}px`;
        bracket.configs.ui.data.style.height = `${largestGameBlockHeightPosition + 100}px`;

        bracket.configs.ui.data.style.top = `${this.getLargestBracketHeightPosition() + 50}px`;
        this.addBracket(bracket);
    }

    /**
     * Method that returns the top + height y coordinate of the furthest down bracket on the canvas
     * @returns {number}
     */
    getLargestBracketHeightPosition() {
        let largestBracketHeightPosition = 0;
        // calculate where to position bracket in relation to other brackets
        this.template.brackets.forEach(b => {
            const bracketHeightPosition = parseFloat(b.configs.ui.data.style.height) + parseFloat(b.configs.ui.data.style.top);

            if (bracketHeightPosition > largestBracketHeightPosition) {
                largestBracketHeightPosition = bracketHeightPosition;
            }
        });

        return largestBracketHeightPosition;
    }

    /**
     * Method that iterates on bracket elements and makes them draggable/resizable
     */
    makeBracketsInteractable() {
        // Enable Drag and Drop
        this.bracketChildren.toArray().forEach(elm => {
            const $elm: any = jQuery(elm.nativeElement);
            $elm
                .draggable({
                    create: () => {
                    },
                    containment: 'parent',
                    drag: () => {
                    },
                    grid: this.dragAndResizeGrid,
                    scroll: true,
                    stop: (event, ui) => {
                        const dragElm = jQuery(event.target);
                        const bracketId = dragElm.data('bracket-id');

                        this.setBracketPosition(bracketId, ui.position.left, ui.position.top);
                    },
                    start: (event, ui) => {
                    },
                    zIndex: 3
                })
                .resizable({
                    autoHide: true,
                    containment: 'parent',
                    grid: this.dragAndResizeGrid,
                    handles: 'all',
                    stop: (event, ui) => {
                        const dragElm = jQuery(event.target);
                        const bracketId = dragElm.data('bracket-id');

                        this.setBracketSize(bracketId, ui.size.width, ui.size.height);
                    },
                    start: (event) => {
                        this.setBracketResizableLimit(event.target);
                    }
                });

            this.setBracketResizableLimit(elm.nativeElement);
        });
    }

    /**
     * Method that iterates on game block elements and makes them draggable/resizable
     */
    makeGameBlocksInteractable() {
        // Enable Drag and Drop
        this.gameBlockChildren.toArray().forEach(elm => {
            const $elm: any = jQuery(elm.nativeElement);
            $elm
                .draggable({
                    containment: 'parent',
                    grid: this.dragAndResizeGrid,
                    scroll: true,
                    snap: true,
                    snapMode: 'outer',
                    snapTolerance: 5,
                    drag: (event, ui) => {
                        const draggingElm = jQuery(event.target);
                        const bracketId = draggingElm.data('bracket-id');
                        const gameBlockId = draggingElm.data('game-block-id');
                        const draggingGameBlock = this.getGameBlockById(bracketId, gameBlockId);

                        // if multiple blocks are selected, move all at once
                        if (this.multiGameBlockSelection.gameBlockIds.length && draggingGameBlock) {
                            const draggingGameBlockTop = parseFloat(draggingGameBlock.configs.ui.data.style.top);
                            const draggingGameBlockLeft = parseFloat(draggingGameBlock.configs.ui.data.style.left);

                            const topValue = Math.abs(draggingGameBlockTop - ui.position.top);
                            const leftValue = Math.abs(draggingGameBlockLeft - ui.position.left);

                            this.multiGameBlockSelection.gameBlockIds.forEach(id => {
                                const gameBlock = this.getGameBlockById(this.multiGameBlockSelection.bracketId, id);

                                if (gameBlock && gameBlockId !== id) {
                                    gameBlock.configs.ui.data.style.top =
                                        draggingGameBlockTop < ui.position.top
                                            ? `${parseFloat(gameBlock.configs.ui.data.style.top) + topValue}px`
                                            : `${parseFloat(gameBlock.configs.ui.data.style.top) - topValue}px`;

                                    gameBlock.configs.ui.data.style.left =
                                        draggingGameBlockLeft < ui.position.left
                                            ? `${parseFloat(gameBlock.configs.ui.data.style.left) + leftValue}px`
                                            : `${parseFloat(gameBlock.configs.ui.data.style.left) - leftValue}px`;

                                    const configChange = this.changes.configs[gameBlock.configs.ui.uuid];
                                    if (configChange) {
                                        configChange.data = _.cloneDeep(gameBlock.configs.ui);
                                    } else {
                                        this.changes.configs[gameBlock.configs.ui.uuid] = {
                                            action: ACTION_UPDATE,
                                            data: _.cloneDeep(gameBlock.configs.ui)
                                        };
                                    }
                                }
                            });
                        }

                        draggingGameBlock.configs.ui.data.style.top = `${ui.position.top}px`;
                        draggingGameBlock.configs.ui.data.style.left = `${ui.position.left}px`;

                        const draggingConfigChange = this.changes.configs[draggingGameBlock.configs.ui.uuid];
                        if (draggingConfigChange) {
                            draggingConfigChange.data = _.cloneDeep(draggingGameBlock.configs.ui);
                        } else {
                            this.changes.configs[draggingGameBlock.configs.ui.uuid] = {
                                action: ACTION_UPDATE,
                                data: _.cloneDeep(draggingGameBlock.configs.ui)
                            };
                        }
                    },
                    start: (event, ui) => {
                    },
                    stop: (event, ui) => {
                        const dragElm = jQuery(event.target);
                        const bracketId = dragElm.data('bracket-id');

                        if (this.multiGameBlockSelection.gameBlockIds.length) {
                            // move any gameBlocks that moved out of bracket range
                            const bracket = this.getBracketById(bracketId);
                            const bracketWidth = parseFloat(bracket.configs.ui.data.style.width);
                            const bracketHeight = parseFloat(bracket.configs.ui.data.style.height);

                            if (bracket) {
                                this.multiGameBlockSelection.gameBlockIds.forEach(id => {
                                    const gameBlock = this.getGameBlockById(this.multiGameBlockSelection.bracketId, id);
                                    const gameBlockLeft = parseFloat(gameBlock.configs.ui.data.style.left);
                                    const gameBlockTop = parseFloat(gameBlock.configs.ui.data.style.top);
                                    const gameBlockWidth = parseFloat(gameBlock.configs.ui.data.style.width);
                                    const gameBlockHeight = parseFloat(gameBlock.configs.ui.data.style.height);

                                    const gameBlockWidthPosition = gameBlockLeft + gameBlockWidth;
                                    const gameBlockHeightPosition = gameBlockTop + gameBlockHeight;

                                    if (gameBlockLeft < 0) {
                                        gameBlock.configs.ui.data.style.left = '0px';
                                    }

                                    if (gameBlockTop < 0) {
                                        gameBlock.configs.ui.data.style.top = '0px';
                                    }

                                    if (gameBlockWidthPosition > bracketWidth) {
                                        gameBlock.configs.ui.data.style.left = `${bracketWidth - gameBlockWidth}px`;
                                    }

                                    if (gameBlockHeightPosition > bracketHeight) {
                                        gameBlock.configs.ui.data.style.top = `${bracketHeight - gameBlockHeight}px`;
                                    }

                                    const configChange = this.changes.configs[gameBlock.configs.ui.uuid];
                                    if (configChange) {
                                        configChange.data = _.cloneDeep(gameBlock.configs.ui);
                                    } else {
                                        this.changes.configs[gameBlock.configs.ui.uuid] = {
                                            action: ACTION_UPDATE,
                                            data: _.cloneDeep(gameBlock.configs.ui)
                                        };
                                    }
                                });
                            }
                        }

                    },
                    zIndex: 3
                })
                .resizable({
                    autoHide: true,
                    containment: 'parent',
                    minHeight: GAMEBLOCK_RESIZE_MIN_HEIGHT,
                    minWidth: GAMEBLOCK_RESIZE_MIN_WIDTH,
                    grid: this.dragAndResizeGrid,
                    handles: 'all',
                    stop: (event, ui) => {
                        const dragElm = jQuery(event.target);
                        const bracketId = dragElm.data('bracket-id');
                        const gameBlockId = dragElm.data('game-block-id');

                        this.setGameBlockSize(bracketId, gameBlockId, ui.size.width, ui.size.height);
                    },
                    start: (event) => {
                        this.setGameBlockResizableLimit(event.target);
                    }
                });

            this.setGameBlockResizableLimit(elm.nativeElement);
        });
    }

    /**
     * Method that iterates on pageBreak elements and makes them draggable
     */
    makePageBreaksInteractable() {
        // Enable Drag and Drop
        this.pageBreakChildren.toArray().forEach(elm => {
            const $elm: any = jQuery(elm.nativeElement);
            $elm
                .draggable({
                    containment: 'parent',
                    axis: 'y',
                    grid: this.dragAndResizeGrid,
                    scroll: true,
                    stop: (event, ui) => {
                        const dragElm = jQuery(event.target);
                        const index = dragElm.data('index');

                        this.template.configs.pageBreak.data[index].style.top = `${ui.position.top}px`;

                        const configChange = this.changes.configs[this.template.configs.pageBreak.uuid];
                        if (configChange) {
                            configChange.data = _.cloneDeep(this.template.configs.pageBreak);
                        } else {
                            this.changes.configs[this.template.configs.pageBreak.uuid] = {
                                action: ACTION_UPDATE,
                                data: _.cloneDeep(this.template.configs.pageBreak)
                            };
                        }
                    },
                    zIndex: 3
                });
        });
    }

    /**
     * Method that moves a game block or a set of game blocks in a given direction on the canvas
     * @param {string} direction
     */
    moveGameBlock(direction: string) {
        const moveAmount = 5;
        const bracketId = this.multiGameBlockSelection.bracketId;
        const gameBlockIds = this.multiGameBlockSelection.gameBlockIds;
        const bracket = this.getBracketById(bracketId);

        if (bracketId && gameBlockIds.length && bracket) {
            gameBlockIds.forEach(id => {
                const gameBlock = this.getGameBlockById(bracketId, id);

                if (gameBlock) {
                    if (direction === 'up') {
                        const top = parseFloat(gameBlock.configs.ui.data.style.top) - moveAmount;
                        gameBlock.configs.ui.data.style.top = `${top > 0 ? top : 0}px`;
                    } else if (direction === 'down') {
                        const top = parseFloat(gameBlock.configs.ui.data.style.top) + moveAmount;
                        const maxHeight = parseFloat(bracket.configs.ui.data.style.height);
                        const blockHeight = parseFloat(gameBlock.configs.ui.data.style.height);
                        const isWithinLimit = (top + blockHeight) < maxHeight;
                        gameBlock.configs.ui.data.style.top = `${isWithinLimit ? top : maxHeight - blockHeight}px`;
                    } else if (direction === 'left') {
                        const left = parseFloat(gameBlock.configs.ui.data.style.left) - moveAmount;
                        gameBlock.configs.ui.data.style.left = `${left > 0 ? left : 0}px`;
                    } else if (direction === 'right') {
                        const left = parseFloat(gameBlock.configs.ui.data.style.left) + moveAmount;
                        const maxWidth = parseFloat(bracket.configs.ui.data.style.width);
                        const blockWidth = parseFloat(gameBlock.configs.ui.data.style.width);
                        const isWithinLimit = (left + blockWidth) < maxWidth;
                        gameBlock.configs.ui.data.style.left = `${isWithinLimit ? left : maxWidth - blockWidth}px`;
                    }

                    const configChange = this.changes.configs[gameBlock.configs.ui.uuid];
                    if (configChange) {
                        configChange.data = _.cloneDeep(gameBlock.configs.ui);
                    } else {
                        this.changes.configs[gameBlock.configs.ui.uuid] = {
                            action: ACTION_UPDATE,
                            data: _.cloneDeep(gameBlock.configs.ui)
                        };
                    }
                }
            });
        }
    }

    /**
     * Method that calculates largest game block width & height position on a bracket to limit the min resize limit of that bracket
     * @param {EventTarget | ElementRef} elm
     */
    setBracketResizableLimit(elm: EventTarget | ElementRef) {
        const dragElm = (<any>jQuery(elm));
        const bracketId = dragElm.data('bracket-id');

        const bracket = this.getBracketById(bracketId);

        if (bracket) {
            let largestHeight = 0;
            let largestWidth = 0;

            bracket.gameBlocks.forEach(gB => {
                const positionWidth = parseFloat(gB.configs.ui.data.style.left) + parseFloat(gB.configs.ui.data.style.width);
                const positionHeight = parseFloat(gB.configs.ui.data.style.top) + parseFloat(gB.configs.ui.data.style.height);

                if (positionHeight > largestHeight) {
                    largestHeight = positionHeight;
                }

                if (positionWidth > largestWidth) {
                    largestWidth = positionWidth;
                }
            });

            if (largestHeight > 0) {
                dragElm.resizable('option', 'minHeight', largestHeight);

                if (dragElm.height() < largestHeight) {
                    dragElm.height(largestHeight);
                }
            }

            if (largestWidth > 0) {
                dragElm.resizable('option', 'minWidth', largestWidth);

                if (dragElm.width() < largestWidth) {
                    dragElm.width(largestWidth);
                }
            }
        }
    }

    /**
     * Method that sets the game block resize limit based on resize defaults and game block type
     * @param {EventTarget | ElementRef} elm
     */
    setGameBlockResizableLimit(elm: EventTarget | ElementRef) {
        const dragElm = (<any>jQuery(elm));
        const bracketId = dragElm.data('bracket-id');
        const gameBlockId = dragElm.data('game-block-id');

        const gameBlock = this.getGameBlockById(bracketId, gameBlockId);

        if (gameBlock) {
            let minWidth;
            let minHeight;
            if (gameBlock.type.indexOf('final') !== -1) {
                minWidth = GAMEBLOCK_RESIZE_FINAL_MIN_WIDTH;
                minHeight = GAMEBLOCK_RESIZE_MIN_HEIGHT;
            } else {
                minWidth = GAMEBLOCK_RESIZE_MIN_WIDTH;
                minHeight = GAMEBLOCK_RESIZE_MIN_HEIGHT;
            }

            dragElm.resizable('option', 'minWidth', minWidth);
            dragElm.resizable('option', 'minHeight', minHeight);

            if (dragElm.width() < minWidth) {
                dragElm.width(minWidth);
                this.setGameBlockSize(bracketId, gameBlockId, minWidth, parseFloat(gameBlock.configs.ui.data.style.height));
            }

            if (dragElm.height() < minHeight) {
                dragElm.height(minHeight);
                this.setGameBlockSize(bracketId, gameBlockId, parseFloat(gameBlock.configs.ui.data.style.width), minHeight);
            }

            if (dragElm.height() < minHeight) {
                dragElm.height(minHeight);
            }
        }
    }

    /**
     * Method that updates a bracket object's left & top style config
     * @param {string} bracketId
     * @param {number} left
     * @param {number} top
     */
    setBracketPosition(bracketId: string, left: number, top: number) {
        const bracket = this.getBracketById(bracketId);

        if (bracket) {
            bracket.configs.ui.data.style.left = `${left}px`;
            bracket.configs.ui.data.style.top = `${top}px`;

            const configChange = this.changes.configs[bracket.configs.ui.uuid];
            if (configChange) {
                configChange.data = _.cloneDeep(bracket.configs.ui);
            } else {
                this.changes.configs[bracket.configs.ui.uuid] = {
                    action: ACTION_UPDATE,
                    data: _.cloneDeep(bracket.configs.ui)
                };
            }
        }
    }

    /**
     * Method that updates a bracket object's width & height style config
     * @param {string} bracketId
     * @param {number} width
     * @param {number} height
     */
    setBracketSize(bracketId: string, width: number, height: number) {
        const bracket = this.getBracketById(bracketId);

        if (bracket) {
            bracket.configs.ui.data.style.width = `${width}px`;
            bracket.configs.ui.data.style.height = `${height}px`;

            const configChange = this.changes.configs[bracket.configs.ui.uuid];
            if (configChange) {
                configChange.data = _.cloneDeep(bracket.configs.ui);
            } else {
                this.changes.configs[bracket.configs.ui.uuid] = {
                    action: ACTION_UPDATE,
                    data: _.cloneDeep(bracket.configs.ui)
                };
            }
        }
    }

    /**
     * Method that updates a game block object's width & height style config
     * @param {string} bracketId
     * @param {string} gameBlockId
     * @param {number} width
     * @param {number} height
     */
    setGameBlockSize(bracketId: string, gameBlockId: string, width: number, height: number) {
        const gameBlock = this.getGameBlockById(bracketId, gameBlockId);

        if (gameBlock) {
            gameBlock.configs.ui.data.style.width = `${width}px`;
            gameBlock.configs.ui.data.style.height = `${height}px`;

            const configChange = this.changes.configs[gameBlock.configs.ui.uuid];
            if (configChange) {
                configChange.data = _.cloneDeep(gameBlock.configs.ui);
            } else {
                this.changes.configs[gameBlock.configs.ui.uuid] = {
                    action: ACTION_UPDATE,
                    data: _.cloneDeep(gameBlock.configs.ui)
                };
            }
        }
    }

    /**
     * Method that opens the Create Bracket modal component.
     * On success, auto generates a bracket with game blocks based on modalResult settings
     */
    addBracketModal() {
        const modalRef = this.modalService.open(CreateBracketComponent);

        from(modalRef.result)
            .subscribe(
                (modalResult: any) => {
                    if (modalResult.name && modalResult.style && modalResult.team_count && modalResult.type === 'standard') {
                        this.generateBracketWithGameBlocks(modalResult.name, modalResult.style, modalResult.team_count);
                    } else if (modalResult.id && modalResult.type === 'template') {

                        // retrieve bracket data using modalResult.id
                        this.templateBracketService.getBracketById(modalResult.id)
                            .subscribe(
                                (data: any) => {
                                    // copy bracket data & config into new local template
                                    if (!data.brackets.length) {
                                        alert('The selected Template currently has no Brackets.');
                                    } else {
                                        data.brackets.forEach(bracket => {
                                            const bracketClone = _.cloneDeep(bracket);

                                            // clear identifiers for copied data
                                            const bracketId = uuid();
                                            delete bracketClone.id;
                                            bracketClone.uuid = bracketId;

                                            delete bracketClone.configs.ui.id;
                                            bracketClone.configs.ui.uuid = uuid();
                                            bracketClone.configs.ui.entityUuid = bracketId;

                                            // adjust bracket top position to avoid brackets stacking over each other
                                            bracketClone.configs.ui.data.style.top = `${this.getLargestBracketHeightPosition() + 50}px`;

                                            bracketClone.gameBlocks = bracketClone.gameBlocks.map(gb => {
                                                const gameBlockClone = _.cloneDeep(gb);

                                                const gameBlockId = uuid();
                                                delete gameBlockClone.id;
                                                gameBlockClone.uuid = gameBlockId;
                                                gameBlockClone.bracketId = bracketId;

                                                delete gameBlockClone.configs.ui.id;
                                                gameBlockClone.configs.ui.uuid = uuid();
                                                gameBlockClone.configs.ui.entityUuid = gameBlockId;

                                                return gameBlockClone;
                                            });

                                            this.addBracket(bracketClone);
                                        });
                                    }
                                }
                            );
                    }
                }
            );
    }

    /**
     * Method that handles the click event of a game block element
     * @param event
     * @param {string} bracketId
     * @param {string} gameBlockId
     */
    onGameBlockClick(event, bracketId: string, gameBlockId: string) {
        event.stopPropagation();

        if (event.shiftKey || event.ctrlKey || event.metaKey) {
            this.gameBlockSelection(bracketId, gameBlockId);
        }
    }

    /**
     * Method that handles the click event of the overall canvas
     */
    onCanvasClick(event) {
        this.clearGameBlockSelection();
    }

    /**
     * Method that handles the click event of the Add Game Block action
     * @param {EventTarget} $event
     * @param {string} bracketId
     * @param {number} gameBlockTypeValue
     */
    onAddGameBlockClick($event: EventTarget, bracketId: string, gameBlockTypeValue: string) {
        const bracket = this.getBracketById(bracketId);

        if (bracket) {
            const left = parseFloat(bracket.configs.ui.data.style.width) - (GAMEBLOCK_DEFAULT_WIDTH + 100);
            const top = 50;

            this.addGameBlock(bracket.uuid, this.generateGameBlock(
                bracket.id ? bracket.id.toString() : bracket.uuid,
                gameBlockTypeValue,
                left,
                top
            ));
        }
    }

    /**
     * Method that handles the click event of the Add Page Break action
     * @param {EventTarget} $event
     */
    onAddPageBreak($event: EventTarget) {
        const top = this.getLargestBracketHeightPosition() + 50;
        this.addPageBreak(top > 600 ? top : 600);
    }

    /**
     * Method that handles the click event of the Select All Game Blocks action
     * @param event
     * @param {string} bracketId
     */
    onSelectAllGameBlocks(event, bracketId: string) {
        event.stopPropagation();
        const bracket = this.getBracketById(bracketId);

        if (bracket) {
            this.clearGameBlockSelection();
            bracket.gameBlocks.forEach(b => {
                this.gameBlockSelection(bracketId, b.uuid);
            });
        }
    }

    /**
     * Method that handles the click event of the Delete Game Block action
     * @param $event
     * @param bracketId
     * @param gameBlockId
     */
    onDeleteGameBlock($event, bracketId, gameBlockId) {
        this.deleteGameBlock(bracketId, gameBlockId);
    }

    /**
     * Method that handles the click event of the Bracket Delete action
     * @param $event
     * @param bracketId
     */
    onBracketDelete($event, bracketId) {
        this.deleteBracket(bracketId);
    }

    /**
     * Method that handles the click event of the Edit Bracket Name action
     * @param $event
     * @param bracketId
     */
    onEditBracketName($event, bracketId) {
        this.updateBracketName(bracketId);
    }

    /**
     * Method that handles the click event of the Page Break Delete action
     * @param $event
     * @param index
     */
    onPageBreakDelete($event, index) {

        if (this.template.configs && this.template.configs.pageBreaks) {
            this.template.configs.pageBreak.data.splice(index, 1);

            const configChange = this.changes.configs[this.template.uuid];
            if (configChange) {
                configChange.data = _.cloneDeep(this.template.configs.pageBreak);
            } else {
                this.changes.configs[this.template.uuid] = {
                    action: ACTION_UPDATE,
                    data: _.cloneDeep(this.template.configs.pageBreak)
                };
            }
        }
    }

    /**
     * Method that handles the event responsible for deleting a game block or a set of game blocks
     */
    onDeleteGameBlocks() {
        const bracketId = this.multiGameBlockSelection.bracketId;
        const gameBlockIds = this.multiGameBlockSelection.gameBlockIds;
        const bracket = this.getBracketById(bracketId);
        if (bracketId && gameBlockIds.length && bracket) {
            gameBlockIds.forEach(id => {
                this.deleteGameBlock(bracketId, id);
            });
        }
    }

    onGameBlockChange(block, bracketId) {
        if (block && block.id) {
            const bracket = this.getBracketById(bracketId);

            if (bracket !== undefined) {
                const gameBlockIndex = bracket.gameBlocks.findIndex(gB => gB.uuid === block.uuid);

                if (gameBlockIndex !== -1 && !_.isEqual(bracket.gameBlocks[gameBlockIndex], block)) {
                    bracket.gameBlocks[gameBlockIndex] = block;
                    this.gameBlockStagingUpdate(bracket.uuid, block.uuid);
                }
            }
        }
    }

    /**
     * Method that handles the event responsible for printing a template
     */
    onPrintTemplate() {
        let body = {};
        if (this.event) {
            body = {
                eventName: this.event.name,
                eventLocation: this.event.location || null,
                eventStartDate: this.event.start_date,
                eventEndDate: this.event.end_date,
                divisionName: this.division.name
            };
        }
        this.templateBracketService.printBracket(this.template.id, body)
            .subscribe(
                data => {
                    const elm = this.printChild.nativeElement;

                    elm.href = 'data:application/octet-stream;base64,' + data;
                    elm.click();
                }
            );
    }

    trackByUUID(index, item) {
        return item.uuid;
    }

    /**
     * Method that submits any local bracket changes to the API
     */
    save() {
        this.saving = true;
        const observables = [this.templateBracketService.saveBracket(this.templateId, this.changes)];

        const gamesArray = [];

        if (!_.isEmpty(this.games)) {
            _.map(this.games, game => {
                gamesArray.push(game);
            });
            const requestBody = {'games': gamesArray};
            observables.push(this.gameService.putGames(requestBody));
        }

        forkJoin(observables)
        .subscribe(
            async () => {
                // clear active changes
                this.changes = {
                    configs: {},
                    gameBlocks: {},
                    brackets: {}
                };
                this.games = {};
                this.template = await this.templateService.getById(this.templateId + '').toPromise() as TemplateView;
                const {brackets} = await this.templateBracketService.getBracketById(this.templateId).toPromise() as TemplateBracketsView;
                this.template.brackets = brackets;
                this.templateBracketLength = brackets.length;
                this.setBreadcrumbsItems();
            },
            () => {
                this.saving = false;
            },
            () => {
                this.saving = false;
            }
        );
    }

    /**
     * Set the teamsByPoints property with an array of objects with team name, id and total points across all pools
     * @returns {TeamsByPoints}
     */
    setTeamsByPoints() {
        // For bracket only templates/divisions there are no teams sorted by points
        if (this.template.play_type === 'bracket') {
            return;
        }
        const teams = [];
        const poolsPlayFinished = this.standings.filter(p => p.pool_play_finished).length;
        if (this.divisionId !== undefined && poolsPlayFinished) {
            this.standings.forEach(pool => {
                pool.seeds
                    .filter(seed => !!seed.team)
                    .forEach(seed => {
                        teams.push({
                            id: seed.team.id,
                            points: seed.team.total_points,
                        });
                    });
            });
            const sortedTeams = teams
                .sort((a, b) => b.points - a.points)
                .map((team, i) => {
                    team.name = getOrdinal(i + 1) + ' in Points';
                    return team;
                });
            this.teamsByPoints = {
                teams: sortedTeams,
                count: sortedTeams.length,
            };
        }
        const size = this.division ? this.divisionSeeds.length : this.template.team_count;
        const _teams = poolsPlayFinished ? this.divisionSeeds : [];
        this.teamsByPoints = {
            teams: this.getOrdinalsArray(size, _teams),
            count: size,
        };
    }

    /**
     * Return an array of (id, ordinalName) matching the size specified
     * @param size
     * @param items
     */
    private getOrdinalsArray(size: number, items: Array<any>): any[] {
        const _items = [];
        for (let i = 1; i <= size; i++) {
            _items.push({
                name: getOrdinal(i) + ' in Points',
                id: items[i - 1] && items[i - 1].id
            });
        }
        return _items;
    }

    /**
     * Set the breadcrumbs items of the page
     */
    setBreadcrumbsItems() {
        this.breadcrumbItems = [
            { alias: this.division ? 'DIVISIONS' : 'TEMPLATES', link: '../..' },
            { alias: this.division ? this.division.name : this.template.name },
            { alias: 'BRACKETS', active: true }
        ];
    }

    /**
     * @param gameBlocks
     */
    getSourceSelectionOptions(gameBlocks: GameBlock[]) {
        return {
            sources: {
                gameBlocks,
                pools: this.templatePools,
                teams: this.divisionSeeds,
                teamsByPoints: this.teamsByPoints
            },
            disablePoolAndPoints: (this.template.play_type === 'bracket'),
            showTeamSubDdl: !!this.division
        };
    }
}
