import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnChanges,
    ChangeDetectionStrategy,
} from '@angular/core';

@Component({
    selector: 'bss-scorable-box',
    templateUrl: './scorable-box.component.html',
    styleUrls: ['./scorable-box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScorableBoxComponent implements OnChanges {
    @Input() isEditable = true;
    @Input() team1Score;
    @Input() team2Score;
    @Output() scoreGame: EventEmitter<Number[]> = new EventEmitter();
    editingMode = false;
    updatedTeam1Score: string;
    updatedTeam2Score: string;

    onSave(): void {
        const team1Score = parseInt(this.updatedTeam1Score, 10);
        const team2Score = parseInt(this.updatedTeam2Score, 10);
        this.team1Score = this.updatedTeam1Score;
        this.team2Score = this.updatedTeam2Score;
        this.scoreGame.emit([team1Score, team2Score]);
        this.editingMode = false;
    }

    ngOnChanges(): void {
        this.updatedTeam1Score = this.team1Score;
        this.updatedTeam2Score = this.team2Score;
    }

    onCancel(): void {
        this.updatedTeam1Score = this.team1Score;
        this.updatedTeam2Score = this.team2Score;
        this.editingMode = false;
    }

    /**
     * Handle keydown events on input field and delegate to specific methos
     * @param $event
     */
    onKeydown($event): void {
        if ($event.keyCode === 13) { // Enter
            this.onSave();
        } else if ($event.keyCode === 27) { // Escape
            this.onCancel();
        }
    }
}
