import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventService } from '../../services';
import { GameBlockSetting, Division } from '../../interfaces';

@Component({
    templateUrl: './scheduler-settings.page.html',
    styleUrls: ['./scheduler-settings.page.scss']
})
export class SchedulerSettingsPage implements OnInit {
    divisions: Division[] = [];
    blocks: GameBlockSetting[] = [];
    eventId: number;
    blockInEditingMode: GameBlockSetting;
    lastBlockSetting: string;
    isLoading = false;

    constructor(
        private events: EventService,
        private route: ActivatedRoute) {
    }
    ngOnInit() {
        this.eventId = parseInt(this.route.snapshot.parent.params.eventId, 10);
        this.load();
    }
    load() {
        this.getDivisions();
        this.getGridSettings();
    }
    getDivisions() {
        return this.events.getDivisions(this.eventId)
            .subscribe((data: any) => this.divisions = data.divisions);
    }
    getGridSettings() {
        return this.events.getGridSettings(this.eventId)
            .subscribe((data: any) => this.blocks = data);
    }
    createSettingsBlock(gridSettings: GameBlockSetting) {
        this.isLoading = true;
        delete gridSettings.id;
        return this.events
            .createGridSettings(this.eventId, gridSettings)
            .subscribe(
                (data: any) => {
                    this.blockInEditingMode.id = data.id;
                    this.blockInEditingMode.divisions = this.filterDivisionsByIds(gridSettings.divisions);
                    this.lastBlockSetting = JSON.stringify(this.blockInEditingMode);
                    this.blockInEditingMode = null;
                    this.load();
                    this.isLoading = false;
                },
                err => {
                    console.error(err);
                    this.isLoading = false;
                }
            );
    }

    updateSettingsBlock(gridSettings: GameBlockSetting) {
        this.isLoading = true;
        const gridSettingsId = gridSettings.id;
        delete gridSettings.id;
        return this.events
            .updateGridSettings(this.eventId, gridSettingsId, gridSettings)
            .subscribe(
                () => {
                    this.blockInEditingMode.divisions = this.filterDivisionsByIds(gridSettings.divisions);
                    this.lastBlockSetting = JSON.stringify(this.blockInEditingMode);
                    this.blockInEditingMode = null;
                    this.load();
                    this.isLoading = false;
                },
                err => {
                    console.error(err);
                    this.isLoading = false;
                }
            );
    }
    dropSettingsBlock(blockId) {
        this.isLoading = true;
        return this.events.dropGridSettings(this.eventId, blockId)
            .subscribe(
                () => {
                    this.load();
                    this.isLoading = false;
                },
                err => {
                    console.error(err);
                    this.isLoading = false;
                }
            );
    }

    addOrEdit(block: GameBlockSetting = null) {
        this.onCancel();
        this.blockInEditingMode = block || {
            id: -1,
            game_length: null,
            min_break: null,
            max_break: null,
            max_daily_games: null,
            home_guarantee: null,
            divisions: [],
        };
        if (this.blockInEditingMode.id === -1) {
            this.blocks.push(this.blockInEditingMode);
        } else {
            this.lastBlockSetting = JSON.stringify(this.blockInEditingMode);
        }
    }

    onCancel() {
        if (!this.blockInEditingMode) {
            return;
        }
        if (this.blockInEditingMode.id === -1) {
            this.blocks.splice(this.blocks.length - 1, 1);
        } else {
            this.onReset(this.blockInEditingMode);
        }
        this.blockInEditingMode = null;
    }

    onReset(blockSetting: GameBlockSetting) {
        const index = this.blocks.findIndex(b => b.id === blockSetting.id);
        this.blocks[index] = JSON.parse(this.lastBlockSetting);
        this.blockInEditingMode = null;
    }

    onUpdate(blockSetting: GameBlockSetting) {
        if (blockSetting && blockSetting.id > 0) {
            this.updateSettingsBlock(blockSetting);
        } else {
            this.createSettingsBlock(blockSetting);
        }
    }

    filterDivisionsByIds(ids): any[] {
        return this.divisions
            .filter(div => ids.indexOf(div.id) > -1)
            .map(division => {
                return {
                    id: division.id,
                    name: division.name,
                    abbreviation: division.abbreviation,
                };
            });
    }
}
