import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TemplateService } from '../../services/template/template.service';
import { CreateTemplateComponent } from '../../components/create-template/create-template.component';
import { CopyTemplateComponent } from '../../components/copy-template/copy-template.component';
import { ConfirmationDialog } from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { CopyFolderComponent } from '../../components/copy-folder/copy-folder.component';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { AuthService } from '../../services/auth/auth.service';
import { Router } from '@angular/router';

@Component({
    selector: 'bss-templates-home',
    templateUrl: './templates-home.page.html',
    styleUrls: ['./templates-home.page.scss']
})
export class TemplatesHomePage implements OnInit {
    adminFolders = [];
    adminFolderNames = [];
    operatorFolders = [];
    operatorFolderNames = [];
    expandedAdminFolders = {};
    expandedOperatorFolders = {};
    expandAll = false;
    isAdmin = false;

    constructor(
        private templateService: TemplateService,
        private modalService: NgbModal,
        private auth: AuthService,
        private router: Router) {}

    ngOnInit() {
        this.isAdmin = this.auth.isAdmin();
        this.load();
    }

    /**
     * Load templates from API
     */
    load() {
        let filter = 'filter={"include": "templates"}';
        if (!this.isAdmin) {
            filter = `${filter}&operator_id=${this.auth.getOperatorId()}`;
        }
        this.templateService
            .getAll({ prefix: '/?', filter: filter })
            .subscribe((data: any) => {
                const adminTemplates = [];
                const operatorTemplates = data.templates.filter((template: any) => {
                    if (template.created_by && template.created_by.is_admin) {
                        adminTemplates.push(template);
                        return false;
                    } else {
                        return true;
                    }
                });
                const getFolderNames = templates => (
                    Array.from(new Set(templates.map(t => t.folder_name)))
                        .sort()
                );
                const buildFolders = (names, templates) => (
                    names.map(name => ({
                        name,
                        templates: templates.filter(t => t.folder_name === name)
                    }))
                );
                const operatorFolderNames = getFolderNames(operatorTemplates);
                const adminFolderNames = getFolderNames(adminTemplates);
                const adminFolders = buildFolders(adminFolderNames, adminTemplates);
                const operatorFolders = buildFolders(operatorFolderNames, operatorTemplates);
                this.adminFolders = adminFolders;
                this.adminFolderNames = adminFolderNames;
                this.operatorFolders = operatorFolders;
                this.operatorFolderNames = operatorFolderNames;
            });
    }

    /**
     * Rename a template
     * @param template
     */
    renameTemplate(template, newName) {
        this.templateService
            .update({ id: template.id, payload: { name: newName } })
            .subscribe(() => {
                this.load();
            });
    }

    renameFolder(folder, newName) {
        if (folder.templates[0].created_by.is_admin) {
            this.expandedAdminFolders[newName] = this.expandedAdminFolders[folder.name];
        } else {
            this.expandedOperatorFolders[newName] = this.expandedOperatorFolders[folder.name];
        }
        forkJoin(
            folder.templates.map((template) => {
                return this.templateService.update(
                    { id: template.id, payload: { folder_name: newName } }
                );
            })
        ).subscribe((result) => {
            this.load();
        });
    }

    shouldBeExpanded(folder) {
        if (folder.templates[0].created_by.is_admin) {
            return !!this.expandedAdminFolders[folder.name];
        } else {
            return !!this.expandedOperatorFolders[folder.name];
        }
    }

    toggleExpandAll() {
        this.expandAll = !this.expandAll;
        this.adminFolderNames.map(folder => {
            this.expandedAdminFolders[folder] = this.expandAll;
        });
        this.operatorFolderNames.map(folder => {
            this.expandedOperatorFolders[folder] = this.expandAll;
        });
    }

    /**
     * Toggle folder
     * @param t
     * @returns {boolean}
     */
    toggle(folder, $event) {
        const admin = folder.templates[0].created_by.is_admin;
        $event.stopPropagation();
        if (admin) {
            if (this.expandedAdminFolders[folder.name]) {
                this.expandedAdminFolders[folder.name] = false;
            } else {
                this.expandedAdminFolders[folder.name] = true;
            }
        } else {
            if (this.expandedOperatorFolders[folder.name]) {
                this.expandedOperatorFolders[folder.name] = false;
            } else {
                this.expandedOperatorFolders[folder.name] = true;
            }
        }
        return false;
    }

    addTemplate() {
        const folderNames = [];
        if (this.isAdmin) {
            folderNames.push(...this.adminFolderNames);
        } else {
            folderNames.push(...this.operatorFolderNames);
        }
        const modalRef = this.modalService.open(CreateTemplateComponent, { size: 'lg' });
        modalRef.componentInstance.folders = folderNames;
        modalRef.result.then((created) => {
            if (created && !created.template.pool_size) {
                this.router.navigate([`templates/${created.response.id}/brackets`]);
            } else if (created) {
                this.router.navigate([`templates/${created.response.id}/pools`]);

            }
        });
    }

    copyTemplate(template, $event) {
        const templateCopy = { ...template };
        delete templateCopy._links;
        delete templateCopy.created_by;

        if (!templateCopy.team_count) {
            templateCopy.team_count = 4;
        }

        if (!template.pool_size) {
            delete templateCopy.pool_size;
        }
        const modalRef = this.modalService.open(CopyTemplateComponent);
        const folders = [];
        if (this.isAdmin) {
            folders.push(...this.adminFolderNames);
        } else {
            folders.push(...this.operatorFolderNames);
        }
        modalRef.componentInstance.template = templateCopy;
        modalRef.componentInstance.folders = folders;
        modalRef.result.then((copied) => {
            if (copied && !copied.template.pool_size) {
                this.router.navigate([`templates/${copied.response.id}/brackets`]);
            } else if (copied) {
                this.router.navigate([`templates/${copied.response.id}/pools`]);
            }
        });
    }

    copyFolder(folder, $event) {
        $event.stopPropagation();
        const modalRef = this.modalService.open(CopyFolderComponent);
        modalRef.componentInstance.sourceFolder = folder.name;
        modalRef.result.then((copyName) => {
            if (copyName) {
                forkJoin(
                    folder.templates.map((template) => {
                        const newTemplate = { ...template, folder_name: copyName };
                        delete newTemplate._links;
                        delete newTemplate.created_by;
                        if (!template.pool_size) {
                            delete newTemplate.pool_size;
                        }
                        return this.templateService.copy(newTemplate);
                    })
                ).subscribe((result) => {
                    this.load();
                });
            }
        });
    }

    deleteFolder(folder, $event) {
        $event.stopPropagation();
        const count = folder.templates.length;
        const modalRef = this.modalService.open(ConfirmationDialog);
        modalRef.componentInstance.headingPrefix = `delete "${folder.name}" - `;
        modalRef.componentInstance.bodyText =
            `Folder contains ${count} template${count > 1 ? 's' : ''}.`;
        modalRef.result.then((confirmed) => {
            if (confirmed) {
                forkJoin(
                    folder.templates.map((template) => {
                        return this.templateService.delete(template.id);
                    })
                ).subscribe((result) => {
                    this.load();
                });
            }
        });
    }

    deleteTemplate(template, $event) {
        const modalRef = this.modalService.open(ConfirmationDialog);
        modalRef.componentInstance.headingPrefix = `delete template "${template.name}" - `;
        modalRef.result.then((confirmed) => {
            if (confirmed) {
                this.templateService
                    .delete(template.id)
                    .subscribe((response) => {
                        this.load();
                    });
            }
        });
    }

    trackByFunction(index, item) {
        if (!item) {
            return null;
        }
        return item.id;
    }
}
