import { Component, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'bss-tiebreaker-resolver',
    templateUrl: './tiebreaker-resolver.html',
    styleUrls: ['./tiebreaker-resolver.scss']
})
export class TieBreakerResolver implements OnInit {
    tiebreakerForm: FormGroup;
    teams: any[];
    type: string;
    teamsFormArray: FormArray;

    constructor(
        private fb: FormBuilder,
        private activeModal: NgbActiveModal) {

        this.teamsFormArray = this.fb.array([]);

        this.tiebreakerForm = this.fb.group({
            teams: this.teamsFormArray
        });
    }

    ngOnInit() {}

    setType(type) {
        this.type = type;
    }

    setTeams(teams) {
        this.teams = teams;

        this.teamsFormArray.controls = [];
        this.teams.forEach((t, i) => {
            this.teamsFormArray.push(
                this.fb.group({
                    teamId: t.id,
                    order: [t.seed.pool_rank]
                })
            );
            this.teamsFormArray.markAsPristine();
        });
    }

    cancel() {
        this.activeModal.close();
    }


    uniqueOrders() {
        const counts = [];
        const isUnique = !this.teamsFormArray.controls.find((tgroup, i) => {
            const tgroup2 = <FormGroup> tgroup;
            if (counts[tgroup2.value.order] === 1) {
                return true;
            } else {
                counts[tgroup2.value.order] = 1;
            }
        });
        return isUnique;
    }

    onSubmit(data) {
        this.activeModal.close(data.teams);
    }
}
