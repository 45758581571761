
export const GAMEBLOCK_RESIZE_MIN_WIDTH = 200;
export const GAMEBLOCK_RESIZE_MIN_HEIGHT = 60;
export const GAMEBLOCK_RESIZE_FINAL_MIN_WIDTH = 400;

export const GAMEBLOCK_DEFAULT_WIDTH = GAMEBLOCK_RESIZE_MIN_WIDTH;
export const GAMEBLOCK_DEFAULT_HEIGHT = GAMEBLOCK_RESIZE_MIN_HEIGHT;

export const ACTION_CREATE = 'CREATE';
export const ACTION_UPDATE = 'UPDATE';
export const ACTION_DELETE = 'DELETE';

export const GAME_BLOCK_TYPES = [
    {
        name: 'Left',
        value: 'left'
    },
    {
        name: 'Right',
        value: 'right'
    },
    {
        name: 'Double',
        value: 'double'
    },
    {
        name: 'Double Block',
        value: 'double-block'
    },
    {
        name: 'Final Left',
        value: 'final-left'
    },
    {
        name: 'Final Right',
        value: 'final-right'
    }
];
