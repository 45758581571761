import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';

@Injectable()
export class PublicDataService {
    constructor(protected url: string, protected http: HttpClient) {
    }

    getAll(options: object = {}) {
        const url = options['filter']
            ? `${this.url}${`${options['prefix']}${options['filter']}`}`
            : this.url;

        return this.http.get(url)
        .map(response => response)
        .catch(this.handleError);
    }

    getById(id: string, options: object = null) {
        let url = id ? `${this.url}/${id}` : null;
        if (options && options['filter']) {
            url = url + `${options['prefix']}${options['filter']}`;
        }

        return this.http.get(url)
        .map(response => response)
        .catch(this.handleError);
    }

    protected handleError(error: Response) {
        return throwError(new Error(error.statusText));
    }
}
