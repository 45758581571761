import * as jQuery from 'jquery';
import { ElementRef } from '@angular/core';

/**
 * Determines if the provided x y coordinates
 * are positioned over the target ElementRef
 * @param {ElementRef} targetElement
 * @param {number} x
 * @param {number} y
 * @returns {boolean}
 */
export function areCoordsOverElement(targetElement: ElementRef, x: number, y: number) {
    const stagingElm = jQuery(targetElement.nativeElement)
        , offset = stagingElm.offset();

    const right = stagingElm.width() + offset.left;
    const bottom = stagingElm.height() + offset.top;

    // Compare
    return (x >= offset.left
        && y >= offset.top
        && x <= right
        && y <= bottom);
}
