import { Component, OnInit, Input } from '@angular/core';
import { AbstractControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of } from 'rxjs';
import { TemplatePool, TemplatePoolGroup } from '../../interfaces';

@Component({
    selector: 'bss-add-template-pool',
    templateUrl: './add-template-pool.component.html',
    styleUrls: ['./add-template-pool.component.scss']
})
export class AddTemplatePoolComponent implements OnInit {
    pool: TemplatePool = { name: '', visible: true, group: null, seeds: [], crossed: null };
    groupName = '';
    groupIndex: number = null;
    existingGroupMode = true;
    @Input() groups: any[];
    public poolForm: FormGroup;

    constructor(
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder) {
        this.poolForm = this.formBuilder.group({
            name: ['', Validators.required, this.poolNameValidator],
            groupName: [''],
            groupIndex: [null],
            visible: [true],
        });
    }

    ngOnInit() {
    }

    /**
     * Provide modal with the list of groups and a pool to be modified (optionally)
     * @param {TemplatePoolGroup[]} groups
     * @param {TemplatePool} pool
     */
    setup(groups: TemplatePoolGroup[], pool: TemplatePool = null ) {
        this.groups = groups;
        if (pool) {
            this.pool = {...pool};
            this.poolForm.setValue({
                name: pool.name,
                visible: pool.visible,
                groupIndex: this.pool.group ? this.groups.findIndex(g => g.name === pool.group.name) : null,
                groupName: ''
            });
        }
    }

    /**
     * Toogle TemplatePool#group input mode
     */
    toggleGroupMode() {
        this.existingGroupMode = !this.existingGroupMode;
    }

    /**
     * Close modal and send back modified pool.
     */
    onSubmit(data) {
        if (!this.pool.id) {
            if (this.existingGroupMode) {
                this.pool.group = (data.groupIndex > -1) ? this.groups[data.groupIndex] : null;
            } else {
                this.pool.group = data.groupName ? {name: data.groupName} : null;
            }
        }
        this.pool.name = data.name;
        this.pool.visible = data.visible;
        this.activeModal.close(this.pool);
    }

    /**
     * Close modal without saving
     */
    cancel() {
        this.activeModal.close();
    }

    poolNameValidator(control: AbstractControl): Observable<{[key: string]: any}> {
        const invalid = /\s/.test(control.value);
        return of(invalid ? {invalidName: {value: control.value}} : null);
    }
}
