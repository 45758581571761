import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MasterPage } from './pages/master/master.page';
import { EventListPage } from './pages/event-list/event-list.page';
import { DivisionListPage } from './pages/division-list/division-list.page';
import { DivisionPage } from './pages/division/division.page';
import { PoolStandingsPage } from './pages/pool-standings/pool-standings.page';
import { EventPage } from './pages/event/event.page';
import { PoolSchedulesPage } from './pages/pool-schedules/pool-schedules.page';
import { BracketsPage } from './pages/brackets/brackets.page';
import { BracketsListPage } from './pages/brackets-list/brackets-list.page';
import { BracketsTreePage } from './pages/brackets-tree/brackets-tree.page';
import { FacilitiesPage } from './pages/facilities/facilities.page';
import { GameSchedulePage } from './pages/game-schedule/game-schedule.page';
import { TeamSchedulePage } from './pages/team-schedule/team-schedule.page';

const routes: Routes = [
    {
        path: '',
        component: MasterPage,
        children: [
            {
                path: '',
                redirectTo: 'schedules',
                pathMatch: 'full',
            },
            {
                path: 'schedules',
                component: EventListPage,
            },
            {
                path: 'schedules/:eventId',
                component: EventPage,
                children: [
                    {
                        path: '',
                        redirectTo: 'divisions',
                        pathMatch: 'full',
                    },
                    {
                        path: 'divisions',
                        component: DivisionListPage,
                    },
                    {
                        path: 'divisions/:divisionId',
                        component: DivisionPage,
                        children: [
                            {
                                path: '',
                                redirectTo: 'pool-schedules',
                                pathMatch: 'full',
                            },
                            {
                                path: 'pool-standings',
                                component: PoolStandingsPage,
                            },
                            {
                                path: 'pool-schedules',
                                component: PoolSchedulesPage,
                            },
                            {
                                path: 'brackets',
                                component: BracketsPage,
                                children: [
                                    {
                                        path: '',
                                        redirectTo: 'tree',
                                        pathMatch: 'full'
                                    },
                                    {
                                        path: 'tree',
                                        component: BracketsTreePage
                                    },
                                    {
                                        path: 'list',
                                        component: BracketsListPage
                                    }
                                ]
                            },
                            {
                                path: 'facilities',
                                component: FacilitiesPage
                            },
                            {
                                path: 'game-schedule/:gameid',
                                component: GameSchedulePage,
                                pathMatch: 'full'
                            },
                            {
                                path: 'game-schedule/:gameid/team-schedule/:teamid',
                                component: TeamSchedulePage,
                            }
                        ]
                    },
                ]
            },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PublicRoutingModule {
}
