import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'bss-schedule-by-division',
    templateUrl: './schedule-by-division.page.html',
    styleUrls: ['./schedule-by-division.page.scss']
})
export class ScheduleByDivisionPage implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }
}
