import { Component } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';

@Component({
    templateUrl: './divisions.page.html',
    styleUrls: ['./divisions.page.scss']
})
export class DivisionsPage {
    isBracketsPage: (url) => any;

    constructor(public router: Router) {
        // use memoize to limit calculations
        this.isBracketsPage = _.memoize((url) => {
            const urlSplit = url.split('/');

            return (urlSplit[5] && urlSplit[5] === 'brackets');
        }, (url) => {
            return url;
        });
    }
}
