import { Component, OnInit } from '@angular/core';
import { DivisionService } from '../../services/division/division.service';
import { ActivatedRoute } from '@angular/router';
import { Division } from '../../interfaces';

interface Seed {
    id: number;
    name: string;
    order: number;
}

@Component({
    selector: 'bss-division-seeding',
    templateUrl: './division-seeding.page.html',
    styleUrls: ['./division-seeding.page.scss']
})
export class DivisionSeedingPage implements OnInit {
    division: Division;
    eventId: number;
    divisions: Division[] = [];
    persistedSeeds: Seed[] = [];
    seeds: Seed[] = [];
    staging: Seed[] = [];
    isLoading = false;

    constructor(
        private divisionService: DivisionService,
        private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.isLoading = true;
        this.staging = [];

        this.route.parent.parent.data.subscribe(data => {
            this.divisions = data.eventsAndDivisions.divisions;
            this.eventId = data.eventsAndDivisions.event.id;
            this.route.parent.params.subscribe(params => {
                this.division = this.divisions.find(d => parseInt(params.divisionId, 10) === d.id);
                this.refresh();
            });
        });
    }

    /**
     * Load item seeds array for the current division
     * @returns {Promise<void>}
     */
    async refresh() {
        this.isLoading = true;
        try {
            this.persistedSeeds = await this.divisionService.getSeeds(this.division.id).toPromise();
            this.persistedSeeds.sort((s1, s2) => s1.order - s2.order);
            this.reset();
        } catch (err) {
            console.error(err);
        }
        this.isLoading = false;
    }

    /**
     * Move a seed from position or to staging
     * @param {Seed} seed
     * @param {any} position
     * @param {boolean} toStaging
     */
    move(seed: Seed, position: null, toStaging = false) {
        if (toStaging) {
            if (this.staging.find(s => s.id === seed.id)) {
                return;
            }
            const seedIndex = this.seeds.findIndex(s => s.id === seed.id);
            if (seedIndex > -1) {
                this.staging.push(seed);
                this.seeds.splice(seedIndex, 1);
            }
        } else {
            // Move it if it already exists
            const index = this.seeds.findIndex(s => s.id === seed.id);
            if (index > -1) {
                if (index !== position) {
                    this.seeds.splice(index, 1);
                    this.seeds.splice(position, 0, seed);
                }
            } else {
                const seedIndex = this.staging.findIndex(s => s.id === seed.id);
                if (seedIndex > -1) {
                    this.seeds.push(seed);
                    this.staging.splice(seedIndex, 1);
                }
            }
            this.updateOrderProperties();
        }
    }

    /**
     * Update property order of seeds. Set seed.order = index + 1
     */
    updateOrderProperties() {
        this.seeds.map((seed: Seed, i: number) => {
            seed.order = i + 1;
        });
    }

    /**
     * Retrieve remaining item placeholders
     * @returns {any[]}
     */
    getPlaceholders() {
        return Array(this.persistedSeeds.length - this.seeds.length);
    }

    /**
     * Reset seeds array state to the latest persisted state
     */
    reset() {
        this.seeds = JSON.parse(JSON.stringify(this.persistedSeeds));
        this.staging = [];
    }

    /**
     * Send all team seed to staging
     */
    clear() {
        this.staging = JSON.parse(JSON.stringify(this.persistedSeeds));
        this.seeds = [];
    }

    /**
     * Prepare the payload to send save request
     * @param {Seed[]} seeds
     * @returns {Array}
     */
    cleanPayload(seeds: Seed[]) {
        const payload = [];
        seeds.forEach(seed => {
            payload.push({
                id: seed.id,
                order: seed.order
            });
        });
        return payload;
    }

    /**
     * Send save request to team seeds of the current division
     */
    save() {
        this.isLoading = true;
        this.divisionService
        .setSeeds(this.division.id, this.cleanPayload(this.seeds))
        .subscribe(
            () => {},
            err => console.error(err),
            () => this.isLoading = false
        );
    }
}
