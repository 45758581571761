import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { FacilityService } from '../../services/facility/facility.service';
import { Division, Event, Facility } from '../../interfaces';
import { InternalBreadcrumbItem } from '../../../shared/interfaces';

interface TimeItem {
    hours: number;
    minutes: string;
    meridiem: string;
}

interface DateTimeItem {
    date: Date;
    time: TimeItem;
}

@Component({
    selector: 'bss-division-restrictions',
    templateUrl: './division-restrictions.page.html',
    styleUrls: ['./division-restrictions.page.scss']
})
export class DivisionRestrictionsPage implements OnInit {
    breadcrumbItems: InternalBreadcrumbItem[];
    currentDivision: Division;
    divisions: Division[];
    timeItems: TimeItem[];
    divisionTeams: any;
    eventFacilities: Facility[];
    event: Event;
    date: any;
    totalTeams = 0;
    timeframes = [];

    constructor(
      private route: ActivatedRoute,
      private facilityService: FacilityService) {
    }

    ngOnInit() {
        this.route.parent.parent.data.subscribe((data) => {
            this.divisions = data.eventsAndDivisions.divisions;
            this.event = data.eventsAndDivisions.event;

            if (this.divisions.length === 0) {
                this.totalTeams = 0;
            } else {
                this.totalTeams = this.divisions
                .map(d => d.teams.length || 0)
                .reduce((p, c) => p + c);
            }
        });
        this.route.parent.data.subscribe((data) => {
            this.currentDivision = data.division;
        });
        this.facilityService.getFacilities(this.event.id).subscribe((data) => {
            this.eventFacilities = data.facilities;
        });
        this.breadcrumbItems = [
            { alias: 'TEAMS & DIVISIONS', link: '../..' },
            { alias: 'RESTRICTIONS' },
            { alias: this.currentDivision.name, active: true }
        ];
        this.divisionTeams = this.divisions.find(division => division.id === this.currentDivision.id).teams;
        this.timeItems = new Array(48).fill(null, 0, 48).map((val, index) => {
            const hours = index % 2 === 0 ? index / 2 : Math.abs(Math.round(index / 2 - 1));
            return {
                hours: (hours >= 12 ? hours - 12 : hours) || 12,
                minutes: index % 2 === 0 ? '00' : '30',
                meridiem: index < 24 ? 'AM' : 'PM'
            };
        });
        this.timeframes = [{ startDate: moment.utc(), endDate: moment.utc() }];
    }
}
