import {PublicDataService} from '../common/public-data.service';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AuthService} from '../../../scheduler/services';
import {environment as env} from '../../../../environments/environment';

@Injectable()
export class PrintService extends PublicDataService {

    constructor(http: HttpClient, private authService: AuthService) {
        super(`${env.API_BASE_URL}/print`, http);
    }

    create(resource) {

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            responseType: 'text' as 'text'
        };
        return this.http.post(`${env.API_BASE_URL}/print`, resource, httpOptions)
            .map(response => response)
            .catch(this.handleError);
    }

    createBrackets(id, resource) {
        resource.tempId = id;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            responseType: 'text' as 'text'
        };
        return this.http.post(`${env.API_BASE_URL}/print/brackets`, resource, httpOptions)
            .map(response => response)
            .catch(this.handleError);
    }

}
