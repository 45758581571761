import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../scheduler/services';
import { NavbarService } from '../../services/navbar/navbar.service';
import { environment as env } from '../../../../environments/environment';
import { RecentEvent } from '../../classes/RecentEvent';
import { Observable } from 'rxjs';
import { EventHistoryService } from '../../services/event-history/event-history.service';

@Component({
    selector: 'bss-public-master',
    templateUrl: './master.page.html',
    styleUrls: ['./master.page.scss']
})
export class MasterPage {
    recent_events: Observable<Array< RecentEvent>>;

    constructor(
        public router: Router,
        public navbarService: NavbarService,
        private authService: AuthService,
        private eventHistoryService: EventHistoryService
        ) {
        this.recent_events = this.eventHistoryService.eventHistory;
    }

    /**
     * Redirect to SSO page with redirect_uri param to redirect back after successful login attempt
     */
    login() {
        // localStorage.setItem('after-login-redirect-url', this.router.url);
        // window.location.href = `${this.authService.getSsoUrl()}`;

        // window.location.href = this.router.url;
    }

    changeEvent($event) {
        if (Number($event.target.value) === -1) {
            this.router.navigate(['/schedules']);
        } else {
            this.router.navigate([`/schedules/${$event.target.value}/divisions`]);
        }
    }

    /**
     * Returns true if a user is authenticated
     * @returns {boolean}
     */
    get isAuthenticated () {
        return this.authService.isAuthenticated();
    }

    /**
     * Terminate user session and refresh the page
     */
    logout() {
        this.authService.signOut();
        window.location.reload();
    }

}


