import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute  } from '@angular/router';
import { environment as env } from '../../../../environments/environment';
import { Observable } from 'rxjs/Observable';
import jwt_decode from 'jwt-decode';

@Injectable()
export class AuthService {
    private JWT: string;
    private adminJWT: string;

    constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute) {
    }

    /**
     * Returns true if a user is authenticated
     * @returns {boolean}
     */
    isAuthenticated(): boolean {
        const token = this.getJWT();
        // const exp = token && JSON.parse(atob(token.split('.')[1])).exp;
        if(token){
            const temp:any = jwt_decode(token);
            const exp = (token && temp.exp);
            return exp && (exp - Date.now() / 1000 > 0);
        }       
    }

    /**
     * Retrive the JWT token of an authenticated user, null otherwise
     * @returns {string | null}
     */
    getJWT() {
        return localStorage.getItem('JWT');
    }

    /**
     * Decode a JWT string and return an object
     * @param {string} token
     */
    getJWTData(token: string): any {
        return token && jwt_decode(token);
    }

    /**
     * Returns true if the authenticated user has the admin role
     * @returns {boolean}
     */
    isAdmin(): boolean {
        const jwtPayload = this.getJWTData(this.getJWT());
        return jwtPayload && jwtPayload.roles && jwtPayload.roles.indexOf('Admin') !== -1;
    }

    /**
     * Returns true if the authenticated user has the operator role
     * @returns {boolean}
     */
    isOperator(): boolean {
        const jwtPayload = this.getJWTData(this.getJWT());
        return jwtPayload && jwtPayload.roles && jwtPayload.roles.indexOf('Operator') !== -1
            || this.isAdmin();
    }

    /**
     * Returns true if the authenticated user has the scorer role
     * @returns {boolean}
     */
    isScorer(): boolean {
        const jwtPayload = this.getJWTData(this.getJWT());
        return (jwtPayload && jwtPayload.roles) && jwtPayload.roles.indexOf('Scorer') !== -1
            || this.isAdmin();
    }

    /**
     * Returns the operator id if present
     * @returns {number}
     */
    getOperatorId(): number {
        const jwtPayload = this.getJWTData(this.getJWT());
        return jwtPayload && (jwtPayload.operator_id);
    }


    /**
     * Initiate a user session by requesting a JWT from API server
     * @param credentials
     * @returns {Observable<any>}
     */
    signIn(credentials): Observable<any> {
        return this.http.post(env.API_BASE_URL + '/users/login', credentials)
            .map((data: any) => {
                if (data.auth) {
                    localStorage.setItem('JWT', data.token);
                }
                return data;
            });
    }


    validateToken(token) {
        return this.http.post(env.API_BASE_URL + '/users/login', { id_token: token })
            .map((data: any) => {
            if (data.auth) {
                localStorage.setItem('JWT', data.token);
            }
            return data;
          });

        // return this.http.post(env.API_BASE_URL + '/users/login', { id_token: token })
        //     .map((data: any) => {
        //         console.log("data",data);
        //         if (data.auth) {
        //             console.log("ifffdata");
        //             localStorage.setItem('JWT', data.token);
        //         }
        //         return data;
        //     });
    }

    /**
     * Terminate user session by removing JWT from localStorage
     */
    signOut() {
        localStorage.removeItem('JWT');
        localStorage.clear();
        localStorage.setItem('logoutFlag', "true");
    }

    /**
     * 
     * Return the AUTH0 code
     */

    AuthToken(authorizeCode) {
        return this.http.post(env.SSO_API_URI + '/oauth/token', {grant_type:'authorization_code', client_id:env.SSO_CLIENT_ID, client_secret:env.SSO_CLIENT_SECRET, audience:env.SSO_API_AUDIENCE, scope:'offline_access', code:authorizeCode, "redirect_uri":env.SSO_REDIRECT_URI })
            .map((data: any) => {                
                return data;
            });
    }

    /**
     * Return the SSO Url
     */
    getSsoUrl() {
        if (localStorage.getItem('logoutFlag') == 'true'){
            return `${env.SSO_API_URI}/authorize?response_type=code&connection=${env.SSO_DATABASE_CONNECTION}&client_id=${env.SSO_CLIENT_ID}&redirect_uri=${env.SSO_REDIRECT_URI}&prompt=login&scope=profile openid email offline_access`;
        }else{
            return `${env.SSO_API_URI}/authorize?response_type=code&connection=${env.SSO_DATABASE_CONNECTION}&client_id=${env.SSO_CLIENT_ID}&redirect_uri=${env.SSO_REDIRECT_URI}&scope=profile openid email offline_access`;
        }     

        //return `${env.SSO_API_URI}?client_id=${env.SSO_CLIENT_ID}&redirect_uri=${env.SSO_REDIRECT_URI}`;
    }
}
