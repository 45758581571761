import { DataService } from '../common/data.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as env } from '../../../../environments/environment';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class BracketTemplateService extends DataService {
    constructor(http: HttpClient, private authService: AuthService) {
        super(`${env.API_BASE_URL}/templates`, http, authService);
    }

    getBracketById(templateId: number) {
        const headers = new HttpHeaders(
            { 'Authorization': `Bearer ${this.authService.getJWT()}` }
        );
        return this.http
        .get(`${this.url}/${templateId}/brackets`, { headers });
    }

    printBracket(templateId: number, headerBody: any) {
        const headers = new HttpHeaders(
            {
                'Authorization': `Bearer ${this.authService.getJWT()}`,
                'accept': 'application/octet-stream'
            }
        );
        return this.http
        .post(`${this.url}/${templateId}/print`, headerBody, { headers });
    }

    saveBracket(templateId: number, changes) {
        const headers = new HttpHeaders(
            { 'Authorization': `Bearer ${this.authService.getJWT()}` }
        );
        return this.http
        .patch(`${this.url}/${templateId}/brackets`, changes, { headers });
    }
}
