import { Injectable } from '@angular/core';
import { TieBreakerOption, TieBreaker } from '../../interfaces';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { environment as env } from '../../../../environments/environment';

@Injectable()
export class TieBreakerService {

    // accetableConfiguration = true;

    constructor(private http: HttpClient, private auth: AuthService) { }

    get(sportId: number): Observable<TieBreaker[]> {
        return this.http
        .get(env.API_BASE_URL + `/sports/${sportId}/tiebreakers`)
        .map((resp: any) => resp.tiebreakers);
    }

    getOptionsForEvent(eventId: number): Observable<TieBreakerOption[]> {
        return this.http
        .get(env.API_BASE_URL + `/events/${eventId}/tiebreaker`)
        .map((resp: any) => resp.tiebreakers );
    }

    save(eventId: number, options: any[]) {
        const headers = new HttpHeaders(
            {'Authorization': `Bearer ${this.auth.getJWT()}`}
        );
        return this.http
        .patch(env.API_BASE_URL + `/events/${eventId}/tiebreaker`, options, {headers});

    }

    //
    //
    // /**
    //  * Return the tiebreaker rules for an sport
    //  * @param {number} sportId
    //  * @returns {Observable<TieBreakerRule[]>}
    //  */
    // getRules(sportId: number): Observable<TieBreakerRule[]> {
    //     const params = new HttpParams();
    //     params.append('sport_id', sportId.toString());
    //
    //     return this.http
    //         .get(env.API_BASE_URL + '/tiebreakers', {params})
    //         .map((data: any) => {
    //             const rules: any = data.tiebreakers.filter(t => t.sport_id === sportId);
    //             rules.map((item: any) => {
    //                 if (typeof item.values === 'string' || item.values instanceof String) {
    //                     item.values = JSON.parse(item.values);
    //                 }
    //                 if (item.values instanceof Object) {
    //                     item.values = this.formatRuleValues(item.values);
    //                 }
    //                 item.value = (item.value ? parseInt(item.value, 10) : 0);
    //             });
    //             return rules;
    //         });
    // }
    //
    // /**
    //  * Get TieBreakers of an event
    //  * @param {number} eventId
    //  * @returns {Observable<TieBreakerOption[]>}
    //  */
    // getTieBreakers(eventId: number): Observable<TieBreakerOption[]> {
    //     return this.http.get(env.API_BASE_URL + `/events/${eventId}/tiebreaker`);
    // }
    //
    // filterByTeams(data, teams) {
    //     return data
    //         .filter(d => d.teams === teams)
    //         .map(d => {
    //             return {
    //                 id: d.id,
    //                 order: d.order,
    //                 value: (d.value ? parseInt(d.value, 10) : 0)
    //             };
    //         });
    // }
    //
    // private formatRuleValues(values: any) {
    //     if (Array.isArray(values.range)
    //         && values.range.length === 2
    //         && values.range[0] < values.range[1]
    //         && values.range[1] < 1000) {
    //         const _values = [];
    //         for (let i = values.range[0]; i <= values.range[1]; i++) {
    //             _values.push(i);
    //         }
    //         values = _values;
    //     } else if (Array.isArray(values.options)) {
    //         values = values.options;
    //     } else {
    //         values = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
    //     }
    //     return values;
    // }
    //
    // /**
    //  * Save tiebreaker of an event
    //  * @param eventId
    //  * @param data
    //  * @returns {Observable<Response>}
    //  */
    // saveTieBreakers(eventId, data) {
    //     const body = [];
    //     data.forEach(tb => {
    //         tb.points.forEach(p => {
    //             const tieBreaker: any = {
    //                 id: p.id,
    //                 order: p.order,
    //                 teams: tb.teams
    //             };
    //             if (p.value) {
    //                 tieBreaker.value = p.value;
    //             }
    //             body.push(tieBreaker);
    //         });
    //     });
    //
    //     const headers = new HttpHeaders(
    //         {'Authorization': `Bearer ${this.auth.getJWT()}`}
    //     );
    //     return this.http
    //         .put(env.API_BASE_URL + `/events/${eventId}/tiebreaker`, body, {headers});
    // }
}
