import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { EventService } from '../../../scheduler/services';
import { ExternalDetailsService } from '../../services/external-details/external-details.service';
import { Division } from '../../../scheduler/interfaces/index';

@Component({
    selector: 'bss-public-division-list',
    templateUrl: './division-list.page.html',
    styleUrls: ['./division-list.page.scss']
})
export class DivisionListPage implements OnInit {
    eventId: number;
    isDivisionsReady = false;
    divisions: Division[];
    entity_type = 'Event';
    registrationLink = 'https://basketball.tournamentconnect.stacksports.com/events/register/';

    constructor(
        private route: ActivatedRoute,
        private eventService: EventService,
        private externalDetailsService: ExternalDetailsService) { }

    async ngOnInit() {
        const routeParams = this.route.parent.snapshot.params;
        this.isDivisionsReady = true;
        this.eventId = routeParams.eventId;

        const [divisions, linkFromEvent, externalId] = await forkJoin(
            this.eventService.getPublicDivisions(this.eventId)
                .pipe(
                    map(data => data.divisions),
                    catchError(err => of([]))),
            this.eventService.getEvent(this.eventId)
                .pipe(
                    map(event => event.reg_link || ''),
                    catchError(err => of(''))),
            this.externalDetailsService.getExternalDetails(this.entity_type, this.eventId)
                .pipe(
                    map(details => details.external_id || ''),
                    catchError(err => of('')))
        ).toPromise();

        const linkFromExternalId = this.registrationLink + externalId;
        this.registrationLink = linkFromEvent || linkFromExternalId;
        this.divisions = divisions;

        this.isDivisionsReady = false;
    }
}
