import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AddDivisionModalComponent } from '../../components/add-division/add-division.component';
import { AddTeamModalComponent } from '../../components/add-team/add-team.component';
import { TemplateService } from '../../services/template/template.service';
import { CreateTemplateComponent } from '../../components/create-template/create-template.component';
import { DivisionCombineWithComponent } from '../../components/division-combine-with/division-combine-with.component';
import { DivisionService } from '../../services/division/division.service';
import { EventService } from '../../services/event/event.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import {Event, Division, Template} from '../../interfaces';

@Component({
    selector: 'bss-divisions',
    templateUrl: './divisions-main.page.html',
    styleUrls: ['./divisions-main.page.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DivisionsMainPage implements OnInit {
    divisions: Division[];
    event: Event;
    eventId: number;
    allCollapsed = true;
    totalTeams = 0;
    isLoading = false;
    active = false;
    templateFolders = [];
    draggingDivision: Division = null;
    preDraggingDivisionPosition: number;
    isAdmin = false;

    constructor(
        private cdr: ChangeDetectorRef,
        private modalService: NgbModal,
        private eventService: EventService,
        private divisionService: DivisionService,
        private templateService: TemplateService,
        private auth: AuthService,
        private route: ActivatedRoute) {}

    ngOnInit() {
        this.isAdmin = this.auth.isAdmin();
        this.route.parent.params
        .subscribe(params => {
            this.eventId = params.eventId;
            this.refresh();
        });
    }

    /**
     * Refresh page. Load Divisions and Templates from API
     * @param {number} expandedDivisionId
     * @returns {Promise<void>}
     */
    async refresh(expandedDivisionId: number = null) {
        this.isLoading = true;
        try {
            // Event, Divisions, Teams
            const eventData: any = await this.eventService.getDivisions(this.eventId).toPromise();
            this.divisions = eventData.divisions.sort((a, b) => a.order > b.order ? 1 : -1);
            this.event = eventData.event;
            this.expandCollapseAll(true, expandedDivisionId);
            if (this.divisions.length === 0) {
                this.totalTeams = 0;
            } else {
                this.totalTeams = this.divisions
                .map(d => d.teams.length || 0)
                .reduce((p, c) => p + c);
            }

            // Filtering templates based on operator when assigning if the user is not admin
            let filter = 'filter={"include": "templates"}';
            if (!this.isAdmin) {
                filter = `${filter}&operator_id=${this.auth.getOperatorId()}`;
            }
            const templatesData: any = await this.templateService.getAll({ prefix: '/?', filter: filter }).toPromise();
            this.templateFolders = [];
            templatesData.templates.forEach((t: Template) => {
                if (t.folder_name) {
                    const index = this.templateFolders.findIndex(tg => tg.name === t.folder_name);
                    if (index === -1) {
                        this.templateFolders.push({
                            name: t.folder_name,
                            templates: [ t ]
                        });
                    } else {
                        this.templateFolders[ index ].templates.push(t);
                    }
                }
            });
        } catch (err) {
            console.error(err);
        }
        this.isLoading = false;
        this.active = this.allActive();
        this.cdr.detectChanges();
    }

    /**
     * Display modal to create a new Division
     */
    addDivision() {
        const modalRef = this.modalService.open(AddDivisionModalComponent);
        modalRef.componentInstance.setup(this.event.id);
        modalRef.result.then(data => {
            if (data) {
                this.refresh();
            }
        });
    }

    /**
     * Send request to update name of a Division
     * @param division
     * @param value
     */
    renameDivision(division, value) {
        this.isLoading = true;
        this.divisionService.setName(division.id, value)
        .subscribe(
            () => {
                division.name = value;
            },
            err => {
                console.error(err);
                this.isLoading = false;
                this.cdr.detectChanges();
            },
            () => {
                this.isLoading = false;
                this.cdr.detectChanges();
            }
        );
    }

    /**
     * Send request to update abbreviation of a Division
     * @param division
     * @param value
     */
    changeDivisionAbbr(division, value) {
        this.isLoading = true;
        this.divisionService.setAbbreviation(division.id, value)
        .subscribe(
            () => division.abbreviation = value,
            err => {
                console.error(err);
                this.isLoading = false;
                this.cdr.detectChanges();
            },
            () => {
                this.isLoading = false;
                this.cdr.detectChanges();
            }
        );
    }

    /**
     * Display modal to add a new Team
     * @param {number} divisionId
     */
    addTeam(divisionId: number) {
        const modalRef = this.modalService.open(AddTeamModalComponent);
        modalRef.componentInstance.setup(this.event.id, this.divisions, divisionId);
        modalRef.result.then(data => {
            if (data) {
                this.refresh(data.division_id);
            }
        });
    }

    /**
     * Display modal to merge teams of two divisions
     * @param {number} divisionId
     */
    combineWith(divisionId: number) {
        const modalRef = this.modalService.open(DivisionCombineWithComponent);
        modalRef.componentInstance.setup(divisionId, this.divisions);
        modalRef.result
        .then(data => {
            if (data) {
                this.refresh();
            }
        });
    }

    /**
     * Display modal for creating a Template
     */
    onCreateTemplate(division: Division) {
        const modalRef = this.modalService.open(CreateTemplateComponent, { size: 'lg' });
        modalRef.componentInstance.folders = this.templateFolders.map(f => f.name);
        modalRef.result.then(data => {
            if (data) {
                let folder = this.templateFolders.find(f => f.name === data.template.folder_name);
                const template = { ... data.template, id: data.response.id };
                folder = folder || { name: template.folder_name, templates: [] };
                folder.templates.push(template);
            }
            this.cdr.detectChanges();
        });
    }

    /**
     * Expand or collapse all Divisions in the UI
     * @param {boolean} forceCollapsed
     * @param exceptionDivisionId
     */
    expandCollapseAll(forceCollapsed = false, exceptionDivisionId = null) {
        this.allCollapsed = forceCollapsed || !this.allCollapsed;
        this.divisions.forEach(data => {
            data.collapsed = (exceptionDivisionId && exceptionDivisionId === data.id) ? !this.allCollapsed : this.allCollapsed;
        });
    }

    /**
     * Publish or unpublish all Divisions
     * @param ev
     */
    publishAll(ev) {
        const active = !!ev.checked;
        this.isLoading = true;
        const divisionIds = this.divisions.filter(division => division.teams.length > 0).map(division => division.id);
        this.divisionService
        .publish(this.eventId, active, divisionIds)
        .subscribe(
            () => {
                this.divisions
                .filter(division => division.teams.length > 0)
                .forEach(data => (data.active = active));
            },
            err => this.isLoading = false,
            () => {
                this.isLoading = false;
                this.active = this.allActive();
                this.cdr.detectChanges();
            }
        );
    }

    publish(ev, divisionId) {
        const active = !!ev.checked;
        this.isLoading = true;
        this.divisionService
        .publish(this.eventId, active, [divisionId])
        .subscribe(
            () => {
                this.divisions.find(division => division.id === divisionId).active = active;
            },
            err => this.isLoading = false,
            () => {
                this.isLoading = false;
                this.active = this.allActive();
                this.cdr.detectChanges();
            }
        );
    }

    allActive() {
        if (this.divisions) {
            const divisions = this.divisions.filter(division => division.teams.length !== 0);
            const actives = divisions.filter(division => division.active);
            return !!(this.divisions && actives.length === divisions.length);
        } else {
            return false;
        }
    }

    /**
     * Enable mode to move team from a division to another
     * @param division
     */
    enableTeamsMoveMode(division) {
        division.collapsed = false;
        division.teamsMoveMode = true;
    }

    /**
     * Assign a Template to a Division
     * @param {Division} division
     * @param {Template} template
     */
    assignTemplate(division: Division, template: Template) {

        if (division.template_id) {
            return false;
        }

        this.isLoading = true;
        this.divisionService
        .assignTemplate(division.id, template.id)
        .subscribe(
            (data: any) => {
                division.template = template;
                division.template_id = data.templateId;
            },
            err => {
                console.error(err);
                this.isLoading = false;
                this.cdr.detectChanges();
            },
            () => {
                this.isLoading = false;
                this.refresh(division.id);
                this.cdr.detectChanges();
            }
        );
    }

    /**
     * Unassign any Template previously assigned to a Division
     * @param {Division} division
     */
    unassignTemplate(division: Division) {
        this.isLoading = true;
        this.divisionService
        .unassignTemplate(division.id)
        .subscribe(
            () => {
                division.template_id = null;
                division.template = null;
            },
            err => {
                console.error(err);
                this.isLoading = false;
                this.cdr.detectChanges();
            },
            () => {
                this.isLoading = false;
                this.refresh(division.id);
                this.cdr.detectChanges();
            }
        );
    }

    /**
     * Change order of a division in the list of divisions
     * @param division
     * @param targetIndex
     */
    moveDivision(division, targetIndex) {
        if (division.order !== targetIndex + 1) {
            this.isLoading = true;
            this.eventService.changeDivisionOrder(this.event.id, division.id, targetIndex + 1)
            .subscribe(
                () => {
                    this.updateOrderProperties();
                    this.isLoading = false;
                    this.cdr.detectChanges();
                },
                err => {
                    this.revertMovement();
                    console.error(err);
                    this.isLoading = false;
                    this.cdr.detectChanges();
                }
            );
        }
    }

    /**
     * Update property order of divisions
     */
    updateOrderProperties() {
        this.divisions.map((division: Division, index) => {
            division.order = index + 1;
        });
    }

    /**
     * Prepare divisions for movement
     * @param {Division} division
     * @param {number} index
     */
    onDragStart(division: Division, index: number) {
        this.draggingDivision = division;
        this.expandCollapseAll(true);
        this.preDraggingDivisionPosition = index;
    }

    /**
     * End dragging
     */
    onDragEnd() {
        this.draggingDivision = null;
    }

    /**
     * Revert reorder of divisions
     */
    revertMovement() {
        const index = this.divisions.findIndex(d => d.id === this.draggingDivision.id);
        this.divisions.splice(index, 1);
        this.divisions.splice(this.preDraggingDivisionPosition, 0, this.draggingDivision);
    }

    /**
     * When dragging, reorder divisions on mouse over for realtime user feedback
     * @param targetPosition
     * @param division
     */
    onDraggingMouseEnter(targetPosition, division) {
        if (division) {
            const currentPosition = this.divisions.findIndex(d => d.id === division.id);
            this.divisions.splice(currentPosition, 1);
            this.divisions.splice(targetPosition, 0, division);
        }
    }

    /**
     * When dragging, if the dragging area is leaved, revert draggingDivision position
     */
    onDraggingMouseLeave() {
        if (this.draggingDivision) {
            this.revertMovement();
        }
    }
}
