import { DataService } from '../common/data.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {environment as env} from '../../../../environments/environment';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class TemplateGroupService extends DataService {
    constructor(http: HttpClient, auth: AuthService) {
        super(`${env.API_BASE_URL}/TemplateGroups`, http, auth);
    }
}
