import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { Moment } from 'moment';

interface TimeItem {
    hours: number;
    minutes: string;
    meridiem: string;
}

interface DateTimeItem {
    date: Date;
    time: TimeItem;
}

@Component({
    selector: 'bss-datetime-picker',
    templateUrl: './datetime-picker.component.html',
    styleUrls: ['./datetime-picker.component.scss']
})
export class DateTimePickerComponent implements OnInit {
    dt: DateTimeItem;
    timeItems: TimeItem[];
    min: Moment;
    max: Moment;
    private dirty = false;

    @Input() set datetime(val) {
        this.dt = this.getDateTimeItemFromDate(val);
    }

    @Input() set minDate(val) {
        this.min = moment.utc(val);
    }

    @Input() set maxDate(val) {
        this.max = moment.utc(val);
    }

    @Output() valueChange: EventEmitter<Moment> = new EventEmitter();
    @Output() valueChanged: EventEmitter<Moment> = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
        // Generate TimeItems
        this.timeItems = new Array(48).fill(null, 0, 48).map((val, index) => {
            const hours = index % 2 === 0 ? index / 2 : Math.abs(Math.round(index / 2 - 1));
            return {
                hours: (hours >= 12 ? hours - 12 : hours) || 12,
                minutes: index % 2 === 0 ? '00' : '30',
                meridiem: index < 24 ? 'AM' : 'PM'
            };
        });
    }

    /**
     * Emit valueChanged/valueChange event with the updated DateTime object
     */
    change() {
        const date = this.parseDateTimeItemToDate(this.dt);
        this.valueChanged.emit(date);
        this.valueChange.emit(date);
        this.dirty = true;
    }

    /**
     * Take an date string|Moment and create a DateTimeItem object
     * @param {Moment | string} date
     * @returns {DateTimeItem}
     */
    private getDateTimeItemFromDate(date: Moment | string): DateTimeItem {
        date = moment.utc(date);
        const hours = date.hours();
        return {
            date: new Date(date.year(), date.month(), date.date(), date.hours(), date.minutes()),
            time: {
                hours: (hours >= 12 ? hours - 12 : hours) || 12,
                minutes: date.minutes() >= 30 ? '30' : '00',
                meridiem: hours < 12 ? 'AM' : 'PM'
            }
        };
    }

    /**
     * Convert a DateTimeItem object and return a Date object
     * @param {DateTimeItem} dt
     * @returns {Moment}
     */
    private parseDateTimeItemToDate(dt: DateTimeItem): Moment {
        let hours = dt.time.hours === 12 ? 0 : dt.time.hours;
        hours = dt.time.meridiem === 'PM' ? hours + 12 : hours;
        return (moment.utc()
            .year(dt.date.getFullYear())
            .month(dt.date.getMonth())
            .date(dt.date.getDate())
            .hours(hours)
            .minutes(parseInt(dt.time.minutes, 10))
            .seconds(0));
    }

    /**
     * Getter that retrieve the datetime as a Date object
     * @returns {Date}
     */
    get datetime() {
        return this.parseDateTimeItemToDate(this.dt);
    }

    /**
     * Getter that determine if the DateTime is valid
     * @returns {boolean}
     */
    public get isValid() {
        return this.datetime.valueOf() >= this.min.valueOf()
            && this.datetime.valueOf() <= this.max.valueOf();
    }

    /**
     * Getter that returns true if the control has been modified by the user
     * @returns {boolean}
     */
    get isDirty() {
        return this.dirty;
    }
}
