// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    stage: true,
    stage2: false,
    API_BASE_URL: 'https://scheduler-api.tournamentconnect.premiergirlsfastpitch.com/api/v2',
    SSO_API_URI: 'https://auth-premiergirlsfastpitch.us.auth0.com',
    SSO_API_AUDIENCE: 'https://auth-premiergirlsfastpitch.us.auth0.com/api/v2/',
    SSO_REDIRECT_URI: 'https://scheduler.tournamentconnect.premiergirlsfastpitch.com/login',
    SSO_CLIENT_ID: 'vSQVYzOPG4Yh9OGMQRtlalyxizel8Z0N',
    SSO_CLIENT_SECRET: 'wOpc12Qe44mbnBvUSuacFIE-ntiiaem7h0TcLoXwqOHJNnUY2vTyKdAMmEvdJ3ny',
    SSO_DATABASE_CONNECTION: 'PGF-Tplus',
    DELETE_GAMES_OPERATOR_IDS: [11, 28, 38, 53, 54, 57, 58, 59, 61, 64, 65, 68, 75, 77, 78],
    singleRun: true,
    hmr: false,
};
