import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { PrintDataService } from '../../services';
import {
    DivisionService,
    AuthService,
    GameService,
    PoolService,
    EventService,
} from '../../../scheduler/services';
import {
    Division,
    Game,
    Standings,
    StandingsTeam,
    Event
} from '../../../scheduler/interfaces';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs/Observable';
import { ConfirmationDialog } from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { TieBreakerResolver } from '../../components/tiebreaker-resolver/tiebreaker-resolver';
import { PointLabels } from '../../../shared/utils/point-labels';

@Component({
    selector: 'bss-public-pool-standings',
    templateUrl: './pool-standings.page.html',
    styleUrls: ['./pool-standings.page.scss']
})
export class PoolStandingsPage implements OnInit {
    division = <Division>{};
    eventId: number;
    divisionId: number;
    pools: StandingsView[];
    isLoading = false;
    invalidPoolRankValue = false;
    showPoolSeedsLookup: any;
    customColums: any[];
    tieBreakers: any;
    public standings: StandingsView[];

    constructor(
        private route: ActivatedRoute,
        private gameService: GameService,
        private divisionService: DivisionService,
        private poolService: PoolService,
        private modalService: NgbModal,
        private authService: AuthService,
        private eventService: EventService,
        private _printDataService: PrintDataService) { }

    ngOnInit() {
        this.route.parent.parent.params.subscribe(params => {
            this.eventId = params.eventId;

            this.eventService.getById(params.eventId).subscribe((event: Event) => {
                this.tieBreakers = PointLabels.getTieBreakers(+event.sport_id);
            });

            this.gameService.getGames(this.eventId).subscribe((games: Game[]) => {
                this.showPoolSeedsLookup =
                    _.chain(games)
                        .filter(g => !!g.pool_id)
                        .groupBy('pool_id')
                        .mapValues((poolGames) => _.reduce(
                            poolGames,
                            (sum: number, game) => (!game.team_1_score && game.team_1_score !== 0) || (!game.team_2_score && game.team_2_score !== 0) ? sum + 1 : sum,
                            0) === 0
                        )
                        .value();
            });
        });
        this.route.parent.params.subscribe(params => {
            this.divisionId = params.divisionId;
            this.refresh();
        });
    }

    /**
     * Load data from API and  render the page
     * @returns {Promise<void>}
     */
    async refresh() {
        this.isLoading = true;
        try {
            // Get Custom Columns Configuration Array
            const customColumns = await this.eventService.getSortedStandingsColumns(this.eventId);
            this.customColums = customColumns.filter(cc => cc.order > 0);
            // Get Standings
            const data = await this.divisionService.getDivisionStandings(this.divisionId).toPromise();
            // Sort by pool name
            const sortedData = data.sort((a, b) => a.name > b.name ? 1 : -1);
            // Check if each pool has duplicated seed values, if so a editableSeeds flag is set to each one
            this.pools = sortedData.map((pool: StandingsView) => {
                return pool;
            });
            this.division = await this.divisionService.getById(this.divisionId.toString()).toPromise() as Division;

            if (this.division && this.division.template_id) {
                const scheduleData = await this.poolService.getSchedules(this.division.template_id).toPromise();
                this._printDataService.setScheduleData(scheduleData.schedules);
                const standingsData = await this.divisionService.getDivisionStandings(Number(this.divisionId)).toPromise();
                this.standings = standingsData.map((pool: StandingsView) => {
                    if (pool.seeds.length === 0) {
                        return;
                    }
                    const duplicate = (() => {
                        const seen = [];
                        for (let i = 0; i < pool.seeds.length; i++) {
                            if (seen.indexOf(pool.seeds[i].pool_rank) > -1) {
                                return true;
                            }
                            seen.push(pool.seeds[i].pool_rank);
                        }
                        return false;
                    })();
                    if (duplicate) {
                        pool.editableSeeds = true;
                    }
                    return pool;
                });

                this._printDataService.setStandingsData(this.standings);
            }

        } catch (err) {
            console.error(err);
        }
        this.isLoading = false;
    }

    /**
     * Handle the bss-editable-box changeValue and try to send request to API
     * @param pool
     * @param index
     * @param value
     * @returns {boolean}
     */
    onAssignPoolRank(pool, teamId, value) {
        value = parseInt(value, 10);
        if (value > pool.teams.length) {
            pool.invalidSeed = true;
            return false;
        }
        pool.invalidSeed = false;
        const teamIndex = pool.teams.findIndex(team => team.id === teamId);
        if (pool.teams[teamIndex].seed.pool_rank === value) {
            return false;
        }
        // Teams that already have assigned the save pool_rank value provided
        const teams = pool.teams.filter(team => team.seed.pool_rank === value);
        if (teams.length === 0) {
            // Okay, pool_rank value is unique. Proceed.
            this.updateSeeds(this.divisionId, pool, teamIndex, value);
        } else {
            // As there are several teams, ask to the user if he or she wants to continue with the assignment
            const modalRef = this.modalService.open(ConfirmationDialog);
            modalRef.componentInstance.title = `
                A seed equal to <strong>${value}</strong> is already assigned to another team.<br/>
                Would you like to set it anyway?`;
            Observable.fromPromise(modalRef.result).subscribe(result => {
                if (result === true) {
                    this.updateSeeds(this.divisionId, pool, teamIndex, value);
                }
            });
        }
    }

    /**
     * Update standings property with API request
     * @param pool
     * @param teamId
     * @param fieldName
     * @param value
     */
    onUpdate(pool, teamId, fieldName, value) {
        this.isLoading = true;
        const index = pool.teams.findIndex(t => t.id === teamId);
        const payload: StandingsTeam = { [fieldName]: value };
        this.poolService
            .updateTeamStandings(this.eventId, this.divisionId, pool.id, teamId, payload)
            .subscribe(
                () => {
                    pool.teams[index][fieldName] = value;
                    this.isLoading = false;
                },
                err => {
                    console.error(err);
                    this.isLoading = false;
                }
            );
    }

    /**
     * Verify if the currently signedIn user is an operator
     * @returns {boolean}
     */
    get isOperator() {
        return this.authService.isOperator();
    }

    /**
     * Send update pool_ranks request to API using DivisionService
     * @param divisionId
     * @param pool
     * @param index
     * @param value
     */
    updateSeeds(divisionId, pool, index, value) {
        this.isLoading = true;
        const seeds = pool.teams
            .map((team, i) => {
                return {
                    id: team.seed.id,
                    pool_rank: index === i ? value : team.seed.pool_rank
                };
            });
        this.divisionService
            .updateSeedsPoolRank(divisionId, seeds)
            .subscribe(
                () => {
                    this.poolService.advancePool(pool.id);
                    this.refresh();
                    this.isLoading = false;
                },
                err => {
                    console.error(err);
                    this.isLoading = false;
                }
            );
    }

    resolveTieBreaker(divisionId, pool, team) {
        if (!this.isOperator || !team.tiebreaker || !team.tiebreaker.id) {
            return;
        }

        const modalRef = this.modalService.open(TieBreakerResolver);
        modalRef.componentInstance.setType(team.tiebreaker.description);
        modalRef.componentInstance.setTeams(pool.teams);
        modalRef.result.then(data => {
            if (data) {
                data.forEach((teamOrder, i) => {
                    const index = pool.teams.map((t, tIndex) => {
                        if (t.id === teamOrder.teamId) {
                            t.seed.pool_rank = teamOrder.order;
                        }
                    });
                });
                this.updateSeeds(divisionId, pool, null, null);
            }
        });
    }

    isTeamTie(team, pool) {
        return pool.teams.filter(t => t.seed.pool_rank === team.seed.pool_rank).length >= 2;
    }

    isCoinTossOrShootout(tiebreaker) {
        return tiebreaker && (tiebreaker.description === this.tieBreakers.coinToss || tiebreaker.description === this.tieBreakers.shootouts);
    }
}

interface StandingsView extends Standings {
    editableSeeds?: boolean;
    invalidSeed?: boolean;
    pool_play_finished?: boolean;
}
