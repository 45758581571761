import { Component, OnInit, Input } from '@angular/core';
import { TemplatePool, TemplatePoolGroup } from '../../interfaces';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'bss-add-template-pool-group',
    templateUrl: './add-template-pool-group.component.html',
    styleUrls: ['./add-template-pool-group.component.scss']
})
export class AddTemplatePoolGroupComponent implements OnInit {
    pool: TemplatePool = { name: '', visible: true, group: null, seeds: [], crossed: null };
    groupName = '';
    groupIndex: number = null;
    existingGroupMode = true;
    @Input() groups: TemplatePoolGroup[];

    constructor(public activeModal: NgbActiveModal) { }

    ngOnInit() {}

    /**
     * Provide modal with the list of groups and a pool to be modified (optionally)
     * @param {TemplatePoolGroup[]} groups
     * @param {TemplatePool} pool
     */
    setup(groups: TemplatePoolGroup[], pool: TemplatePool) {
        this.groups = groups;
        this.pool = {...pool};
        if (pool.group) {
            this.groupIndex = this.groups.findIndex(g => g.name === pool.group.name);
        }
    }

    /**
     * Close modal passing the modified TemplatePool object
     */
    onSubmit() {
        if (this.existingGroupMode) {
            this.pool.group = (this.groupIndex > -1) ? this.groups[this.groupIndex] : null;
        } else {
            this.pool.group = this.groupName ? { name: this.groupName } : null;
        }
        this.activeModal.close(this.pool);
    }

    /**
     * Close modal without saving
     */
    cancel() {
        this.activeModal.close();
    }
}
