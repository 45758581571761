import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { environment as env } from '../../../../environments/environment';
import { Facility, CourtUnavailabilityTimeFrame as TimeFrame } from '../../interfaces';
import { DataService } from '../common/data.service';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class FacilityService extends DataService {

    constructor(http: HttpClient, private authService: AuthService) {
        super(`${env.API_BASE_URL}/events`, http, authService);
    }

    /**
     * Get a list of divisions that belongs to an event
     * @param {number} eventId
     * @returns {Observable<Division[]>}
     */
    getFacilities(eventId: number = null): Observable<any> {
        const url = `${env.API_BASE_URL}/events/${eventId}/facilities`;
        const headers = new HttpHeaders(
            {'Authorization': `Bearer ${this.authService.getJWT()}`}
        );
        return this.http.get(url, { headers });
    }

    /**
     * Update facility properties. All are required.
     * @param {number} eventId
     * @param {number} facilityId
     * @param {Facility} facility
     * @returns {Observable<any>}
     */
    change(eventId: number, facilityId: number, facility: Facility): Observable<any> {
        const url = `${env.API_BASE_URL}/events/${eventId}/facilities/${facilityId}`;
        const headers = new HttpHeaders(
            {'Authorization': `Bearer ${this.authService.getJWT()}`}
        );
        delete facility.order;
        return this.http.put(url, facility, { headers });
    }

    /**
     * Add a new Facility
     * @param {number} eventId
     * @param body
     * @returns {Observable<Object>}
     */
    add(eventId: number, body) {
        const url = `${env.API_BASE_URL}/events/${eventId}/facilities`;
        const headers = new HttpHeaders(
            {'Authorization': `Bearer ${this.authService.getJWT()}`}
        );
        return this.http.post(url, body, { headers });
    }

    /**
     * Remove a Facility
     * @param {number} eventId
     * @param {number} facilityId
     * @returns {Observable<Object>}
     */
    remove(eventId: number, facilityId: number) {
        const url = `${env.API_BASE_URL}/events/${eventId}/facilities/${facilityId}`;
        const headers = new HttpHeaders(
            {'Authorization': `Bearer ${this.authService.getJWT()}`}
        );
        return this.http.delete(url, { headers });
    }

    getUnavailability(eventId: number): Observable<any> {
        const url = `${env.API_BASE_URL}/events/${eventId}/facilities/courts/unavailability`;
        const headers = new HttpHeaders(
            {'Authorization': `Bearer ${this.authService.getJWT()}`}
        );
        return this.http.get(url, { headers })
        .map((resp: any) => {
            return { timeframes: resp.unavailability, facilities: resp.facilities };
        });
    }

    setUnavailabilityTimeFrame(eventId: number, payload: TimeFrame, id: number = null) {
        const url = `${env.API_BASE_URL}/events/${eventId}/facilities/courts/unavailability`;
        const headers = new HttpHeaders(
            {'Authorization': `Bearer ${this.authService.getJWT()}`}
        );
        if (id) {
            return this.http.put(`${url}/${id}`, payload, { headers });
        }
        return this.http.post(url, payload, { headers });
    }

    deleteUnavailabilityTimeFrame(eventId: number, id: number) {
        const url = `${env.API_BASE_URL}/events/${eventId}/facilities/courts/unavailability/${id}`;
        const headers = new HttpHeaders(
            {'Authorization': `Bearer ${this.authService.getJWT()}`}
        );
        return this.http.delete(url, { headers });
    }
}
