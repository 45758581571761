import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PublicDataService } from '../common/public-data.service';
import { environment as env } from '../../../../environments/environment';

@Injectable()
export class ExternalDetailsService extends PublicDataService {

    constructor(http: HttpClient) {
        super(`${env.API_BASE_URL}/externalids`, http);
    }

    /**
     * Retrieve a external details for entity_type and entity_id
     * @param {string} entity_type
     * @param {number} entity_id
     * @returns {Observable<Event[]>}
     */
    getExternalDetails(entity_type: string, entity_id: number) {
        const params: any = {};
        params.entity_type = entity_type;
        params.entity_id = entity_id;
        return this.http.get<any>(`${env.API_BASE_URL}/externalids`, { params });
    }
}
