import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, of } from 'rxjs';
import { RecentEvent } from '../../classes/RecentEvent';

const recent_cookie = 'PGF_recent_cookies';
const max_History_Cnount = 10;

@Injectable()
export class EventHistoryService {
    private _cookie: string;

    eventHistory: Observable<Array<RecentEvent>>;
    private _eventHistory: ReplaySubject<Array<RecentEvent>>;

    constructor() {
        this._eventHistory = new ReplaySubject<Array<RecentEvent>>();
        this.eventHistory = this._eventHistory.asObservable();
        this.initEventHistory();
    }

    initEventHistory(): void {
        try {
            const value = this.getCookie(recent_cookie);
            let events = new Array<RecentEvent>();

            if (typeof value !== 'undefined') {
                events = JSON.parse(value);
                this._eventHistory.next(events);
            }
        } catch (err) {
            console.log('initEventHistory: ' + err.message);
        }
    }

    setEventHistory(eventname: string, eventid: Number) {
        let items: Array<RecentEvent> = new Array<RecentEvent>();
        const date = new Date();
        let newData = '';
        let start = 0;

        try {
            let value = this.getCookie(recent_cookie);

            if (typeof value !== 'undefined') {
                items = JSON.parse(value);
            } else {
                value = '';
            }

            date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000));

            // if already in coookie we are done
            if (items.findIndex(f => f.name === eventname && f.id === eventid) !== -1) {
                return;
            }

            const event = new RecentEvent;
            event.name = eventname;
            event.id = Number(eventid);

            items.push(event);
            start = items.length - max_History_Cnount;
            if (start < 0) {
                start = 0;
            }
            items = items.slice(start);

            newData = JSON.stringify(items);

            // save to cookie
            this.setCookie(recent_cookie, newData, date);

            this._eventHistory.next(items);
        } catch (err) {
            console.log('setEventHistory: ' + err.message);
        }
    }

    setCookie(name, value, expires) {
        try {
            if (expires instanceof Date) {
                expires = expires.toUTCString();
            }

            let updatedCookie = name + '=' + encodeURIComponent(value) + '; expires';
            const optValue = expires;

            if (optValue !== true) {
                updatedCookie += '=' + expires;
            }

            updatedCookie += '; path=/;';
            document.cookie = updatedCookie;
            document.cookie = '; expires';
        } catch (err) {
            console.log('setCookie; ' + err.message);
        }
    }

    getCookie(name) {
        const cookie = document.cookie;
        const matches = cookie.match(new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'));

        let value = matches ? decodeURIComponent(matches[1]) : undefined;

        if (typeof value === 'undefined') {
            value = '';
        }

        return matches ? decodeURIComponent(matches[1]) : undefined;
    }
}
