import { Component, OnInit, Input } from '@angular/core';
import { EventService, TieBreakerService } from '../../services';
import { TieBreakerOption, TieBreaker } from '../../interfaces';
import * as _ from 'lodash';

@Component({
    selector: 'bss-set-tie-breakers',
    templateUrl: './set-tie-breakers.component.html',
    styleUrls: ['./set-tie-breakers.component.scss']
})
export class SetTieBreakersComponent implements OnInit {

    @Input() eventId: number;
    event: any;
    tieBreakerOptions: TieBreakerOption[] = [];
    tieBreakers: TieBreakerView[] = [];
    acceptableConfiguration = false;
    originalTieBreakers: TieBreakerView[];
    isLoading = false;
    draggingItem = null;
    draggingCol = null;
    stillDraggable = {};
    mutuallyExclusiveTiebreakers: string[] = ['Shootouts', 'Coin Toss'];

    constructor(
        private eventService: EventService,
        private tieBreakerService: TieBreakerService) {}

    ngOnInit() {
        this.refresh();
    }

    async refresh() {
        this.isLoading = true;
        this.event = await this.eventService.getById(this.eventId + '').toPromise();
        this.tieBreakers = await this.tieBreakerService.get(this.event.sport.id).toPromise();
        this.tieBreakerOptions = await this.tieBreakerService.getOptionsForEvent(this.eventId).toPromise();
        this.originalTieBreakers = JSON.parse(JSON.stringify(this.tieBreakerOptions));

        this.validate();

        this.isLoading = false;
        this.updateStaging();
    }

    /**
     * Get an array of the size of the total points minus the size arg provided
     * @param {number} size
     * @returns {any[]}
     */
    getPlaceholders(size: number) {
        return Array(this.tieBreakers.length - size);
    }

    getSelectOptions(max) {
        return Array(max + 1);
    }

    /**
     * Add new TieBreakerOption or change order of it.
     */
    addOrMove(item: TieBreakerOption|TieBreakerView, teamNumber: number, destIndex = 0, isOption = true) {
        let tieBreakerOptionsWorkingColumn = this.tieBreakerOptions.filter(tbo => tbo.team_number === teamNumber);
        const tieBreakerOptionsOtherColumn = this.tieBreakerOptions.filter(tbo => tbo.team_number !== teamNumber);

        // Get the current mutually exclusive tiebreaker in list to restore it at the end of the list later.
        const oldMutuallyExclusiveTiebreaker = _.remove(tieBreakerOptionsWorkingColumn,
            tbo => _.includes(this.mutuallyExclusiveTiebreakers, tbo.description)
        );
        // If the new tiebreaker is mutually exclusive then add it at the end of the list.
        const newTiebreakerIsMutuallyExclusive = _.includes(this.mutuallyExclusiveTiebreakers, item.description);
        if (newTiebreakerIsMutuallyExclusive) {
            destIndex = tieBreakerOptionsWorkingColumn.length;
        }

        let tb;
        if (!isOption) {
            tb = <TieBreakerView> this.tieBreakers.find(t => t.id === item.id);
        } else {
            tb = <TieBreakerOption> item;
            const tbOption = <TieBreakerOption> tieBreakerOptionsWorkingColumn.find(tbo => tbo.id === item.id);
            if (!tbOption || tbOption.team_number !== tb.team_number) {
                return;
            }

        }

        if (tb && teamNumber === 3 && tb.allow_three_teams === 0) {
            return;
        }

        const existingIndex = tieBreakerOptionsWorkingColumn
        .findIndex(tbo => tbo.id === tb.id);

        if (existingIndex !== -1 && !isOption) {
            return;
        }

        const option = {
            id: tb.id,
            description: item.description,
            diff_max: item.diff_max,
            differential: -1,
            team_number: teamNumber,
            sort: 1
        };

        let localDestIndex = tieBreakerOptionsWorkingColumn.indexOf(tieBreakerOptionsWorkingColumn[destIndex]);
        localDestIndex = localDestIndex > -1 ? localDestIndex : tieBreakerOptionsWorkingColumn.length;

        if (existingIndex >= 0) {
            if (existingIndex === destIndex) {
                return;
            }
            tieBreakerOptionsWorkingColumn.splice(existingIndex, 1);
            tieBreakerOptionsWorkingColumn.splice(localDestIndex, 0, option);
        } else {
            tieBreakerOptionsWorkingColumn.splice(localDestIndex, 0, option);
        }

        // if the new dragged item is NOT coin toss or shootout restore the last one.
        if (!newTiebreakerIsMutuallyExclusive) {
            tieBreakerOptionsWorkingColumn = _.concat(tieBreakerOptionsWorkingColumn, oldMutuallyExclusiveTiebreaker);
        }

        this.tieBreakerOptions = _.concat(tieBreakerOptionsWorkingColumn, tieBreakerOptionsOtherColumn);
        this.updateOrder(teamNumber);
        this.validate();
        this.updateStaging();
    }

    moveBackToStaging(item: TieBreakerOption, isOption: boolean) {
        if (isOption) {
            const index: number = this.tieBreakerOptions.findIndex(tbo => tbo.id === item.id && tbo.team_number === item.team_number);
            if (index !== -1) {
                this.tieBreakerOptions.splice(index, 1);
                this.updateOrder();
                this.updateStaging();
            }
        }
    }

    updateStaging() {
        this.tieBreakers.map(tb => {
            const limit = tb.allow_three_teams ? 2 : 1;
            tb.available = this.tieBreakerOptions.filter(tbo => tbo.id === tb.id).length < limit;
            return tb;
        });
    }


    twoTeamsOptions() {
        return this.tieBreakerOptions.filter(tbo => tbo.team_number === 2) || [];
    }

    threeTeamsOptions() {
        return this.tieBreakerOptions.filter(tbo => tbo.team_number === 3) || [];
    }

    /**
     * Update the sort property of each TieBreakerOption
     * @param {TieBreakerOption} tb
     */
    updateOrder(teamNumber = null) {
        // Update order for 2 teams
        if (!teamNumber || teamNumber === 2) {
            this.tieBreakerOptions
            .filter(tb => tb.team_number === teamNumber)
            .map((tb, i) => {
                tb.sort = i + 1;
                return tb;
            });
        }

        // Update order for 3 teams
        if (!teamNumber || teamNumber === 3) {
            this.tieBreakerOptions
            .filter(tb => tb.team_number === teamNumber)
            .map((tb, i) => {
                tb.sort = i + 1;
                return tb;
            });
        }
    }

    delete(option: TieBreakerOption, isOption, teamNumber) {
        if (!isOption) {
            return;
        }
        const i = this.tieBreakerOptions.findIndex(tbo => {
            return tbo.id === tbo.id && tbo.team_number === teamNumber;
        });
        if (i >= 0) {
            this.tieBreakerOptions.splice(i, 1);
        }
        this.updateOrder(teamNumber);
    }

    validate() {
        if (this.tieBreakerOptions.find(tbo => tbo.diff_max && parseInt(tbo.differential + '', 10) === -1)) {
            this.acceptableConfiguration = false;
            return;
        }
        this.acceptableConfiguration = true;
    }

    reset() {
        this.clear();
        this.tieBreakerOptions = JSON.parse(JSON.stringify(this.originalTieBreakers));
        this.updateStaging();
    }

    clear() {
        this.tieBreakerOptions = [];
        this.updateStaging();
    }

    save() {
        this.isLoading = true;
        const payload = [];
        this.tieBreakerOptions.forEach(t => {
            payload.push({
                differential: t.differential === -1 ? null : t.differential,
                sort: t.sort,
                team_number: t.team_number,
                id: t.id,
            });
        });
        this.tieBreakerService
        .save(this.eventId, payload)
        .subscribe(
            data => {
                this.isLoading = false;
                this.originalTieBreakers = JSON.parse(JSON.stringify(this.tieBreakers));
            },
            err => {
                console.error(err);
                this.isLoading = false;
            }
        );
    }

    dragEnd() {
        this.draggingItem = null;
        this.draggingCol = null;
    }

    dragStart(item, col) {
        this.draggingItem = item;
        this.draggingCol = col;
    }
}

interface TieBreakerView extends TieBreaker {
    available?: boolean;
}
