import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TeamsAndDivisionsPage } from './pages/teams-and-divisions/teams-and-divisions.page';
import { DivisionsPage } from './pages/divisions/divisions.page';
import { EventListPage } from './pages/event-list/event-list.page';
import { MasterPage } from './pages/master/master.page';
import { TemplatesPage } from './pages/templates/templates.page';
import { TemplatesHomePage } from './pages/templates-home/templates-home.page';
import { DivisionsMainPage } from './pages/divisions-main/divisions-main.page';
import { DivisionSeedingPage } from './pages/division-seeding/division-seeding.page';
import { AdminSettingsPage } from './pages/admin/settings/settings.page';
import { OperatorSettingsPage } from './pages/operator/settings/settings.page';
import { AdminUsersPage } from './pages/admin/users/users.page';
import { EventSettingsPage } from './pages/event-settings/event-settings.page';

// TODO: remove
import { PoolSchedulesPage } from './pages/pool-schedules/pool-schedules.page';
import { TemplatePoolsPage } from './pages/template-pools/template-pools.page';
import { SchedulerPage } from './pages/scheduler/scheduler.page';
import { BracketTemplateDetailsPage } from './pages/bracket-template-details/bracket-template-details.page';
import { AdminOperatorsPage } from './pages/admin/operators/operators.page';
import { LoginPage } from './pages/login/login.page';
import { TemplateLayoutPage } from './pages/template-layout/template-layout.page';
import { SchedulerSettingsPage } from './pages/scheduler-settings/scheduler-settings.page';
import { FacilitiesPage } from './pages/facilities/facilities.page';
import { DivisionLayoutPage } from './pages/division-layout/division-layout.page';
import { EventReportsPage } from './pages/event-reports/event-reports.page';
import { ScoringByDivisionPage } from './pages/scoring-by-division/scoring-by-division.page';
import { ScoringByCourtPage } from './pages/scoring-by-court/scoring-by-court.page';
import { ScheduleByDivisionPage } from './pages/schedule-by-division/schedule-by-division.page';

// Guards & Resolvers
import { TemplateResolver } from './guards/template-resolver.guard';
import { AuthResolver } from './guards/auth-resolver.guard';
import { OperatorGuard } from './guards/operator.guard';
import { AdminGuard } from './guards/admin.guard';
import { EventDivisionsResolver } from './guards/event-divisions-resolver.guard';
import { PendingChangesGuard } from './guards/pending-changes.guard';
import { DivisionResolver } from './guards/division-resolver.guard';

const routes: Routes = [
    {
        path: '',
        component: MasterPage,
        children: [
            {
                path: 'login',
                component: LoginPage
            },
            {
                path: 'events',
                component: EventListPage,
                canActivate: [OperatorGuard]
            },
            {
                path: 'events/:eventId/settings',
                component: EventSettingsPage,
                canActivate: [OperatorGuard],
            },
            {
                path: 'events/:eventId',
                component: TeamsAndDivisionsPage,
                canActivate: [OperatorGuard],
                resolve: {
                    eventsAndDivisions: EventDivisionsResolver
                },
                children: [
                    {
                        path: 'divisions',
                        component: DivisionsMainPage,
                        canActivate: [OperatorGuard],
                    },
                    {
                        path: 'divisions/:divisionId',
                        component: DivisionsPage,
                        resolve: { division: DivisionResolver },
                        children: [
                            {
                                path: 'seeding',
                                component: DivisionSeedingPage
                            },
                            {
                                path: '',
                                component: DivisionLayoutPage,
                                children: [
                                    {
                                        path: 'pools',
                                        component: TemplatePoolsPage,
                                    },
                                    {
                                        path: 'pool-schedules',
                                        component: PoolSchedulesPage,
                                    },
                                    {
                                        path: 'brackets',
                                        component: BracketTemplateDetailsPage
                                    }
                                    // {
                                    //     path: 'pages',
                                    //     component: DivisionPagesPage
                                    // },
                                ]
                            },
                            // {
                            //     path: 'schedule-requests',
                            //     component: DivisionRestrictionsPage
                            // }
                        ]
                    },
                    {
                        path: 'grid',
                        component: SchedulerPage,
                        canDeactivate: [PendingChangesGuard]
                    },
                    {
                        path: 'grid/settings',
                        component: SchedulerSettingsPage,
                    },
                    {
                        path: 'facilities',
                        component: FacilitiesPage
                    },
                ]
            },
            {
                path: 'events/:eventId/reports',
                component: EventReportsPage,
                resolve: {
                    eventsAndDivisions: EventDivisionsResolver
                },
                children: [
                    {
                        path: '',
                        redirectTo: 'scoring-by-division',
                        pathMatch: 'full',
                    },
                    {
                        path: 'scoring-by-division',
                        component: ScoringByDivisionPage
                    },
                    {
                        path: 'schedule-by-division',
                        component: ScheduleByDivisionPage,
                    },
                    {
                        path: 'scoring-by-court',
                        component: ScoringByCourtPage,
                    }
                ]
            },
            {
                path: 'templates',
                component: TemplatesPage,
                canActivate: [OperatorGuard],
                children: [
                    {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: 'home',
                    },
                    {
                        path: 'home',
                        component: TemplatesHomePage,
                    },
                    {
                        path: ':templateId',
                        component: TemplateLayoutPage,
                        resolve: {
                            template: TemplateResolver
                        },
                        children: [
                            {
                                path: 'pools',
                                component: TemplatePoolsPage,
                            },
                            {
                                path: 'schedules',
                                component: PoolSchedulesPage,
                                canActivate: [OperatorGuard],
                            },
                            {
                                path: 'brackets',
                                component: BracketTemplateDetailsPage
                            }
                            // {
                            //     path: 'pages',
                            //     component: TemplatePagesPage
                            // }
                        ]
                    },
                ]
            },
            {
                path: 'admin/settings',
                component: AdminSettingsPage,
                canActivate: [AdminGuard],
            },
            {
                path: 'admin/users',
                component: AdminUsersPage,
                canActivate: [AdminGuard],
            },
            {
                path: 'operator/settings',
                component: OperatorSettingsPage,
                canActivate: [OperatorGuard],
            },
            {
                path: 'admin/operators',
                component: AdminOperatorsPage,
                canActivate: [AdminGuard],
            },
            {
                path: '',
                pathMatch: 'full',
                redirectTo: '/schedules',
            },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class SchedulerRoutingModule {
}
