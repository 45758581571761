import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
    name: 'gameBlockSortByGameNumber'
})

export class GameBlockSortByGameNumberPipe implements PipeTransform {
    transform(array: Array<any>): Array<any> {
        return _.sortBy(array, [gameBlock => gameBlock.game.game_number]);
    }
}
