import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NguiDatetimePickerModule } from '@ngui/datetime-picker';
import { ConfirmationDialog } from './components/confirmation-dialog/confirmation-dialog.component';
import { LoadingEffectComponent } from './components/loading-effect/loading-effect.component';
import { EditableBoxComponent } from './components/editable-box/editable-box.component';
import { ScorableBoxComponent } from './components/scorable-box/scorable-box.component';
import { ScorerComponent } from './components/scorer/scorer.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { SubSectionNavComponent } from './components/sub-section-nav/sub-section-nav.component';
import { InRangeValidatorDirective } from './directives/in-range/in-range-validator.directive';
import { AutofocusDirective } from './directives/autofocus/autofocus.directive';
import { DateTimePickerComponent } from './components/datetime-picker/datetime-picker.component';
import { InternalBreadcrumbsComponent } from './components/internal-breadcrumbs/internal-breadcrumbs.component';
import { NotificationsService } from './services';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        NguiDatetimePickerModule,
        RouterModule,
    ],
    declarations: [
        // Components
        ConfirmationDialog,
        LoadingEffectComponent,
        EditableBoxComponent,
        ScorableBoxComponent,
        ScorerComponent,
        BreadcrumbComponent,
        DateTimePickerComponent,
        // Directives
        SubSectionNavComponent,
        InRangeValidatorDirective,
        AutofocusDirective,
        InternalBreadcrumbsComponent
    ],
    entryComponents: [
        ConfirmationDialog,
    ],
    exports: [
        ConfirmationDialog,
        LoadingEffectComponent,
        EditableBoxComponent,
        ScorableBoxComponent,
        ScorerComponent,
        BreadcrumbComponent,
        SubSectionNavComponent,
        InRangeValidatorDirective,
        AutofocusDirective,
        DateTimePickerComponent,
        InternalBreadcrumbsComponent,
    ],
    providers: [
        NotificationsService,
    ]
})
export class SharedModule { }
