import * as moment from 'moment';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { EventService } from '../../../scheduler/services';
import { Event } from '../../../scheduler/interfaces/index';
import { forEach } from 'lodash';
import { Router } from '@angular/router';
import { EventHistoryService } from '../../services/event-history/event-history.service';

@Component({
    selector: 'bss-public-event-list',
    templateUrl: './event-list.page.html',
    styleUrls: ['./event-list.page.scss'],
    encapsulation: ViewEncapsulation.None
})
export class EventListPage implements OnInit {

    events: Event[];
    isLoading = false;
    ascOrDesc = true;
    searchCtrlGroup: FormGroup;
    searchTerms = '';
    minDate: any = moment.utc(Date.now()).subtract(1, 'w').toDate();
    maxDate: any = moment.utc(Date.now()).add(3, 'w').toDate();
    recent_cookie: any = 'PGF_recent_cookies';

    constructor(
        private eventService: EventService,
        public router: Router,
        private eventHistoryService: EventHistoryService) {
    }

    ngOnInit() {
        this.searchCtrlGroup = new FormGroup({
            search: new FormControl()
        });

        this.searchCtrlGroup
            .controls['search']
            .valueChanges
            .debounceTime(500)
            .subscribe(value => {
                this.searchTerms = value;
                this.getEvents();
            });
    }

    changeDateFilters() {
        this.getEvents();
    }

    sort() {
        this.ascOrDesc = !this.ascOrDesc;
        this.getEvents();
    }

    getEvents() {
        this.isLoading = true;
        this.eventService
            .getList(
                this.ascOrDesc,
                this.searchTerms,
                this.minDate,
                this.maxDate
            )
            .subscribe((data) => {
                this.events = data;
                this.isLoading = false;
            });
    }

    SaveRecent(event: Event) {
        this.eventHistoryService.setEventHistory(event.name, event.id);
    }
}
