import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as jQuery from 'jquery';
import * as Mousetrap from 'mousetrap';

import * as _ from 'lodash';
import * as moment from 'moment';
import * as randomColor from 'randomcolor';
import { Moment } from 'moment';
import 'jquery-ui-dist/jquery-ui.js';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, forkJoin } from 'rxjs';

import '../../../../rxjs-imports';
import { ComponentCanDeactivate } from '../../guards/pending-changes.guard';
import { EditGameBlockComponent } from '../../components/edit-game-block/edit-game-block.component';
import { SchedulerComponent } from '../../components/scheduler/scheduler.component';
import { FacilityService, GameService, TemplatePoolService, EventService } from '../../services';
import { CourtUnavailabilityTimeFrame, Division, Facility, Game, TemplatePool } from '../../interfaces';
import { areCoordsOverElement } from '../../helpers';
import { GridSettingsBlockComponent } from '../../components/grid-settings-block/grid-settings-block.component';

interface Hideable {
    hidden?: boolean;
}

interface FacilityView extends Hideable, Facility {
}

interface DivisionView extends Hideable, Division {
}

interface GameView extends Game {
    backgroundColor: string;
    pool: TemplatePool;
    division: DivisionView;
    gameblock?: any;
    setting_id?: number;
    team_1?: any;
    team_2?: any;
}

interface GameEvent extends GameView {
    end: Moment;
    rendering?: string;
    resourceId: number;
    start: Moment;
    title: string;
}

const ACTION_MOVE_GAME_TO_SCHEDULER = 'ACTION_MOVE_GAME_TO_SCHEDULER'
    , ACTION_MOVE_GAME_TO_STAGING = 'ACTION_MOVE_GAME_TO_STAGING'
    , ACTION_MOVE_GAME = 'ACTION_MOVE_GAME';

const CONFLICT_MIN_TIME_BETWEEN_GAMES = 'CONFLICT_MIN_TIME_BETWEEN_GAMES'
    , CONFLICT_MIN_TIME_BETWEEN_GAMES_LABEL = 'Minimum time between games not met.'
    , CONFLICT_MAX_TIME_BETWEEN_GAMES = 'CONFLICT_MAX_TIME_BETWEEN_GAMES'
    , CONFLICT_COURT_GAMES_OVERLAPPING = 'CONFLICT_COURT_GAMES_OVERLAPPING'
    , CONFLICT_MAX_TIME_BETWEEN_GAMES_LABEL = 'Maximum time between games per team exceeded.'
    , CONFLICT_MAX_GAMES_PLAYED_PER_DAY = 'CONFLICT_MAX_GAMES_PLAYED_PER_DAY'
    , CONFLICT_MAX_GAMES_PLAYED_PER_DAY_LABEL = 'Maximum games played per day per team exceeded.'
    , CONFLICT_COURT_GAMES_OVERLAPPING_LABEL = 'Game time overlapped for the same court';

const DEFAULT_CALENDAR_HEIGHT = 550;

@Component({
    selector: 'bss-scheduler-page',
    templateUrl: './scheduler.page.html',
    styleUrls: ['./scheduler.page.scss']
})
export class SchedulerPage implements OnInit, AfterViewInit, ComponentCanDeactivate {
    @ViewChild('calendarWrapper') calendarWrapperChild: ElementRef;
    @ViewChildren('gameRef') gameChildren: QueryList<ElementRef>;
    @ViewChild(SchedulerComponent) schedulerChild: SchedulerComponent;
    @ViewChild('staging') stagingChild: ElementRef;

    calendarOptions = {
        allDaySlot: false,
        defaultDate: null,
        defaultView: 'agendaDay',
        defaultTimedEventDuration: '01:00',
        dragRevertDuration: 0,
        eventBackgroundColor: '#009bdf',
        eventClick: (calEvent, jsEvent, view) => {
            this.openGameBlockModal(calEvent);
        },
        eventOverlap: false,
        eventRender: (event: GameEvent, element, view) => {

            if (event.rendering !== 'background') {
                // filter games out based on filter settings
                if (this.isGameEventHidden(event)) {
                    // The block on the grid will still be occupied, but no HTML will be rendered
                    return false;
                }

                const fcContentElement = element.find('.fc-content');

                // Time is displayed by default
                element.find('.fc-time').remove();
                if (this.showGameTime) {
                    fcContentElement.append(`<div class="fc-time">
                        <span ${view.type === 'timelineDay' ? 'style="font-size: .7em; font-weight: normal"' : ''}>
                            ${event.start.format('hh:mm A')} - ${event.end.format('hh:mm A')}
                        </span>
                    </div>`);
                }
                const extraPaddingLeft = view.type === 'timelineDay' ? 'style="padding-left: 1px;"' : '';
                if (this.showGameTeams) {
                    let firstParticipant = event.seed1 && (event.seed1.team ? event.seed1.team.name : 'Seed' + event.seed1.overall_rank);
                    if (!firstParticipant && event.gameblock && event.gameblock.id) {
                        firstParticipant = event.gameblock.participant_label_1 || '&lt;Home&gt;';
                    }
                    let secondParticipant = event.seed2 && (event.seed2.team ? event.seed2.team.name : 'Seed' + event.seed2.overall_rank);
                    if (!secondParticipant && event.gameblock && event.gameblock.id) {
                        secondParticipant = event.gameblock.participant_label_2 || '&lt;Away&gt;';
                    }
                    fcContentElement.append(`<div class="fc-teams" ${extraPaddingLeft}>
                        ${firstParticipant}<span style="font-style: italic"> VS &nbsp;</span>${secondParticipant}
                    </div>`);
                }
                if (this.showGamePlayPoolPosition && event.pool.id && event.seed1 && event.seed2) {
                    fcContentElement.append(`<div class="fc-teams" ${extraPaddingLeft}>
                        ${event.pool.name}${event.seed1.order}
                        <span style="font-style: italic"> VS &nbsp;</span>
                        ${event.pool.name}${event.seed2.order}
                    </div>`);
                }

                const gameConflictRef = this.gameConflicts[event.id];
                if (gameConflictRef && gameConflictRef.length) {
                    let conflictText = '';

                    if (gameConflictRef.indexOf(CONFLICT_MIN_TIME_BETWEEN_GAMES) !== -1) {
                        conflictText += `${CONFLICT_MIN_TIME_BETWEEN_GAMES_LABEL}<br>`;
                    }

                    if (gameConflictRef.indexOf(CONFLICT_MAX_TIME_BETWEEN_GAMES) !== -1) {
                        conflictText += `${CONFLICT_MAX_TIME_BETWEEN_GAMES_LABEL}<br>`;
                    }

                    if (gameConflictRef.indexOf(CONFLICT_MAX_GAMES_PLAYED_PER_DAY) !== -1) {
                        conflictText += `${CONFLICT_MAX_GAMES_PLAYED_PER_DAY_LABEL}`;
                    }

                    if (gameConflictRef.indexOf(CONFLICT_COURT_GAMES_OVERLAPPING) !== -1) {
                        conflictText += `${CONFLICT_COURT_GAMES_OVERLAPPING_LABEL}`;
                    }

                    const conflictHTML = `<i class="fa fa-warning fc-conflict" title="${conflictText}"></i>`;
                    fcContentElement.append(conflictHTML);
                    const conflictElement = fcContentElement.find('.fc-conflict');
                    conflictElement.tooltip({
                        content: conflictElement.attr('title')
                    });
                }

                // determine if event will have overflow information
                // and whether or not to expand event
                element
                    .mouseover(function () {
                        const self = jQuery(this);

                        const contentHeight = self.find('.fc-content').height();
                        const eventHeight = self.height();

                        if (contentHeight > eventHeight) {
                            self.addClass('fc-expanded');
                        }
                    })
                    .mouseout(function () {
                        jQuery(this).removeClass('fc-expanded');
                    });
            }
        },
        eventResourceEditable: true,
        eventStartEditable: true,
        eventTextColor: '#fff',
        events: [],
        forceEventDuration: true,
        height: DEFAULT_CALENDAR_HEIGHT,
        header: false,
        minTime: '07:00',
        maxTime: '23:00',
        nowDuration: false,
        resources: [],
        resourceAreaWidth: 150,
        resourceLabelText: 'Courts',
        slotDuration: '00:30:00',
        slotLabelFormat: 'h:mm a',
        slotLabelInterval: '00:60:00',
        snapDuration: '00:30:00',
        theme: false,
        titleFormat: 'YYYY-MM-DD dddd'
    };
    calendarWrapperStyle = {
        height: null,
        width: null,
        'padding-right': null,
        'padding-left': null
    };
    divisionSelectedCount: number;
    divisions = <DivisionView[]>[];
    eventDates = [];
    facilities = <FacilityView[]>[];
    facilitySelectedCount: number;
    games = <GameView[]>[];
    gameConflicts = {};
    gameTypes = ['pool', 'bracket'];
    gridSettings = {};
    hours = [];
    pools = <TemplatePool[]>[];
    printMode = false;
    saving = false;
    schedulerHistory = [];
    schedulerHistoryIndex = -1;
    showGameTime = false;
    showGameTeams = false;
    showGamePlayPoolPosition = false;
    showGameTypes = Object.assign([], this.gameTypes);
    stagingPosition = 'right';
    stagingStyle = {
        height: null,
        width: null
    };
    stagedGameIds = <number[]>[];
    eventId = null;

    constructor(
        private changeRef: ChangeDetectorRef,
        private facilityService: FacilityService,
        private gameService: GameService,
        private templatePoolService: TemplatePoolService,
        private eventService: EventService,
        private modalService: NgbModal,
        private route: ActivatedRoute) {
        this.hours = Array(24).fill(0).map((n, i) => {
            const hour = i;

            const value: string = hour < 10 ? `0${hour.toString()}:00` : `${hour}:00`;
            const display = `${hour <= 12 && hour !== 0 ? hour.toString() : (Math.abs((hour - 12))).toString()}:00 ${hour >= 12 && hour !== 0 ? 'PM' : 'AM'}`;

            return { display, value };
        });
    }

    ngOnInit() {
        this.eventId = parseInt(this.route.snapshot.parent.params.eventId, 10);
        forkJoin([this.getFacilities(), this.getGames(), this.getPools(), this.getDivisions(), this.getGridSettings(), this.getUnavailability()])
            .subscribe(results => {
                // generate resources
                this.refreshResources();
                this.setFacilitiesSelected();
                this.setDivisionsSelected();

                const settings = results[4] as any[];
                // generate events
                const games = results[1] as Game[];
                this.games = [];
                this.calendarOptions.events = [];

                const unavailability = results[5] as { timeframes: CourtUnavailabilityTimeFrame[] };
                // set blocked out time
                unavailability.timeframes.forEach(tF => {
                    tF.courts.forEach(courtId => {
                        this.addBlockedEventTime(moment.utc(tF.from_date), moment.utc(tF.to_date), courtId);
                    });
                });

                games.forEach(g => {
                    const pool = this.getPoolById(g.pool.id) || {};
                    const division = this.getDivisionById(g.division.id) || {};
                    const game: any = {
                        ...g,
                        pool,
                        division,
                        backgroundColor: randomColor({ seed: g.division.id || null, luminosity: 'dark' })
                    };

                    settings.forEach(s => {
                        const divisionIndex = s.divisions.findIndex(d => d.id === g.division.id);

                        if (divisionIndex !== -1) {
                            game.setting_id = s.id;
                        }
                    });

                    if (g.start_date && g.end_date && g.court.id) {
                        this.calendarOptions.events.push(this.generateGameEvent(game));
                    } else {
                        this.games.push(game);
                    }
                });

                this.calendarOptions.events.forEach(e => {
                    const conflicts = this.getConflicts(e);

                    if (conflicts.length) {
                        this.addConflicts(conflicts);
                    }
                });
            });
    }

    ngAfterViewInit() {
        this.makeGamesDraggable();
        this.gameChildren.changes.subscribe(
            () => {
                this.makeGamesDraggable();
            }
        );

        Mousetrap.bind(['command+p', 'ctrl+p'], (e) => {
            if (e.preventDefault) {
                e.preventDefault();
            } else {
                e.returnValue = false;
            }
            this.onPrint();
        });
    }

    getGridSettings() {
        const observable = this.eventService.getGridSettings(this.eventId);
        observable.subscribe(
            (response: any[]) => {
                // set event dates
                if (response && response.length) {
                    const settings = response[response.length - 1] as GridSettingsBlockComponent;

                    response.forEach(setting => {
                        this.gridSettings[setting.id] = setting;
                    });
                }
            }
        );
        return observable;
    }

    /**
     * Retrieves list of facilities from facilityService.
     * On success, assigns calendar resources
     * @returns {Observable<Facility[]>}
     */
    getFacilities() {
        const observable = this.facilityService.getFacilities(this.eventId);
        observable.subscribe(
            response => {
                // set event dates
                if (response.start_date && response.end_date) {
                    this.setEventDates(response.start_date, response.end_date);
                }
                this.facilities = response.facilities;
            }
        );
        return observable;
    }

    /**
     * Retrieves list of court unavailabilities from facilityService
     * On success, assigns unavailable time blocks to scheduler grid
     * @returns {Observable<any>}
     */
    getUnavailability() {
        const observable = this.facilityService.getUnavailability(this.eventId);
        return observable;
    }

    /**
     * Retrieves list of pools from templatePoolService.
     * On success, assigns pools
     * @returns {Observable<Facility[]>}
     */
    getPools() {
        const observable = this.eventService.getPools(this.eventId);
        observable.subscribe(
            divisions => {
                const pools = [];

                divisions.forEach(d => {
                    d.template.pools.forEach(p => {
                        pools.push({
                            ...p,
                            division_id: d.id,
                            template_id: d.template.id
                        });
                    });
                });

                this.pools = pools;
            }
        );
        return observable;
    }

    /**
     * Retrieves list of divisions from divisionService.
     * On success, assigns divisions
     * @returns {Observable<Division[]>}
     */
    getDivisions() {
        const observable = this.eventService.getDivisions(this.eventId);
        observable.subscribe(
            (data: any) => {
                this.divisions = data.divisions.map(d => {
                    d.color = randomColor({ seed: d.id || null, luminosity: 'dark' });
                    return d;
                });
            }
        );
        return observable;
    }

    /**
     * Retrieves list of games from gameService.
     * @returns {Observable<Game[]>}
     */
    getGames() {
        const observable = this.gameService
            .getGames(this.eventId)
            .map((resp: any) => {
                return resp.games;
            });
        return observable;
    }

    /**
     * Retrieves a specific pool by poolId from local pools property
     * @param {number} poolId
     * @returns {TemplatePool}
     */
    getPoolById(poolId: number): TemplatePool {
        return this.pools.find(p => poolId === p.id);
    }

    /**
     * Retrieves a specific division by divisionId from local divisions property
     * @param {number} divisionId
     * @returns {DivisionView}
     */
    getDivisionById(divisionId: number): DivisionView {
        return (this.divisions.find(d => divisionId === d.id) || <DivisionView>{});
    }

    /**
     * Generates and assigns a FullCalendar event
     * used to 'black out' specific areas of the calendar grid
     * @param {Moment} start
     * @param {Moment} end
     * @param {number} resourceId
     */
    addBlockedEventTime(start: Moment, end: Moment, resourceId: number) {
        this.calendarOptions.events.push({
            resourceId,
            start,
            end,
            rendering: 'background',
            backgroundColor: '#9fa4aa'
        });
    }

    /**
     * Toggles the FullCalendar view between 'timelineDay' and 'agendaDay'
     */
    toggleCalendarView() {
        const scheduler = this.schedulerChild.schedule;
        const view = scheduler.fullCalendar('getView');
        const switchToView = view.type === 'agendaDay' ? 'timelineDay' : 'agendaDay';
        scheduler.fullCalendar('changeView', switchToView);
    }

    /**
     * Method to remove a Game from the Staging area and
     * add a GameEvent to the Scheduler Grid.
     * ACTION_MOVE_GAME_TO_SCHEDULER will be added
     * to the schedulerHistory by default
     * @param {GameEvent} event
     * @param {boolean} addHistory
     */
    moveGameToScheduler(event: GameEvent, addHistory = true) {
        this.calendarOptions.events.push({
            ...event,
            court: { id: event.resourceId },
            end_date: event.end,
            start_date: event.start
        });
        // remove from games
        this.games = this.games.filter(g => g.id !== event.id);

        if (addHistory) {
            this.addSchedulerHistory(ACTION_MOVE_GAME_TO_SCHEDULER, { event });
        }

        this.reProcessConflicts();
        this.stageGameId(event.id);
    }

    /**
     * Method to remove a GameEvent from the Scheduler Grid
     * and add a Game to the Staging area
     * ACTION_MOVE_GAME_TO_STAGING will be added
     * to the schedulerHistory by default
     * @param {GameEvent} event
     * @param {boolean} addHistory
     */
    moveGameToStaging(event: GameEvent, addHistory = true) {
        // delete event from calendar events
        const eventIndex = this.calendarOptions.events.findIndex(e => e.id === event.id);

        if (eventIndex !== -1) {
            this.calendarOptions.events = this.calendarOptions.events.filter(e => e.id !== event.id);

            // add back to games
            this.games.push(<GameView>{
                id: event.id,
                backgroundColor: event.backgroundColor,
                court_id: null,
                division: event.division,
                division_id: event.division_id,
                game_number: event.game_number,
                game_type: event.game_type,
                locked: event.locked,
                notes: event.notes,
                pool: event.pool,
                pool_id: event.pool_id,
                // convert date string back to Date object
                setting_id: event.setting_id,
                start_date: null,
                end_date: null,
                seed1: event.seed1 || null,
                seed2: event.seed2 || null,
                team_1_score: event.team_1_score,
                team_2_score: event.team_2_score,
                event_id: event.event_id,
                status: event.status,
                gameblock: event.gameblock
            });

            if (addHistory) {
                this.addSchedulerHistory(ACTION_MOVE_GAME_TO_STAGING, { event });
            }

            this.reProcessConflicts();
            this.stageGameId(event.id);
        }
    }

    /**
     * Method to move a game on the Scheduler Grid
     * and keep GameEvents in sync between the local component
     * and the Scheduler Grid
     * ACTION_MOVE_GAME will be added
     * to the schedulerHistory by default
     * @param {GameEvent} event
     * @param {boolean} addHistory
     */
    moveGame(event: GameEvent, addHistory = true) {
        const previousEventIndex = this.calendarOptions.events.findIndex(e => e.id === event.id);

        if (previousEventIndex !== -1) {
            const previousEvent = this.calendarOptions.events[previousEventIndex];

            // Keep events in sync
            this.calendarOptions.events[previousEventIndex] = event;

            if (addHistory) {
                this.addSchedulerHistory(ACTION_MOVE_GAME, {
                    previousEvent,
                    event
                });
            }

            this.reProcessConflicts();
            this.stageGameId(event.id);
        }
    }

    /**
     * Method to add an action to the
     * schedulerHistory
     * @param actionName
     * @param actionData
     */
    addSchedulerHistory(actionName, actionData) {
        const currentIndex = this.schedulerHistoryIndex;

        // Remove any history after the currentIndex action
        this.schedulerHistory.splice(currentIndex + 1);

        // add action to history
        this.schedulerHistory.push({
            name: actionName,
            data: actionData
        });

        this.schedulerHistoryIndex = (this.schedulerHistory.length - 1);
    }

    /**
     * Method to increment the schedulerHistory by 1
     * and trigger the associated action required
     */
    schedulerHistoryForward() {
        const forwardIndex = this.schedulerHistoryIndex + 1;

        if (forwardIndex < this.schedulerHistory.length) {
            const action = this.schedulerHistory[forwardIndex];
            switch (action.name) {
            case ACTION_MOVE_GAME_TO_SCHEDULER: {
                this.moveGameToScheduler(action.data.event, false);
                break;
            }
            case ACTION_MOVE_GAME_TO_STAGING: {
                this.moveGameToStaging(action.data.event, false);
                break;
            }
            case ACTION_MOVE_GAME: {
                this.moveGame(action.data.event, false);
                break;
            }
            }

            this.schedulerHistoryIndex = forwardIndex;
        }
    }

    /**
     * Method to decrement the schedulerHistory by 1
     * and trigger the associated action required
     */
    schedulerHistoryBackward() {
        const previousIndex = this.schedulerHistoryIndex;

        if (previousIndex >= 0) {
            const action = this.schedulerHistory[previousIndex];
            switch (action.name) {
            case ACTION_MOVE_GAME_TO_SCHEDULER: {
                this.moveGameToStaging(action.data.event, false);
                break;
            }
            case ACTION_MOVE_GAME_TO_STAGING: {
                this.moveGameToScheduler(action.data.event, false);
                break;
            }
            case ACTION_MOVE_GAME: {
                this.moveGame(action.data.previousEvent, false);
                break;
            }
            }

            this.schedulerHistoryIndex = previousIndex - 1;
        }
    }

    /**
     * Method to add a game Id to the staging change log
     * @param {number} gameId
     */
    stageGameId(gameId: number) {
        // add game to gameChangeLog
        if (this.stagedGameIds.indexOf(gameId) === -1) {
            this.stagedGameIds.push(gameId);
        }
    }

    /**
     * Resets the Staging Size & Calendar Size to their defaults
     */
    resetStagingSize() {
        this.stagingStyle.height = null;
        this.stagingStyle.width = null;
        this.calendarWrapperStyle.height = null;
        this.calendarWrapperStyle.width = null;
    }

    /**
     * Updates the count of facilities selected in the facility filter
     */
    setFacilitiesSelected() {
        this.facilitySelectedCount = this.facilities.reduce((total, f) => total + (f.hidden ? 0 : 1), 0);
    }

    /**
     * Method to set the eventDates property based on a start and end date
     * @param {Date} startDate
     * @param {Date} endDate
     */
    setEventDates(startDate: string, endDate: string) {
        const eventDates = [];
        const start = moment.utc(startDate);
        const end = moment.utc(endDate);

        const dateIterator = start;

        // push each date in date range into eventDates
        while (dateIterator <= end) {
            eventDates.push(_.cloneDeep(dateIterator));
            dateIterator.add(1, 'days');
        }

        // set default date to first day
        this.calendarOptions.defaultDate = eventDates[0].toISOString();

        this.eventDates = eventDates;
    }

    toggleAllFacilities(hidden: boolean) {
        this.facilities = this.facilities.map(f => ({ ...f, hidden: hidden }));
        this.setFacilitiesSelected();
    }

    /**
     * Updates the count of divisions selected in the division filter
     */
    setDivisionsSelected() {
        this.divisionSelectedCount = this.divisions.reduce((total, d) => total + (d.hidden ? 0 : 1), 0);
    }

    toggleAllDivisions(hidden: boolean) {
        this.divisions = this.divisions.map(f => ({ ...f, hidden: hidden }));
        this.setDivisionsSelected();
    }

    /**
     * Method that sets showGameTypes back to default
     */
    showAllGameTypes() {
        this.showGameTypes = Object.assign([], this.gameTypes);
    }

    /**
     * Method triggered when a game type in the Game Type filter list is selected
     * @param event
     * @param {string} gameType
     */
    onGameTypeClick(event, gameType: string) {
        // stop dropdown from closing
        event.stopPropagation();

        // toggle game type in showGameTypes array
        const gameTypeIndex = this.showGameTypes.findIndex(gT => gT === gameType);
        if (gameTypeIndex === -1) {
            this.showGameTypes.push(gameType);
        } else {
            this.showGameTypes.splice(gameTypeIndex, 1);
        }

        this.reRenderEvents();
    }

    /**
     * Method to check & return any conflicts for the provided game
     * @param {GameEvent} game
     * @returns {any[]}
     */
    getConflicts(game: GameEvent) {
        const setting = this.gridSettings[game.setting_id];

        const checkTeamConflicts = (compareGame: GameEvent, games: GameEvent[]) => {
            const minTimeBetweenGames = setting.min_break;
            const maxTimeBetweenGames = setting.max_break;

            const gameConflicts = [];

            games.sort((a, b) => a.start < b.start ? -1 : 1);
            const gameIndex = games.findIndex(g => g.id === compareGame.id);

            if (gameIndex !== -1) {
                const gameBeforeIndex = gameIndex - 1;
                const gameAfterIndex = gameIndex + 1;

                games.forEach((g, i) => {
                    if (i === gameBeforeIndex || i === gameAfterIndex) {
                        const game1 = games[i];
                        const game2 = compareGame;

                        const game1StartDate = moment.utc(game1.start);
                        const game1EndDate = moment.utc(game1.end);

                        const game2StartDate = moment.utc(game2.start);
                        const game2EndDate = moment.utc(game2.end);

                        if (game2StartDate <= game1StartDate) { // game2 is before game 1
                            const gameBeforeMinTimeBetweenCutoff = moment.utc(game1StartDate);
                            gameBeforeMinTimeBetweenCutoff.subtract(minTimeBetweenGames, 'minutes');

                            // min time between games conflict check
                            if (game2EndDate > gameBeforeMinTimeBetweenCutoff) {
                                gameConflicts.push({
                                    gameIds: [game1.id, game2.id],
                                    type: CONFLICT_MIN_TIME_BETWEEN_GAMES
                                });
                            }

                            // max time between games conflict check
                            const gameBeforeMaxTimeBetweenCutoff = moment.utc(game1StartDate);
                            gameBeforeMaxTimeBetweenCutoff.subtract(maxTimeBetweenGames, 'minutes');

                            if (game2EndDate < gameBeforeMaxTimeBetweenCutoff) {
                                gameConflicts.push({
                                    gameIds: [game1.id, game2.id],
                                    type: CONFLICT_MAX_TIME_BETWEEN_GAMES
                                });
                            }
                        } else if (game2StartDate >= game1StartDate) { // game2 is after game1
                            const gameAfterMinTimeBetweenCutoff = moment.utc(game1EndDate);
                            gameAfterMinTimeBetweenCutoff.add(minTimeBetweenGames, 'minutes');

                            // min time between games conflict check
                            if (game2StartDate < gameAfterMinTimeBetweenCutoff) {
                                gameConflicts.push({
                                    gameIds: [game1.id, game2.id],
                                    type: CONFLICT_MIN_TIME_BETWEEN_GAMES
                                });
                            }

                            // max time between games conflict check
                            const gameAfterMaxTimeBetweenCutoff = moment.utc(game1EndDate);
                            gameAfterMaxTimeBetweenCutoff.add(maxTimeBetweenGames, 'minutes');

                            if (game2StartDate > gameAfterMaxTimeBetweenCutoff) {
                                gameConflicts.push({
                                    gameIds: [game1.id, game2.id],
                                    type: CONFLICT_MAX_TIME_BETWEEN_GAMES
                                });
                            }
                        }
                    }
                });

            }

            return gameConflicts;
        };

        let conflicts = [];

        // check team based conflicts
        const teamGames = {};

        if (game.seed1 && game.seed1.team && game.seed1.team.id) {
            teamGames[game.seed1.team.id] = this.calendarOptions.events.filter(e => e.id !== game.id
                && ((e.seed1 && e.seed1.team && e.seed1.team.id === game.seed1.team.id) || (e.seed2 && e.seed2.team && e.seed2.team.id === game.seed1.team.id))
                && ((moment.utc(e.start)).date()) === (moment.utc(game.start).date())
            );
            teamGames[game.seed1.team.id].push(game);

            conflicts = conflicts.concat(checkTeamConflicts(game, teamGames[game.seed1.team.id]));

            // check for max games played per day per team
            if (teamGames[game.seed1.team.id].length > setting.max_daily_games) {
                conflicts.push({
                    gameIds: [game.id],
                    type: CONFLICT_MAX_GAMES_PLAYED_PER_DAY
                });
            }
        }

        if (game.seed2 && game.seed2.team && game.seed2.team.id) {
            teamGames[game.seed2.team.id] = this.calendarOptions.events.filter(e => e.id !== game.id
                && ((e.seed1 && e.seed1.team && e.seed1.team.id === game.seed2.team.id) || (e.seed2 && e.seed2.team && e.seed2.team.id === game.seed2.team.id))
                && ((moment.utc(e.start)).date()) === (moment.utc(game.start).date())
            );
            teamGames[game.seed2.team.id].push(game);

            conflicts = conflicts.concat(checkTeamConflicts(game, teamGames[game.seed2.team.id]));

            // check for max games played per day per team
            if (teamGames[game.seed2.team.id].length > setting.max_daily_games) {
                conflicts.push({
                    gameIds: [game.id],
                    type: CONFLICT_MAX_GAMES_PLAYED_PER_DAY
                });
            }
        }

        const courtGames = this.calendarOptions.events.filter(e => e.id !== game.id && parseInt(e.resourceId, 10) === parseInt(<any>game.resourceId, 10) && !e.rendering);

        courtGames.forEach(cG => {
            if ((cG.start <= game.start && cG.end > game.start) || (cG.start >= game.start && cG.start < game.end)) {
                conflicts.push({
                    gameIds: [game.id, cG.id],
                    type: CONFLICT_COURT_GAMES_OVERLAPPING
                });
            }
        });

        return conflicts;
    }

    /**
     * Method that clears conflicts for a given gameId
     * @param {number} gameId
     */
    clearConflicts(gameId?: number) {
        this.gameConflicts[gameId] = [];
    }

    /**
     * Method to check for conflicts and prompt the user whether or not to override
     * @returns {boolean}
     */
    overrideConflicts(conflicts: any[]) {
        let allow = true;

        if (conflicts.length) {
            // concatenate reasons into string for prompt
            let conflictReasons = '';
            let hasMinTimeBetweenGamesReason = false;
            let hasMaxTimeBetweenGamesReason = false;
            let hasMaxGamesPerDayReason = false;
            let hasOverlap = false;

            conflicts.forEach(conflict => {
                if (conflict.type === CONFLICT_MIN_TIME_BETWEEN_GAMES) {
                    hasMinTimeBetweenGamesReason = true;
                } else if (conflict.type === CONFLICT_MAX_TIME_BETWEEN_GAMES) {
                    hasMaxTimeBetweenGamesReason = true;
                } else if (conflict.type === CONFLICT_MAX_GAMES_PLAYED_PER_DAY) {
                    hasMaxGamesPerDayReason = true;
                } else if (conflict.type === CONFLICT_COURT_GAMES_OVERLAPPING) {
                    hasOverlap = true;
                }
            });

            if (hasMinTimeBetweenGamesReason) {
                conflictReasons += `\n${CONFLICT_MIN_TIME_BETWEEN_GAMES_LABEL}`;
            }

            if (hasMaxTimeBetweenGamesReason) {
                conflictReasons += `\n${CONFLICT_MAX_TIME_BETWEEN_GAMES_LABEL}`;
            }

            if (hasMaxGamesPerDayReason) {
                conflictReasons += `\n${CONFLICT_MAX_GAMES_PLAYED_PER_DAY_LABEL}`;
            }

            if (hasOverlap) {
                conflictReasons += `\n${CONFLICT_COURT_GAMES_OVERLAPPING_LABEL}`;
            }

            allow = confirm(`There are conflicts with scheduling this game at this time:\n${conflictReasons}\n\nOverride and schedule anyways?`);
        }

        return allow;
    }

    /**
     * Method that recalculates conflicts for any existing gameConflicts
     */
    reProcessConflicts() {
        // clear & reprocess other game conflicts
        Object.keys(this.gameConflicts).forEach(id => {
            const game = this.calendarOptions.events.find(e => e.id === parseInt(id, 10));

            if (game !== undefined) {
                this.clearConflicts(game.id);
                const conflicts = this.getConflicts(game);

                if (conflicts.length) {
                    this.addConflicts(conflicts);
                }
            }
        });
    }

    /**
     * Method to add conflicts to their respective gameConflict objects
     * @param {any[]} conflicts
     */
    addConflicts(conflicts: any[]) {
        conflicts.forEach(c => {
            c.gameIds.forEach(id => {
                let gameConflictRef = this.gameConflicts[id];

                if (!gameConflictRef) {
                    gameConflictRef = this.gameConflicts[id] = [];
                }

                if (gameConflictRef && gameConflictRef.indexOf(c.type) === -1) {
                    gameConflictRef.push(c.type);
                }
            });
        });
    }

    /**
     * Recalculates and assigns courts to the calendar resources
     */
    refreshResources() {
        // Merge all facilities and courts into flat array of objects
        this.calendarOptions.resources =
            (([].concat.apply([], this.facilities.map(
                f => {
                    if (!f.hidden) {
                        return f.courts.map(
                            c =>
                                ({ id: c.id, title: c.name })
                        );
                    }
                }
            )
            )).filter(i => i !== undefined));
    }

    /**
     * Method that reRenders all fullCalendar events
     */
    reRenderEvents() {
        this.schedulerChild.schedule.fullCalendar('rerenderEvents');
    }

    /**
     * Generates a GameEvent object
     * @param {GameView} game
     * @returns {GameEvent}
     */
    generateGameEvent(game: GameView) {
        return <GameEvent>{
            ...game,
            title: this.generateGameLabel(game),
            start: moment.utc(game.start_date),
            end: moment.utc(game.end_date),
            resourceId: game.court ? game.court.id : null,
        };
    }

    /**
     * Method that returns the Game Block label
     * @param {GameView} game
     * @returns {string}
     */
    generateGameLabel(game: GameView) {
        let label = `${game.division.abbreviation}-`;

        if (game.game_type === 'pool') {
            label += `P${game.pool.name || ''}`;
        } else if (game.game_type === 'bracket') {
            label += 'B';
        }

        label += `${game.game_number}`;

        return label;
    }

    /**
     * Clears all active GameEvent filters
     */
    clearFilters() {
        // Reset time range filter
        this.calendarOptions.minTime = '07:00';
        this.calendarOptions.maxTime = '23:00';
        // Reset facilities filter
        this.toggleAllFacilities(false);
        // Reset Divisions filter
        this.toggleAllDivisions(false);
        // Reset game types filter
        this.showAllGameTypes();

        // refresh calendar
        this.refreshResources();
        this.reRenderEvents();
    }

    /**
     * Method that returns true/false if a GameEvent should be hidden based on filter settings
     * @param {GameEvent} event
     * @returns {boolean}
     */
    isGameEventHidden(event: GameEvent) {
        return ((event.game_type && this.showGameTypes.indexOf(event.game_type) === -1)
            || (event.division.id && (this.getDivisionById(event.division.id) || <DivisionView>{}).hidden));
    }

    /**
     * Toggles all facility filters either true/false.
     * Refreshes calendar resources
     * @param event
     * @param {boolean} hidden
     */
    onToggleAllFacilities(event, hidden: boolean) {
        // stop dropdown from closing
        event.stopPropagation();

        this.toggleAllFacilities(hidden);
        this.refreshResources();
    }

    /**
     * Method triggered when a facility in the Facility filter is selected
     * @param event
     * @param {FacilityView} facility
     */
    onFacilityClick(event, facility: FacilityView) {
        // stop dropdown from closing
        event.stopPropagation();
        facility.hidden = !facility.hidden;

        this.facilitySelectedCount += facility.hidden ? -1 : 1;

        this.refreshResources();
    }

    /**
     * Toggles all division filters either true/false.
     * Refreshes calendar resources
     * @param event
     * @param {boolean} hidden
     */
    onToggleAllDivisions(event, hidden: boolean) {
        // stop dropdown from closing
        event.stopPropagation();

        this.toggleAllDivisions(hidden);
        this.reRenderEvents();
    }

    /**
     * Method triggered when a division in the Division filter is selected
     * @param event
     * @param {DivisionView} division
     */
    onDivisionClick(event, division: DivisionView) {
        // stop dropdown from closing
        event.stopPropagation();
        division.hidden = !division.hidden;

        this.divisionSelectedCount += division.hidden ? -1 : 1;

        this.reRenderEvents();
    }

    /**
     * Sets the game type filter to display all game types and Rerenders events
     * @param event
     */
    onShowAllGameTypes(event) {
        // stop dropdown from closing
        event.stopPropagation();

        this.showAllGameTypes();
        this.reRenderEvents();
    }

    /**
     * Method that handles printing the scheduler grid using jQuery print
     */
    onPrint() {
        this.printMode = true;
        // force a UI update before triggering print
        this.calendarOptions.height = window.screen.height;
        this.changeRef.detectChanges();
        this.schedulerChild.renderCalendar();
        (<any>window).jQuery(this.schedulerChild.schedule).print();
        this.printMode = false;
        this.calendarOptions.height = DEFAULT_CALENDAR_HEIGHT;
        this.schedulerChild.renderCalendar();
        this.changeRef.detectChanges();
    }

    /**
     * Method called when FullCalendar retrieves
     * an external jQuery UI draggable GameEvent.
     * This is triggered by FullCalendar outside Angulars change detection
     * @param event
     */
    externalDrop(e) {
        const jsEvent = e.jsEvent;
        if (jsEvent && !areCoordsOverElement(this.stagingChild, jsEvent.pageX, jsEvent.pageY)) {
            const date = e.date;
            const gameId = jQuery(jsEvent.target).data('game-id');

            const game = this.games.find(g => g.id === gameId);

            if (game && date && e.resourceId) {
                const setting = this.gridSettings[game.setting_id];
                const event = this.generateGameEvent(game);
                event.start = date;
                event.end = date.clone().add(setting.game_length, 'minutes');
                event.resourceId = e.resourceId;

                // find out if the game was dropped over a blocked area
                let droppedOnBlockedArea = false;

                this.calendarOptions.events
                    // filter all background events for the same event day
                    .filter(calEvent => {
                        return calEvent.rendering === 'background'
                            && parseInt(calEvent.resourceId, 10) === parseInt(<any>event.resourceId, 10)
                            && ((moment.utc(calEvent.start)).date()) === (moment.utc(event.start).date());
                    })
                    .forEach(calEvent => {
                        // check if dropped event is overlapping the blocked area
                        if ((calEvent.start <= event.start && calEvent.end > event.start) || (calEvent.start >= event.start && calEvent.start < event.end)) {
                            droppedOnBlockedArea = true;
                        }
                    });

                const conflicts = this.getConflicts(event);

                if (!droppedOnBlockedArea && this.overrideConflicts(conflicts)) {
                    this.moveGameToScheduler(event);
                    this.addConflicts(conflicts);
                    this.changeRef.detectChanges();
                } else {
                    this.reRenderEvents();
                }
            }
        }
    }

    /**
     * Method called when FullCalendar event starts dragging.
     * This is triggered by FullCalendar outside Angulars change detection
     * @param e
     */
    eventDragStart(e) {
    }

    /**
     * Method called when a FullCalendar event stops dragging,
     * whether it is inside or outside the calendar.
     * This is triggered by FullCalendar outside Angulars change detection
     * @param e
     */
    eventDragStop(e) {
        const jsEvent = e.jsEvent;

        if (jsEvent && areCoordsOverElement(this.stagingChild, jsEvent.pageX, jsEvent.pageY)) {
            // add item back to staging
            this.moveGameToStaging(e.event);
            this.changeRef.detectChanges();
        }
    }

    /**
     * Method called when FullCalendar detects an event
     * that has been moved inside the calendar
     * This is triggered by FullCalendar outside Angulars change detection
     * @param e
     */
    eventDrop(e: any) {
        const event = e.event;
        const eventIndex = this.calendarOptions.events.findIndex(calEvent => calEvent.id === event.id);

        const conflicts = this.getConflicts(event);

        if (eventIndex !== -1 && this.overrideConflicts(conflicts)) {
            this.moveGame(event);
            this.addConflicts(conflicts);
        } else {
            e.revertFunc();
        }
    }

    /**
     * Method called when the Staging area
     * is resized by mwlResizable directive
     * @param event
     */
    onStagingResize(event) {
        const paddingAmount = 15;

        if (this.stagingPosition === 'left') {
            this.calendarWrapperStyle['padding-left'] = `${paddingAmount}px`;
        } else if (this.stagingPosition === 'right') {
            this.calendarWrapperStyle['padding-right'] = `${paddingAmount}px`;
        }

        if (this.stagingPosition === 'bottom') {
            this.stagingStyle.height = `${event.rectangle.height}px`;
        } else if (this.stagingPosition === 'left' || this.stagingPosition === 'right') {
            this.stagingStyle.width = `${event.rectangle.width}px`;
            // 15px for calendar padding
            this.calendarWrapperStyle.width = `calc(100% - ${event.rectangle.width + paddingAmount}px)`;
        }
    }

    /**
     * Makes gameChildren elements draggable using jQuery UI draggable
     */
    makeGamesDraggable() {
        this.gameChildren.toArray().forEach(elm => {
            (<any>jQuery(elm.nativeElement)).draggable({
                appendTo: '.scheduler-container',
                helper: 'clone',
                revert: true,
                revertDuration: 0,
                scroll: true,
                zIndex: 3
            });
        });
    }

    /**
     * Method that triggers the EditGameBlockComponent modal instance
     * @returns {Observable<any>}
     */
    openGameBlockModal(game: GameEvent) {
        const modalRef = this.modalService.open(EditGameBlockComponent);
        modalRef.componentInstance.game = game;
        modalRef.componentInstance.facilities = this.facilities;
        modalRef.componentInstance.eventDates = this.eventDates;
        modalRef.componentInstance.gameLength = this.gridSettings[game.setting_id].game_length;

        const observable = Observable.fromPromise(modalRef.result);

        observable.subscribe(
            (modalResult: any) => {
                if (modalResult && modalResult.id) {
                    // check for conflicts
                    const gameIndex = this.calendarOptions.events.findIndex(e => e.id === modalResult.id);

                    if (gameIndex !== -1) {
                        const gEvent = this.generateGameEvent(modalResult);

                        const conflicts = this.getConflicts(gEvent);

                        if (this.overrideConflicts(conflicts)) {
                            const _events = this.calendarOptions.events;
                            const putGameData = {
                                id: gEvent.id,
                                court_id: gEvent.court.id,
                                game_number: gEvent.game_number,
                                game_type: gEvent.game_type,
                                locked: !!gEvent.locked,
                                notes: gEvent.notes || null,
                                pool_id: gEvent.pool_id,
                                status: gEvent.status || 'scheduled',
                                start_date: gEvent.start_date,
                                end_date: gEvent.end_date,
                                seed_1: gEvent.seed1 ? gEvent.seed1.id : undefined,
                                seed_2: gEvent.seed2 ? gEvent.seed2.id : undefined,
                                team_1_score: gEvent.team_1_score,
                                team_2_score: gEvent.team_2_score
                            };

                            this.gameService.putGame(putGameData)
                                .subscribe(response => {
                                    this.calendarOptions.events[gameIndex] = gEvent;
                                    this.reProcessConflicts();
                                    this.addConflicts(conflicts);

                                    // refresh events
                                    this.reRenderEvents();

                                    // remove from stagedIds if present
                                    const stagedIdIndex = this.stagedGameIds.indexOf(gEvent.id);
                                    if (stagedIdIndex !== -1) {
                                        this.stagedGameIds.splice(stagedIdIndex, 1);
                                    }
                                }, (err) => {
                                    this.openGameBlockModal(gEvent);
                                });
                        } else {
                            this.openGameBlockModal(gEvent);
                        }
                    }
                }
            }
        );

        return observable;
    }

    // @HostListener allows us to also guard against browser refresh, close, etc.
    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        // returning true will navigate without confirmation
        // returning false will show a confirm dialog before navigating away
        return !this.stagedGameIds.length;
    }

    save() {
        this.saving = true;
        const games = this.stagedGameIds.map(id => {
            // find the matching game in either calendar events or staging games
            const game = this.calendarOptions.events.find(e => e.id === id) || this.games.find(g => g.id === id)
                || this.games.find(e => e.id === id) || this.games.find(g => g.id === id);

            const event = this.calendarOptions.events.find(e => e.id === id);
            if (event) {
                event.start_date = game.start;
                event.end_date = game.end;
            }
            const startDate = game.start && game.start.toISOString ? game.start.toISOString() : null;
            const endDate = game.end && game.end.toISOString ? game.end.toISOString() : null;
            const team1 = game.seed1 && game.seed1.team;
            const team2 = game.seed2 && game.seed2.team;

            return <GameView>{
                id: game.id,
                // backgroundColor: game.backgroundColor,
                court_id: game.resourceId || null,
                // division: game.division,
                // division_id: game.division_id,
                game_number: game.game_number,
                game_type: game.game_type,
                locked: !!game.locked,
                notes: game.notes,
                // pool: game.pool,
                pool_id: game.pool_id,
                start_date: startDate,
                end_date: endDate,
                // seed1: {
                //     id: game.seed1.id,
                //     team_id: (game.seed1.team || {}).id || null,
                // },
                // seed2: {
                //     id: game.seed2.id,
                //     team_id: (game.seed2.team || {}).id || null
                // },
                team_1: { id: team1 && team1.id },
                team_2: { id: team2 && team2.id },
                team_1_score: game.team_1_score,
                team_2_score: game.team_2_score,
                event_id: game.event_id
            };

        });

        this.gameService.putGames({ games })
            .subscribe(
                data => {
                    this.stagedGameIds = [];
                },
                err => {
                    this.saving = false;
                },
                () => {
                    this.saving = false;
                }
            );
    }
}
