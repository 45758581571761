import {BadInput} from '../../common/bad-input';
import {NotFoundError} from '../../common/not-found-error';
import {AppError} from '../../common/app-error';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {throwError} from 'rxjs';
import {AuthService} from '../auth/auth.service';

@Injectable()
export class DataService {
    constructor(protected url: string, protected http: HttpClient, protected auth: AuthService) {
    }

    // TODO: model out options object
    getAll(options: object = {}) {
        const url = options['filter']
            ? `${this.url}${`${options['prefix']}${options['filter']}`}`
            : this.url;

        return this.http.get(url)
            .map(response => response)
            .catch(this.handleError);
    }

    getById(id: string, options: object = null) {
        let url = id ? `${this.url}/${id}` : null;
        if (options && options['filter']) {
            url = url + `${options['prefix']}${options['filter']}`;
        }

        return this.http.get(url)
            .map(response => response)
            .catch(this.handleError);
    }

    create(resource) {
        return this.http.post(this.url, resource, {
            headers: new HttpHeaders().set('Authorization', `Bearer ${this.auth.getJWT()}`),
        })
        .map(response => response)
        .catch(this.handleError);
    }

    update(resource) {
        return this.http.patch(this.url + '/' + resource.id, resource.payload, {
            headers: new HttpHeaders().set('Authorization', `Bearer ${this.auth.getJWT()}`),
        })
            .map(response => response)
            .catch(this.handleError);
    }

    delete(id) {
        return this.http.delete(this.url + '/' + id, {
            headers: new HttpHeaders().set('Authorization', `Bearer ${this.auth.getJWT()}`),
        })
            .map(response => response)
            .catch(this.handleError);
    }

    protected handleError(error: Response) {
        if (error.status === 400) {
            return throwError(new BadInput(error));
        }

        if (error.status === 404) {
            return throwError(new NotFoundError());
        }

        return throwError(new AppError(error));
    }
}
