import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'bss-master',
    templateUrl: './master.page.html',
    styleUrls: ['./master.page.scss']
})
export class MasterPage implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
