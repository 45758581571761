import { Component, OnInit, Input } from '@angular/core';
import * as _ from 'lodash';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TemplateService } from '../../services/template/template.service';

@Component({
    selector: 'bss-create-bracket',
    templateUrl: './create-bracket.component.html',
    styleUrls: ['./create-bracket.component.scss']
})
export class CreateBracketComponent implements OnInit {
    @Input() bracket = { id: null, name: '', team_count: '2', style: 'right', type: 'standard' };
    templateGroups = [];

    constructor(public activeModal: NgbActiveModal,
                private templateService: TemplateService) {
    }

    ngOnInit() {
        this.templateService.getAll({})
            .subscribe(
                (data: any) => {
                    this.templateGroups = _.chain(data.templates)
                    .groupBy('team_count')
                    .map((templates, team_count) => ({ templates, team_count }))
                    .value();
                }
            );
    }

    onSubmit() {
        this.activeModal.close(this.bracket);
    }

    cancel() {
        this.activeModal.close();
    }

}
