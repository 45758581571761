import { Component, OnInit, Input } from '@angular/core';
import { EventService } from '../../services';
import { EventStandingsConfigItem } from '../../interfaces';

@Component({
    selector: 'bss-custom-standings-columns',
    templateUrl: './custom-standings-columns.component.html',
    styleUrls: ['./custom-standings-columns.component.scss']
})
export class CustomStandingsColumnsComponent implements OnInit {
    @Input() eventId: number;
    persistedColumns: EventStandingsConfigItem[];
    columns: EventStandingsConfigItem[];
    staging: EventStandingsConfigItem[];
    isLoading = false;
    standingsExample: StandingColumn[];

    constructor(private eventService: EventService) { }

    ngOnInit() {
        this.standingsExample = [
            {
                id: 1,
                team_name: 'Example Team 1',
                w: 3,
                l: 0,
                t: 1,
                gd: +16,
                gf: 28,
                ga: 12,
                pts: 3
            },
            {
                id: 2,
                team_name: 'Example Team 2',
                w: 2,
                l: 1,
                t: 1,
                gd: +13,
                gf: 25,
                ga: 12,
                pts: 2
            },
            {
                id: 3,
                team_name: 'Example Team 3',
                w: 2,
                l: 2,
                t: 0,
                gd: -7,
                gf: 16,
                ga: 23,
                pts: 1
            },
            {
                id: 4,
                team_name: 'Example Team 4',
                w: 1,
                l: 2,
                t: 0,
                gd: -9,
                gf: 10,
                ga: 19,
                pts: 0
            },
        ];
        this.refresh();
    }

    /**
     * Get the event standings configuration
     * @returns {Promise<void>}
     */
    async refresh() {
        this.isLoading = true;
        this.persistedColumns = await this.eventService.getSortedStandingsColumns(this.eventId);
        this.reset();
        this.isLoading = false;
    }


    /**
     * Move a column from position or to staging
     */
    move(column: EventStandingsConfigItem, position: null, toStaging = false) {
        if (toStaging) {
            if (this.staging.find(c => c.id === column.id)) {
                return;
            }
            const columnIndex = this.columns.findIndex(c => c.id === column.id);
            if (columnIndex > -1) {
                column.order = 0;
                this.staging.push(column);
                this.columns.splice(columnIndex, 1);
            }
        } else {
            // Move it if it already exists
            const index = this.columns.findIndex(c => c.id === column.id);
            if (index > -1) {
                if (index !== position) {
                    this.columns.splice(index, 1);
                    this.columns.splice(position, 0, column);
                }
            } else {
                const columnIndex = this.staging.findIndex(c => c.id === column.id);
                if (columnIndex > -1) {
                    this.columns.push(column);
                    this.staging.splice(columnIndex, 1);
                }
            }
            this.updateOrderProperties();
        }
    }

    /**
     * Update property order of columns. Set column.order = index + 1
     */
    updateOrderProperties() {
        this.columns.map((column: EventStandingsConfigItem, i: number) => {
            column.order = i + 1;
        });
    }

    /**
     * Retrieve remaining item placeholders
     * @returns {any[]}
     */
    getPlaceholders(items) {
        return Array(this.persistedColumns.length - items.length);
    }

    /**
     * Returns a sample of standings rows with the columns to display
     * @returns {any[][]}
     */
    get standings () {
        return this.standingsExample.map((s, i) => {
            const standings = [];
            standings.push({
                name: 'Team',
                value: s.team_name,
            });
            this.columns.forEach(c => {
                standings.push({
                    name: c.alias,
                    value: (c.alias === 'gd' && s[c.alias] > 0 ? '+' : '') + s[c.alias]
                });
            });
            return standings;
        });
    }

    /**
     * Persist changes through API request
     */
    save() {
        this.isLoading = true;
        this.eventService
        .saveStandingColumns(this.eventId, [...this.columns, ...this.staging])
        .subscribe(
            () => {
                this.isLoading = false;
                this.refresh();
            },
            err => {
                console.error(err);
                this.isLoading = false;
            },
        );
    }

    /**
     * Sent all columns to staging area
     */
    clear() {
        const columns = JSON.parse(JSON.stringify(this.persistedColumns));
        columns.map(c => c.order = 0);
        this.staging = columns;
        this.columns = [];
    }

    /**
<<<<<<< HEAD
     * Dsicard changes applied
=======
     * Discard changes applied
>>>>>>> 321e6bfcfeb9858793086309d54c006daf3677a2
     */
    reset() {
        const columns = JSON.parse(JSON.stringify(this.persistedColumns));
        this.columns = columns.filter(c => c.order > 0);
        this.staging = columns.filter(c => c.order === 0);
    }
}

interface StandingColumn {
    id: number;
    team_name: string;
    w?: number;
    l?: number;
    t?: number;
    gd?: number;
    gf?: number;
    ga?: number;
    pts?: number;
}
