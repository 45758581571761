import { Component, ElementRef, AfterViewInit, OnDestroy, DoCheck, Input, Output, EventEmitter, IterableDiffers, KeyValueDiffers } from '@angular/core';

import * as jQuery from 'jquery';
import 'fullcalendar';
import 'fullcalendar-scheduler';
import { OptionsInput, OptionsInputBase } from 'fullcalendar/src/types/input-types';

interface CalendarOptions extends OptionsInput, OptionsInputBase {
    agendaEventMinHeight: number;
    defaultTimedEventDuration: string;
    forceEventDuration: boolean;
    lang: string;
    rtl: boolean;
    theme: boolean;
}

@Component({
    selector: 'bss-scheduler',
    templateUrl: 'scheduler.component.html',
    styleUrls: ['scheduler.component.scss']
})
export class SchedulerComponent implements AfterViewInit, OnDestroy, DoCheck {
    // FullCalendar Options
    @Input() options = <CalendarOptions>{};
    @Input() print = false;
    @Input() styleClass: string;

    @Output() dayClick: EventEmitter<any> = new EventEmitter();
    @Output() externalDrop: EventEmitter<any> = new EventEmitter();
    @Output() eventClick: EventEmitter<any> = new EventEmitter();
    @Output() eventMouseover: EventEmitter<any> = new EventEmitter();
    @Output() eventMouseout: EventEmitter<any> = new EventEmitter();
    @Output() eventDelete: EventEmitter<any> = new EventEmitter();
    @Output() eventDragStart: EventEmitter<any> = new EventEmitter();
    @Output() eventDragStop: EventEmitter<any> = new EventEmitter();
    @Output() eventDrop: EventEmitter<any> = new EventEmitter();
    @Output() eventReceive: EventEmitter<any> = new EventEmitter();
    @Output() eventResizeStart: EventEmitter<any> = new EventEmitter();
    @Output() eventResizeStop: EventEmitter<any> = new EventEmitter();
    @Output() eventResize: EventEmitter<any> = new EventEmitter();
    @Output() eventAfterRender: EventEmitter<any> = new EventEmitter();
    @Output() eventAfterAllRender: EventEmitter<any> = new EventEmitter();
    @Output() eventLoading: EventEmitter<any> = new EventEmitter();
    initialized: boolean;
    stopNgOnChangesPropagation: boolean;
    iterableDiffer: any;
    kvDiffer: any;
    public schedule: any;

    constructor(private el: ElementRef, iterableDiffers: IterableDiffers, kvDiffers: KeyValueDiffers) {
        this.iterableDiffer = iterableDiffers.find([]).create(null);
        this.kvDiffer = kvDiffers.find({}).create();
        this.initialized = false;
    }

    ngAfterViewInit() {
        this.schedule = jQuery(this.el.nativeElement.children[0]);

        this.renderCalendar();
        this.initialized = true;
    }

    renderCalendar() {
        this.destroyCalendar();

        const opt = this.options;
        this.schedule.fullCalendar({
            // events: this.events,
            // resources: this.resources,
            agendaEventMinHeight: opt.agendaEventMinHeight,
            allDaySlot: opt.allDaySlot,
            allDayText: opt.allDayText,
            aspectRatio: opt.aspectRatio || 1.35,
            businessHours: opt.businessHours,
            contentHeight: opt.contentHeight,
            defaultDate: opt.defaultDate,
            defaultView: opt.defaultView || 'agendaDay',
            defaultTimedEventDuration: opt.defaultTimedEventDuration,
            dragOpacity: opt.dragOpacity || .75,
            dragRevertDuration: opt.dragRevertDuration !== undefined ? opt.dragRevertDuration : 500,
            dragScroll: opt.dragScroll || true,
            droppable: opt.droppable || true,
            editable: opt.editable,
            eventBackgroundColor: opt.eventBackgroundColor,
            eventConstraint: opt.eventConstraint,
            eventDurationEditable: opt.eventDurationEditable,
            eventLimit: opt.eventLimit,
            eventOverlap: opt.eventOverlap || false,
            eventRender: opt.eventRender,
            eventResourceEditable: opt.eventResourceEditable,
            eventStartEditable: opt.eventStartEditable,
            eventTextColor: opt.eventTextColor,
            fixedWeekCount: opt.fixedWeekCount,
            forceEventDuration: opt.forceEventDuration,
            header: opt.header,
            height: opt.height,
            hiddenDays: opt.hiddenDays,
            isRTL: opt.rtl,
            lang: opt.lang,
            minTime: opt.minTime || '00:00:00',
            maxTime: opt.maxTime || '24:00:00',
            nowIndicator: opt.nowIndicator,
            resourceAreaWidth: opt.resourceAreaWidth,
            resourceLabelText: opt.resourceLabelText,
            selectable: opt.selectable,
            schedulerLicenseKey: '0080338920-fcs-1519749115',
            scrollTime: opt.scrollTime || '08:00:00',
            slotEventOverlap: opt.slotEventOverlap || true,
            slotLabelFormat: opt.slotLabelFormat,
            slotDuration: opt.slotDuration || '00:30:00',
            slotLabelInterval: opt.slotLabelInterval,
            snapDuration: opt.snapDuration,
            theme: opt.theme || false,
            timezone: false,
            titleFormat: opt.titleFormat,
            weekends: opt.weekends,
            weekNumbers: opt.weekNumbers,
            events: (start, end, timezone, callback) => {
                callback(this.options.events);
            },
            resources: (callback) => {
                callback(this.options.resources);
            },
            dayClick: (date, jsEvent, view) => {
                this.dayClick.emit({
                    'date': date,
                    'jsEvent': jsEvent,
                    'view': view
                });
            },
            drop: (date, jsEvent, ui, resourceId) => {
                this.externalDrop.emit({
                    date,
                    jsEvent,
                    ui,
                    resourceId
                });
            },
            eventClick: opt.eventClick,
            eventMouseover: (calEvent, jsEvent, view) => {
                this.eventMouseover.emit({
                    'calEvent': calEvent,
                    'jsEvent': jsEvent,
                    'view': view
                });
            },
            eventMouseout: (calEvent, jsEvent, view) => {
                this.eventMouseover.emit({
                    'calEvent': calEvent,
                    'jsEvent': jsEvent,
                    'view': view
                });
            },
            eventDragStart: (event, jsEvent, ui, view) => {
                this.eventDragStart.emit({
                    event,
                    jsEvent,
                    view
                });
            },
            eventDragStop: (event, jsEvent, ui, view) => {
                this.eventDragStop.emit({
                    event,
                    jsEvent,
                    ui,
                    view
                });
            },
            eventDrop: (event, delta, revertFunc, jsEvent, ui, view) => {
                this.eventDrop.emit({
                    'event': event,
                    'delta': delta,
                    'revertFunc': revertFunc,
                    'jsEvent': jsEvent,
                    'view': view
                });
            },
            eventReceive: (event) => {
                this.eventReceive.emit(event);
            },
            eventResizeStart: (event, jsEvent, ui, view) => {
                this.eventResizeStart.emit({
                    'event': event,
                    'jsEvent': jsEvent,
                    'view': view
                });
            },
            eventResizeStop: (event, jsEvent, ui, view) => {
                this.eventResizeStop.emit({
                    'event': event,
                    'jsEvent': jsEvent,
                    'view': view
                });
            },
            eventResize: (event, delta, revertFunc, jsEvent, ui, view) => {
                this.eventResize.emit({
                    'event': event,
                    'delta': delta,
                    'revertFunc': revertFunc,
                    'jsEvent': jsEvent,
                    'view': view
                });
            },
            eventAfterRender: (event, element, view) => {
                this.eventAfterRender.emit({
                    'event': event,
                    'element': element,
                    'view': view
                });
            },
            eventAfterAllRender: (view) => {
                this.eventAfterAllRender.emit({
                    'view': view
                });
                this.schedule.find('.fc-view.fc-agendaDay-view.fc-agenda-view').css('min-width', this.schedule.find('.fc-resource-cell').length * 100);
            },
            loading: (isLoading, view) => {
                this.eventLoading.emit({
                    'isLoading': isLoading,
                    'view': view
                });
            }
        });
    }

    destroyCalendar() {
        jQuery(this.el.nativeElement.children[0]).fullCalendar('destroy');
    }

    ngDoCheck() {
        const eventChanges = this.iterableDiffer.diff(this.options.events);
        const optionChanges = this.kvDiffer.diff(this.options);

        if (this.schedule) {
            if (eventChanges) {
                this.schedule.fullCalendar('refetchEvents');
                this.schedule.fullCalendar('refetchResources');
            }

            if (optionChanges) {
                optionChanges.forEachChangedItem(r => {
                    if (r.key === 'resources') {
                        this.schedule.fullCalendar('refetchResources');
                    } else if (r.key === 'defaultDate') {
                        this.schedule.fullCalendar('gotoDate', r.currentValue);
                    } else if (r.key === 'defaultTimedEventDuration') { // this setting only updates with a full rerender
                        this.renderCalendar();
                    } else {
                        this.schedule.fullCalendar('option', r.key, r.currentValue);
                    }
                });
            }
        }
    }

    ngOnDestroy() {
        this.initialized = false;
        this.schedule = null;
    }
}


