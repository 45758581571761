import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import * as _ from 'lodash';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {
    EventService,
    BracketTemplateService,
} from '../../../scheduler/services';
import { Event, Division } from '../../../scheduler/interfaces';
import {
    NavbarService,
    PrintService,
    PrintDataService
} from '../../services';

@Component({
    selector: 'bss-public-event',
    templateUrl: './event.page.html',
    styleUrls: ['./event.page.scss']
})

export class EventPage implements OnInit {
    eventId: number;
    event = <Event>{};
    isEventReady = false;
    divisions: Division[] = [];
    closeResult: string;
    pdfUri: any = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8z/C/HgAGgwJ/lK3Q6wAAAABJRU5ErkJggg==';
    pdfUris: any[] = [];
    data: any[];
    standings: any[];

    constructor(private route: ActivatedRoute,
        private router: Router,
        public navbarService: NavbarService,
        private eventService: EventService,
        private templateBracketService: BracketTemplateService,
        public modalService: NgbModal,
        private printService: PrintService,
        private _printDataService: PrintDataService,
        private datePipe: DatePipe) {
        this._printDataService.scheduleData.subscribe((data) => {
            this.data = data;
        });
        this._printDataService.standingsData.subscribe((data) => {
            this.standings = data;
        });
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.isEventReady = true;
            this.eventId = params.eventId;
            const observable = this.eventService.getEvent(this.eventId);
            observable.subscribe(
                (data: any) => {
                    this.event = data;
                    this.isEventReady = false;
                    this.navbarService.showNextUp = this.event.operator_id !== '14';
                }
            );
            this.eventService.getPublicDivisions(this.eventId)
                .subscribe(
                    data => this.divisions = data.divisions,
                    (err) => console.error(err)
                );
            return observable;
        });
    }

    get inDivisionChild() {
        return /schedules\/[\d]+\/divisions\/[\d]+\/[\w]+/.test(this.router.url);
    }

    get currentDivision() {
        const params = this.router.url.match(/divisions\/[\d]+/);
        const divisionId = params ? params[0].split('/')[1] : null;
        return this.divisions.find(division => division.id === parseInt(divisionId, 10));
    }

    getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }


    getPoolStandingsArray(data: any[], blank) {
        const longerIndex = (data[0].teams.length > data[1].teams.length) ? '0' : '1';
        const teams = _.map(data[longerIndex].teams, (t, i) => {
            const t1 = (data[0] && data[0].teams) ? data[0].teams : null;
            const t2 = (data[1] && data[1].teams) ? data[1].teams : null;

            const partOne = (t1 && data[0].teams[i])
                ? [
                    {
                        text: data[0].teams[i].name,
                        border: [true, true, false, true]
                    },
                    {
                        text: data[0].teams[i].wins || 0,
                        border: [false, true, false, true]
                    },
                    {
                        text: data[0].teams[i].losses || 0,
                        border: [false, true, false, true]
                    },
                    {
                        text: data[0].teams[i].pool_rank || ' - ',
                        border: [false, true, true, true]
                    },
                    blank
                ]
                : [
                    { text: '', border: [false, false, false, false] },
                    { text: '', border: [false, false, false, false] },
                    { text: '', border: [false, false, false, false] },
                    { text: '', border: [false, false, false, false] },
                    blank
                ];

            const partTwo = (t2 && data[1].teams[i])
                ? [
                    {
                        text: data[1].teams[i].name,
                        border: [true, true, false, true]
                    },
                    {
                        text: data[1].teams[i].wins || 0,
                        border: [false, true, false, true]
                    },
                    {
                        text: data[1].teams[i].losses || 0,
                        border: [false, true, false, true]
                    },
                    {
                        text: data[1].teams[i].pool_rank || ' - ',
                        border: [false, true, true, true]
                    }
                ]
                : [
                    { text: '', border: [false, false, false, false] },
                    { text: '', border: [false, false, false, false] },
                    { text: '', border: [false, false, false, false] },
                    { text: '', border: [false, false, false, false] }
                ];

            return _.concat(
                partOne,
                partTwo);
        });

        return teams;

    }

    getSinglePoolStandingsArray(data: any[]) {

        const teams = _.map(data[0].teams, (t, i) => {

            const partOne = [
                { text: `${t['name']}`, border: [true, true, false, true] },
                { text: `${t['wins'] || '0'}`, border: [false, true, false, true] },
                { text: `${t['losses'] || '0'}`, border: [false, true, false, true] },
                { text: `${t['pool_rank'] || ' - '}`, border: [false, true, true, true] }
            ];

            return partOne;
        });

        return teams;
    }

    getSingleGameArray(data: any[]) {

        const games = _.map(data[0].games, (g, i) => {
            const home = g.home_seed && g.home_seed.team;
            const away = g.away_seed && g.away_seed.team;

            const partOne = [
                { text: g['game_number'], border: [true, true, false, true] },
                { text: home && home.name, border: [false, true, false, true] },
                { text: `${(home && home.score) || ''}-${(away && away.score) || ''}`, border: [false, true, false, true] },
                { text: away && away.name, border: [false, true, false, true] },
                { text: this.datePipe.transform(g['start_date'], 'mediumDate', 'UTC') || 'TBD', border: [false, true, false, true] },
                { text: this.datePipe.transform(g['start_date'], 'shortTime', 'UTC') || 'TBD', border: [false, true, false, true] },
                { text: (g['location'] || 'TBD'), border: [false, true, true, true] }
            ];

            return partOne;
        });

        return games;

    }

    getGameArray(data: any[], blank) {
        const longerIndex = (data[0].games.length > data[1].games.length) ? '0' : '1';
        const games = _.map(data[longerIndex].games, (g, i) => {
            const g1 = (data[0] && data[0].games) ? data[0].games : null;
            const g2 = (data[1] && data[1].games) ? data[1].games : null;
            const game1 = data[0].games[i];
            const game2 = data[1].games[i];

            const partOne = (g1 && game1)
                ? [
                    {
                        text: game1.game_number,
                        border: [true, true, false, true]
                    },
                    {
                        text: game1.home_seed.team.name,
                        border: [false, true, false, true]
                    },
                    {
                        text: `${game1.home_seed.team.score || ''}-${game1.away_seed.team.score || ''}`,
                        border: [false, true, false, true]
                    },
                    {
                        text: game1.away_seed.team.name,
                        border: [false, true, false, true]
                    },
                    {
                        text: this.datePipe.transform(game1.start_date, 'mediumDate', 'UTC') || 'TBD',
                        border: [false, true, false, true]
                    },
                    {
                        text: this.datePipe.transform(game1.start_date, 'shortTime', 'UTC') || 'TBD',
                        border: [false, true, false, true]
                    },
                    {
                        text: (game1.location || 'TBD'),
                        border: [false, true, true, true]
                    },
                    blank
                ]
                : [
                    { text: '', border: [false, false, false, false] },
                    { text: '', border: [false, false, false, false] },
                    { text: '', border: [false, false, false, false] },
                    { text: '', border: [false, false, false, false] },
                    { text: '', border: [false, false, false, false] },
                    { text: '', border: [false, false, false, false] },
                    { text: '', border: [false, false, false, false] },
                    blank
                ];

            const partTwo = (g2 && game2)
                ? [
                    {
                        text: game2.game_number,
                        border: [true, true, false, true]
                    },
                    {
                        text: game2.home_seed.team.name,
                        border: [false, true, false, true]
                    },
                    {
                        text: `${game2.home_seed.team.score || ''}-${game2.away_seed.team.score || ''}`,
                        border: [false, true, false, true]
                    },
                    {
                        text: game2.away_seed.team.name,
                        border: [false, true, false, true]
                    },
                    {
                        text: this.datePipe.transform(game2.start_date, 'mediumDate', 'UTC') || 'TBD',
                        border: [false, true, false, true]
                    },
                    {
                        text: this.datePipe.transform(game2.start_date, 'shortTime', 'UTC') || 'TBD',
                        border: [false, true, false, true]
                    },
                    {
                        text: (game2.location || 'TBD'),
                        border: [false, true, true, true]
                    }
                ]
                : [
                    { text: '', border: [false, false, false, false] },
                    { text: '', border: [false, false, false, false] },
                    { text: '', border: [false, false, false, false] },
                    { text: '', border: [false, false, false, false] },
                    { text: '', border: [false, false, false, false] },
                    { text: '', border: [false, false, false, false] },
                    { text: '', border: [false, false, false, false] }
                ];

            return _.concat(
                partOne,
                partTwo);
        });

        return games;

    }

    getPayload(content): any {

        const blank = { text: '', border: [false, false, false, false] };
        const startDate = this.datePipe.transform(this.event.start_date, 'mediumDate', 'UTC');
        const endDate = this.datePipe.transform(this.event.end_date, 'mediumDate', 'UTC');

        const headerContent = [
            {
                style: 'tableNoBorder',
                table: {
                    widths: [200, '*', 200],
                    body: [
                        [
                            {
                                image: 'logo',
                                width: 100,
                                border: [false, false, false, false]
                            },
                            {
                                text: [
                                    `${this.event.name} \n`,
                                    `${this.event.location} \n`,
                                    `${startDate.substr(0, startDate.length - 6)} - ${endDate.substr(endDate.length - 8, 8)} \n`,
                                    `${this.currentDivision.name}`
                                ],
                                border: [false, false, false, false],
                                alignment: 'center'

                            },
                            {
                                text: '',
                                width: 150,
                                border: [false, false, false, false]
                            }
                        ]
                    ]
                }
            },
            blank
        ];

        const gh = [
            [
                { text: '', border: [true, true, false, false] },
                { text: 'Home', border: [false, true, false, false] },
                { text: 'Score', border: [false, true, false, false] },
                { text: 'Away', border: [false, true, false, false] },
                { text: 'Date', border: [false, true, false, false] },
                { text: 'Time', border: [false, true, false, false] },
                { text: 'Location', border: [false, true, true, false] },
                blank,
                { text: '', border: [true, true, false, false] },
                { text: 'Home', border: [false, true, false, false] },
                { text: 'Score', border: [false, true, false, false] },
                { text: 'Away', border: [false, true, false, false] },
                { text: 'Date', border: [false, true, false, false] },
                { text: 'Time', border: [false, true, false, false] },
                { text: 'Location', border: [false, true, true, false] }
            ]];

        const gh1 = [
            [
                { text: '', border: [true, true, false, false] },
                { text: 'Home', border: [false, true, false, false] },
                { text: 'Score', border: [false, true, false, false] },
                { text: 'Away', border: [false, true, false, false] },
                { text: 'Date', border: [false, true, false, false] },
                { text: 'Time', border: [false, true, false, false] },
                { text: 'Location', border: [false, true, true, false] }
            ]];


        const tsh = [
            [
                { text: 'Team', border: [true, true, false, false] },
                { text: 'W', border: [false, true, false, false] },
                { text: 'L', border: [false, true, false, false] },
                { text: 'Seed', border: [false, true, true, false] },
                blank,
                { text: 'Team', border: [true, true, false, false] },
                { text: 'W', border: [false, true, false, false] },
                { text: 'L', border: [false, true, false, false] },
                { text: 'Seed', border: [false, true, true, false] }
            ]];


        const tsh1 = [
            [
                { text: 'Team', border: [true, true, false, false] },
                { text: 'W', border: [false, true, false, false] },
                { text: 'L', border: [false, true, false, false] },
                { text: 'Seed', border: [false, true, true, false] }
            ]];

        const standingsData = _.chunk(this.standings, 2);
        const bodyData: any[] =
            _.map(_.chunk(this.data, 2), (v, i) => {
                return [{
                    table: {
                        widths: [200, 175, 200],
                        body: (v.length > 1)
                            ? [[
                                {
                                    text: `Pool ${v[0].name} Schedule`,
                                    border: [false, false, false, false],
                                    fontSize: 10
                                },
                                {
                                    text: '',
                                    border: [false, false, false, false],
                                    fontSize: 10
                                },
                                {
                                    text: ((v[1]) ? `Pool ${(v[1].name)} Schedule` : ''),
                                    border: [false, false, false, false],
                                    fontSize: 10
                                }]]
                            : [[
                                {
                                    text: `Pool ${v[0].name} Schedule`,
                                    border: [false, false, false, false],
                                    fontSize: 10
                                },
                                {
                                    text: '',
                                    border: [false, false, false, false],
                                    fontSize: 10
                                }
                            ]]
                    }
                }, {
                    style: 'tableExample',
                    table: {
                        headerRows: 1,
                        dontBreakRows: true,
                        keepWithHeaderRows: 1,
                        widths: [10, 84, 21, 84, 50, 35, 35, 1, 10, 84, 21, 84, 50, 35, 35],
                        body: (v.length > 1)
                            ? _.concat(gh, this.getGameArray(v, blank))
                            : _.concat(gh1, this.getSingleGameArray(v))
                    }
                }, {
                    text: '',
                    style: 'spacer'
                }, {
                    table: {
                        widths: [200, 175, 200],
                        body: (this.standings.length > 1)
                            ? [[{ text: `Pool ${v[0].name} Standings`, border: [false, false, false, false], fontSize: 10 }, {
                                text: '',
                                border: [false, false, false, false],
                                fontSize: 10
                            }, {
                                text: ((v[1] && v[1].name) ? `Pool ${v[1].name} Standings` : ''),
                                border: [false, false, false, false],
                                fontSize: 10
                            }]]
                            : [[{ text: `Pool ${v[0].name} Standings`, border: [false, false, false, false], fontSize: 10 }, {
                                text: '',
                                border: [false, false, false, false],
                                fontSize: 10
                            }]],
                    }
                }, {
                    style: 'tableExample',
                    table: {
                        headerRows: 1,
                        dontBreakRows: true,
                        keepWithHeaderRows: 1,
                        widths: [307, 10, 10, 20, 1, 307, 10, 10, 20],
                        body: (v.length > 1)
                            ? _.concat(tsh, this.getPoolStandingsArray(standingsData[i], blank))
                            : _.concat(tsh1, this.getSinglePoolStandingsArray(standingsData[i]))
                    },
                    pageBreak: (i % 2 === 1 && i !== v.length + 1) ? 'after' : null
                }, {
                    text: '',
                    style: 'spacer'
                }];
            });

        const contentData = _.concat(headerContent, bodyData);

        const payload = {
            content: contentData,
            // pageBreakBefore: function(currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
            //     //console.log(currentNode.id);
            //     return true;
            //     //return currentNode.headlineLevel === 1 && followingNodesOnPage.length === 0;
            //   //check if signature part is completely on the last page, add pagebreak if not
            //   if (currentNode.id === 'signature' && (currentNode.pageNumbers.length != 1 || currentNode.pageNumbers[0] != currentNode.pages)) {
            //     return true;
            //   }
            //   //check if last paragraph is entirely on a single page, add pagebreak if not
            //   else if (currentNode.id === 'closingParagraph' && currentNode.pageNumbers.length != 1) {
            //     return true;
            //   }
            //   return false;
            // },
            styles: {
                header: {
                    fontSize: 8,
                    bold: true,
                    margin: [0, 0, 0, 10],
                    background: 'green'
                },
                spacer: {
                    fontSize: 8,
                    bold: true,
                    margin: [0, 0, 0, 10]
                },
                subheader: {
                    fontSize: 10,
                    bold: true,
                    margin: [0, 10, 0, 5]
                },
                tableNoBorder: {
                    border: [false, false, false, false]
                },
                tableExample: {
                    margin: [0, 0, 0, 0],
                    fontSize: 8
                },
                tableHeader: {
                    bold: true,
                    fontSize: 8,
                    color: 'black'
                }
            },
            defaultStyle: {
                // alignment: 'justify'
            },
            // a string or { width: number, height: number }
            pageSize: 'LETTER',

            // by default we use portrait, you can change it to landscape if you wish
            pageOrientation: 'landscape',

            // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
            pageMargins: [10, 10, 20, 20],

            images: {
                logo: this.event.image_url
            },
            altLogoText: this.event.name,

        };

        return payload;

    }

    printSchedule(content) {

        const payload = this.getPayload(content);
        const result = this.printService.create(payload);

        result.subscribe((data) => {
            this.pdfUri = 'data:application/pdf;base64,' + data;
            this.modalService.open(content, { windowClass: 'xxl-modal' }).result.then((response) => {
                this.closeResult = `Closed with: ${response}`;
            }, (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });

        }, (err) => console.log(err));
    }

    printBrackets(content) {
        const body = {
            eventName: this.event.name,
            eventLocation: this.event.location,
            eventStartDate: this.event.start_date,
            eventEndDate: this.event.end_date,
            divisionName: this.currentDivision.name
        };
        this.templateBracketService.printBracket(this.currentDivision.template_id, body)
            .subscribe(
                data => {
                    this.pdfUri = 'data:application/pdf;base64,' + data;
                    this.modalService.open(content, { windowClass: 'xxl-modal' }).result.then((response) => {
                        this.closeResult = `Closed with: ${response}`;
                    }, (reason) => {
                        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                    });
                }
            );
    }

    printBoth(content) {
        const body = {
            eventName: this.event.name,
            eventLocation: this.event.location,
            eventStartDate: this.event.start_date,
            eventEndDate: this.event.end_date,
            divisionName: this.currentDivision.name
        };

        const payload = this.getPayload(content);
        const resultSchedule = this.printService.create(payload);
        const resultBrackets = this.templateBracketService.printBracket(this.currentDivision.template_id, body);

        forkJoin([resultSchedule, resultBrackets]).subscribe(results => {
            const scheduleUrl = 'data:application/pdf;base64,' + results[0];
            const bracketsUrl = 'data:application/pdf;base64,' + results[1];

            this.pdfUris.push(scheduleUrl);
            this.pdfUris.push(bracketsUrl);

            this.modalService.open(content, { windowClass: 'xxl-modal-full' }).result.then((response) => {
                this.closeResult = `Closed with: ${response}`;
            }, (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });

        });

    }

}
