import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Division } from '../../interfaces';

interface DivisionView {
    id?: number;
    name: string;
    selected: boolean;
}

@Component({
    selector: 'bss-grid-settings-block',
    templateUrl: './grid-settings-block.component.html',
    styleUrls: ['./grid-settings-block.component.scss']
})
export class GridSettingsBlockComponent implements OnInit {
    @Input() allDivisions: Division[];
    @Input() block = null;
    @Output() create = new EventEmitter();
    @Output() drop = new EventEmitter();
    @Output() switchToEditMode = new EventEmitter();
    @Output() cancel = new EventEmitter();
    @Output() reset = new EventEmitter();
    divisions: DivisionView[] = [];
    hasCreateForm = true;

    @Input()
    set isEditing(value: boolean) {
        this.hasCreateForm = value;
        this.ngOnInit();
    }

    ngOnInit() {
        this.divisions = [];
        this.allDivisions.forEach(div => {
            this.divisions.push({
                id: div.id,
                name: div.name,
                selected: !!this.block.divisions.find(selectedDiv => selectedDiv.id === div.id),
            });
        });
    }

    onSubmit(form) {
        const divisions = this.divisions
        .filter(div => div.selected)
        .map(div => div.id);
        this.create.emit({
            id: this.block.id,
            game_length: form.game_length,
            min_break: form.min_break,
            max_break: form.max_break,
            max_daily_games: form.max_daily_games,
            // home_guarantee: form.home_guarantee,
            home_guarantee: 0,
            divisions: divisions
        });
    }

    onDelete() {
        this.drop.emit(this.block.id);
    }

    formIsValid(form): boolean {
        if (form.game_length < 1) { return false; }
        if (form.min_break < 1) { return false; }
        if (form.max_break < form.min_break) { return false; }
        if (form.max_daily_games < 1) { return false; }
        // if (form.home_guarantee === null || form.home_guarantee < 0) { return false; }
        if (this.divisions.filter(div => div.selected).length === 0) { return false; }
        return true;
    }

    onEdit() {
        this.switchToEditMode.emit(this.block);
    }

    onCancel() {
        this.cancel.emit(this.block);
    }

    onReset() {
        this.reset.emit(this.block);
    }
}
