import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { BracketTemplateDataService, PrintDataService } from '../../services';
import {
    Bracket,
    Division,
    Standings,
    TemplateBrackets
} from '../../../scheduler/interfaces';
import {
    BracketTemplateService,
    DivisionService,
    PoolService
} from '../../../scheduler/services';

@Component({
    selector: 'bss-public-brackets',
    templateUrl: './brackets.page.html',
    styleUrls: ['./brackets.page.scss']
})
export class BracketsPage implements OnInit, OnDestroy {

    division = <Division>{};
    divisionId: string;
    template: TemplateBrackets;
    brackets: Bracket[];
    isLoading = false;
    paramSubscriber: any;
    view = 'tree';
    public standings: StandingsView[];

    constructor(
        private poolService: PoolService,
        private _route: ActivatedRoute,
        private _router: Router,
        private _divisionService: DivisionService,
        private _bracketTemplateService: BracketTemplateService,
        public _bracketTemplateDataService: BracketTemplateDataService,
        private _printDataService: PrintDataService) {
    }

    ngOnInit() {
        this.paramSubscriber = this._route.parent.paramMap.subscribe((params: ParamMap) => {
            this.divisionId = params.get('divisionId');
            this.refresh();
            this.setView();
        });
    }

    ngOnDestroy() {
        this.paramSubscriber.unsubscribe();
    }

    /**
     * [refresh description]
     * @return {void}
     */
    async refresh() {
        this.isLoading = true;
        try {
            this.division = await this._divisionService.getById(this.divisionId).toPromise() as Division;
            if (this.division.template_id) {
                this.template = await this._bracketTemplateService.getBracketById(this.division.template_id).toPromise() as TemplateBrackets;
                this.brackets = this.template.brackets || [];
                this._bracketTemplateDataService.selectedBracket = this.brackets[0];
                this._bracketTemplateDataService.setBracket(this.brackets[0]);
                const scheduleData = await this.poolService.getSchedules(this.division.template_id).toPromise();
                this._printDataService.setScheduleData(scheduleData.schedules);
                const data = await this._divisionService.getDivisionStandings(Number(this.divisionId)).toPromise();
                this.standings = data.map((pool: StandingsView) => {
                    if (pool.seeds.length === 0) {
                        return;
                    }
                    const duplicate = (() => {
                        const seen = [];
                        for (let i = 0; i < pool.seeds.length; i++) {
                            if (seen.indexOf(pool.seeds[i].pool_rank) > -1) {
                                return true;
                            }
                            seen.push(pool.seeds[i].pool_rank);
                        }
                        return false;
                    })();
                    if (duplicate) {
                        pool.editableSeeds = true;
                    }
                    return pool;
                });

                this._printDataService.setStandingsData(this.standings);
            }
        } catch (err) {
            console.error(err);
        }
        this.isLoading = false;
    }

    /***
     * [onChangeBracket description]
     * @param $event
     * @param bracket
     */
    onChangeBracket($event: EventTarget, bracket: Bracket): void {
        this._bracketTemplateDataService.setBracket(bracket);
    }

    /**
     * [onChangeView description]
     * @param {EventTarget} $event [description]
     * @return {void}
     */
    onChangeView($event: any): void {
        const value = $event.target.value;
        this.view = value;
        this._router.navigate([value], { relativeTo: this._route });
    }

    setView() {
        this.view = this._router.url.indexOf('tree') !== -1 ? 'tree' : 'list';
    }

    /**
     * [trackByUUID description]
     * @param {number} index
     * @param {*} item
     * @return {string}
     */
    trackByUUID(index: number, item: any): string {
        return item.uuid;
    }

}

interface StandingsView extends Standings {
    editableSeeds?: boolean;
    invalidSeed?: boolean;
}
