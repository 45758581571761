import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Division, Standings } from '../../interfaces';
import { DataService } from '../common/data.service';
import { environment as env } from '../../../../environments/environment';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class DivisionService extends DataService {
    constructor(http: HttpClient, private authService: AuthService) {
        super(`${env.API_BASE_URL}/divisions`, http, authService);
    }

    /**
     * Get a list of divisions
     * @returns {Observable<Division[]>}
     */
    getDivisions(options = {}): Observable<Division[]> {
        return this.getAll(options)
        .map((data: any) => data.divisions);
    }

    /**
     * Get a list of standings that belongs to a division
     * @param {number} divisionId
     * @returns {Observable<Standings[]>}
     */
    getDivisionStandings(divisionId: number): Observable<Standings[]> {
        const url = `${env.API_BASE_URL}/divisions/${divisionId}/standings`;
        const headers = new HttpHeaders(
            { 'Authorization': `Bearer ${this.authService.getJWT()}` }
        );
        return this.http.get(url, { headers })
        .map((response: any) => {
            return response.pools;
        })
        .catch(this.handleError);
    }

    getStandingsConfig(divisionId: number): Observable<Standings[]> {
        const url = `${env.API_BASE_URL}/divisions/${divisionId}/standings`;
        const headers = new HttpHeaders(
            { 'Authorization': `Bearer ${this.authService.getJWT()}` }
        );
        return this.http.get(url, { headers })
        .map((response: any) => {
            return response.standing_config;
        })
        .catch(this.handleError);
    }

    /**
     * Change name of a division
     * @param {number} divisionId
     * @param {string} name
     * @returns {Observable<any>}
     */
    setName(divisionId: number, name: string): Observable<any> {
        const headers = new HttpHeaders(
            {'Authorization': `Bearer ${this.authService.getJWT()}`}
        );
        return this.http
        .put(`${this.url}/${divisionId}/name`, { name }, { headers });
    }

    /**
     * Update the abbreviation of a division
     * @param {number} divisionId
     * @param {string} abbreviation
     * @returns {Observable<Object>}
     */
    setAbbreviation(divisionId: number, abbreviation: string) {
        const headers = new HttpHeaders(
            {'Authorization': `Bearer ${this.authService.getJWT()}`}
        );
        return this.http
        .put(`${this.url}/${divisionId}/abbreviation`, { abbreviation }, { headers });
    }

    /**
     * Move all teams from source division to a target division.
     * If transaction succeed, remove source division
     * @param {number} divisionId
     * @param targetDivisionId
     * @returns {Observable<any>}
     */
    combineWith(divisionId: number, targetDivisionId): Observable<any> {
        const headers = new HttpHeaders(
            {'Authorization': `Bearer ${this.authService.getJWT()}`}
        );
        return this.http
        .patch(`${this.url}/${divisionId}/combine/${targetDivisionId}`, { name }, { headers });
    }

    /**
     * Bulk move a selection of teams to a division
     * @param {number} divisionId
     * @param {number[]} teamIds
     * @returns {Observable<Object>}
     */
    moveTeams(divisionId: number, teamIds: number[]) {
        const headers = new HttpHeaders(
            {'Authorization': `Bearer ${this.authService.getJWT()}`}
        );
        return this.http
        .patch(`${this.url}/${divisionId}/move`, { teams: teamIds }, { headers });
    }

    /**
     * Drop team from a division
     * @param {number} divisionId
     * @param teamId
     * @returns {Observable<Object>}
     */
    dropTeam(divisionId: number, teamId) {
        const headers = new HttpHeaders(
            {'Authorization': `Bearer ${this.authService.getJWT()}`}
        );
        return this.http
        .delete(`${this.url}/${divisionId}/teams/${teamId}/drop`, { headers });
    }

    /**
     * Replace a team with another
     * @param {number} divisionId
     * @param {number} sourceTeamId
     * @param targetTeamId
     * @returns {Observable<Object>}
     */
    replaceTeam(divisionId: number, sourceTeamId: number, targetTeamId) {
        const headers = new HttpHeaders(
            {'Authorization': `Bearer ${this.authService.getJWT()}`}
        );
        return this.http
        .post(
            `${this.url}/${divisionId}/teams/${sourceTeamId}/replace`,
            { teamId: targetTeamId },
            { headers }
        );
    }

    /**
     * Retrieve the seeds of a division
     * @param {number} divisionId
     * @returns {Observable<any[]>}
     */
    getSeeds(divisionId: number): Observable<any[]> {
        return this.http
        .get(`${this.url}/${divisionId}/seeds`)
        .map((response: { seeds: any[] }) => response.seeds);
    }

    /**
     * Update the seeds of a division
     * @param {number} divisionId
     * @param {any[]} teams
     * @returns {Observable<any | any>}
     */
    setSeeds(divisionId: number, teams: any[]) {
        const headers = new HttpHeaders(
            {'Authorization': `Bearer ${this.auth.getJWT()}`}
        );
        return this.http
        .put(`${this.url}/${divisionId}/seeds`, { teams: teams }, { headers });
    }

    /**
     * Update pool_rank of  each provided seed
     * @param {number} divisionId
     * @param {{id: number; pool_rank: number}[]} seeds
     * @returns {Observable<Object>}
     */
    updateSeedsPoolRank(divisionId: number, seeds: {id: number, pool_rank: number}[]) {
        const headers = new HttpHeaders(
            {'Authorization': `Bearer ${this.auth.getJWT()}`}
        );
        return this.http
        .patch(`${this.url}/${divisionId}/seeds`, { seeds: seeds }, { headers });
    }

    /**
     * Assign a Template to a Division
     * @param {number} divisionId
     * @param {number} templateId
     * @returns {Observable<Object>}
     */
    assignTemplate(divisionId: number, templateId: number) {
        const headers = new HttpHeaders(
            {'Authorization': `Bearer ${this.auth.getJWT()}`}
        );
        return this.http
        .post(
            `${this.url}/${divisionId}/templates`,
            { template_id: templateId},
            { headers }
        );
    }

    /**
     * Unassign any assigned Template from a Division
     * @param {number} divisionId
     */
    unassignTemplate(divisionId: number) {
        const headers = new HttpHeaders(
            {'Authorization': `Bearer ${this.auth.getJWT()}`}
        );
        return this.http
        .delete(
            `${this.url}/${divisionId}/templates`,
            { headers }
        );
    }

    publish(eventId: number, publish: boolean, divisions?: Array<number>) {
        const headers = new HttpHeaders(
            {'Authorization': `Bearer ${this.auth.getJWT()}`}
        );
        const url = `${env.API_BASE_URL}/events/${eventId}/publishall`;
        return this.http
        .put(url, {
            active: publish,
            divisions: divisions
        }, {
            headers
        });
    }
}
