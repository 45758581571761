import { DataService } from '../common/data.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as env } from '../../../../environments/environment';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class TemplateService extends DataService {
    constructor(http: HttpClient, auth: AuthService) {
        super(`${env.API_BASE_URL}/templates`, http, auth);
    }

    copy(resource) {
        const id = resource.id ? resource.id : resource.template_id;
        if (!resource.team_count) {
            resource.team_count = resource.teamCount ? resource.teamCount : 4;
        }
        const url = `${this.url}/${id}/copy`;
        return this.http.post(url, resource, {
            headers: new HttpHeaders().set('Authorization', `Bearer ${this.auth.getJWT()}`),
        })
            .map(response => response)
            .catch(this.handleError);
    }
}
