import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import {
    Resolve,
    RouterStateSnapshot,
    ActivatedRouteSnapshot
} from '@angular/router';
import { DivisionService } from '../services/division/division.service';

@Injectable()
export class DivisionResolver implements Resolve<Observable<any>> {

    constructor(private divisionService: DivisionService) {}

    resolve(route: ActivatedRouteSnapshot,
            state: RouterStateSnapshot): Observable<any[]> {
        return this.divisionService.getById(route.params.divisionId) as any;
    }
}
