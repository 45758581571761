import { Injectable, EventEmitter, Output } from '@angular/core';

@Injectable()
export class FiltersBarService {
    filtersEmitter: EventEmitter<any> = new EventEmitter();
    dataEmitters: EventEmitter<any> = new EventEmitter();

    public get filters () {
        return this.filtersEmitter;
    }

    constructor() { }

    setFilters(filters) {
        this.filtersEmitter.emit(filters);
    }

    clearFilters() {
        this.filtersEmitter.emit(null);
    }

    update(type, value) {
        this.dataEmitters.emit({ type, value });
    }
}
