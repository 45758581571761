import {
    Component,
    Input,
    Output,
    OnChanges,
    EventEmitter,
    ChangeDetectionStrategy,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ScoringConfig } from '../../interfaces';

@Component({
    selector: 'bss-scorer',
    templateUrl: './scorer.component.html',
    styleUrls: ['./scorer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScorerComponent implements OnChanges {
    @Input() isEditable = true;
    @Input() team1: any;
    @Input() team2: any;
    @Input() config: ScoringConfig;
    @Output() scoreGame: EventEmitter<any> = new EventEmitter();
    public editingMode = false;
    scoresForm: FormGroup;

    constructor(private fb: FormBuilder) {
        this.scoresForm = this.fb.group({
            team1: this.fb.group({
                score: [0, Validators.required],
                penalty_kicks: [0],
                yellow_cards: [0],
                red_cards: [0],
            }),
            team2: this.fb.group({
                score: [0, Validators.required],
                penalty_kicks: [0],
                yellow_cards: [0],
                red_cards: [0],
            }),
        });
    }

    ngOnChanges() {
        this.reset();
    }

    reset() {
        if (this.team1 && this.team2) {
            this.scoresForm.controls.team1.setValue({
                score: this.team1.score,
                penalty_kicks: this.team1.penalty_kicks,
                yellow_cards: this.team1.yellow_cards,
                red_cards: this.team1.red_cards
            });

            this.scoresForm.controls.team2.setValue({
                score: this.team2.score,
                penalty_kicks: this.team2.penalty_kicks,
                yellow_cards: this.team2.yellow_cards,
                red_cards: this.team2.red_cards
            });
        }
    }

    onSave(): void {
        this.scoreGame.emit(this.scoresForm.value);
        this.editingMode = false;
    }

    onCancel(): void {
        this.editingMode = false;
        this.reset();
    }

    /**
     * Handle keydown events on input field and delegate to specific methods
     * @param $event
     */
    onKeydown($event): void {
        if ($event.keyCode === 13) { // Enter
            this.onSave();
        } else if ($event.keyCode === 27) { // Escape
            this.onCancel();
        }
    }

    /**
     * Returns true if the teams has been scored
     * @returns {boolean}
     */
    get isScored() {
        return this.team1.score > 0 && this.team2.score > 0;
    }

    /**
     * This property is true if penalty kicks values should be display in the header of the component
     * @returns {any}
     */
    get showPK() {
        return this.isScored && this.config.penalty_kicks && (this.team1.penalty_kicks || this.team2.penalty_kicks);
    }
}
