import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TemplateService } from '../../services/template/template.service';

@Component({
    selector: 'bss-copy-template',
    templateUrl: './copy-template.component.html',
    styleUrls: ['./copy-template.component.scss']
})
export class CopyTemplateComponent {
    @Input() template;
    @Input() folders;
    isNewFolderMode = false;

    constructor(
        public modal: NgbActiveModal,
        private templates: TemplateService) {
    }

    async onSubmit() {
        try {
            const response = await this.templates.copy({
                ...this.template,
                template_name: this.template.name,
            }).toPromise();
            this.modal.close({response, template: this.template});
        } catch (err) {
            console.error(err);
        }
    }
}
