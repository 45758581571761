import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {Subject} from 'rxjs/Subject';

@Injectable()
export class PrintDataService {
    scheduleData: Observable<any>;
    standingsData: Observable<any>;
    private scheduleDataSubject = new Subject<any>();
    private standingsDataSubject = new Subject<any>();

    constructor() {
        this.scheduleData = this.scheduleDataSubject.asObservable();
        this.standingsData = this.standingsDataSubject.asObservable();
    }

    setScheduleData(data) {
        this.scheduleDataSubject.next(data);
    }

    setStandingsData(data) {
        this.standingsDataSubject.next(data);
    }

}
