import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TemplatePool, TemplatePoolGroup } from '../../interfaces';
import { DataService } from '../common/data.service';
import { environment as env } from '../../../../environments/environment';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class TemplatePoolService extends DataService {

    private pools: any[];
    private groups: TemplatePoolGroup[];

    constructor(http: HttpClient, auth: AuthService) {
        super(`${env.API_BASE_URL}/templates`, http, auth);
        this.pools = [
            {
                id: 1,
                name: 'A',
                group: { name: 'Blue'},
                visible: true,
                seeds: [
                    1,
                    8,
                    9,
                ]
            },
            {
                id: 2,
                name: 'B',
                group: { name: 'Blue'},
                visible: true,
                seeds: [
                    2,
                    7,
                    10,
                ]
            },
            {
                id: 3,
                name: 'C',
                group: { name: 'Blue'},
                visible: true,
                seeds: [
                    3,
                    6,
                    11,
                ]
            },
            {
                id: 4,
                name: 'D',
                group: { name: 'Blue'},
                visible: true,
                seeds: [
                    4,
                    5,
                    12,
                ]
            },
        ];
    }

    /**
     * Get Pools of Template
     * @returns {Observable<TemplatePool[]>}
     */
    getPools(templateId: number = null): Observable<TemplatePool[]> {
        if (!templateId) {
            return Observable.of(this.pools);
        }
        return this.http
        .get(`${this.url}/${templateId}/pools`)
        .map((response: { pools: TemplatePool[] }) => response.pools);
    }

    /**
     * Get available groups
     * @returns {Observable<any | any>}
     */
    getGroups(): Observable<TemplatePoolGroup[]> {
        return this.http
        .get(`${env.API_BASE_URL}/pools/groups`)
        .map((response: { groups: TemplatePoolGroup[] }) => response.groups);
    }

    /**
     * Send save request to API server
     * @param {number} templateId
     * @param {Array<TemplatePool>} pools
     * @returns {Observable<{}>}
     */
    save(templateId: number, pools: TemplatePool[]) {
        const headers = new HttpHeaders(
            {'Authorization': `Bearer ${this.auth.getJWT()}`}
        );
        return this.http
        .put(`${this.url}/${templateId}/pools`, { pools }, { headers });
    }
}
