import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Facility, Court } from '../../interfaces';
import { FacilityService } from '../../services/facility/facility.service';

@Component({
    selector: 'bss-manage-courts',
    templateUrl: './manage-courts.component.html',
    styleUrls: ['./manage-courts.component.scss']
})
export class ManageCourtsComponent implements OnInit {
    courts: Court[] = [];
    eventId: number;
    facility: Facility;
    courtName = '';
    isLoading = false;

    constructor(
        public activeModal: NgbActiveModal,
        private facilityService: FacilityService
    ) { }

    ngOnInit() { }

    /**
     * Setup required properties of this modal
     * @param {number} eventId
     * @param {Facility} facility
     * @param {Court[]} courts
     */
    setup(eventId: number, facility: Facility, courts: Court[]) {
        this.eventId = eventId;
        this.facility = facility;
        this.courts = courts;
    }

    /**
     * Update the court name
     * @param $event
     * @param court
     */
    onValueChange($event, court) {
        court.name = $event.trim();
    }

    /**
     * Save facility object with the updated courts array
     */
    save() {
        this.isLoading = true;
        const payload = this.cleanPayload(this.facility);
        payload.courts = this.courts;
        this.facilityService
        .change(this.eventId, this.facility.id, payload)
        .subscribe(
            () => this.activeModal.close(true),
            err => console.error(err),
            () => this.isLoading = false
        );
    }

    /**
     * Append new Court object created from court name text field
     */
    addCourt() {
        this.courts.push({
            name: this.courtName.trim()
        });
        this.courtName = '';
    }


    /**
     * Remove an element from the this.courts array
     * @param {number} index
     */
    deleteCourt(index: number) {
        this.courts.splice(index, 1);
    }

    /**
     * Handle keypress events on court name text field
     * @param $event
     */
    onKeyPress($event) {
        if ($event.key === 'Enter' && this.courtName !== '') {
            this.addCourt();
        }
    }

    /**
     * Prepare facility object for API request
     * @param {Facility} facility
     * @returns {any}
     */
    private cleanPayload(facility: Facility) {
        const payload = JSON.parse(JSON.stringify(facility));
        payload.state_id = payload.state.id;
        delete payload.state;
        delete payload.id;
        return payload;
    }
}
