import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'bss-operator-settings-page',
    templateUrl: './settings.page.html',
    styleUrls: ['./settings.page.scss']
})
export class OperatorSettingsPage implements OnInit {
    constructor() {
    }

    ngOnInit() {
    }
}
