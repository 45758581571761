import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { NotificationsService } from '../../shared/services';

@Injectable()
export class ApiRequestsInterceptor implements HttpInterceptor {

    constructor(private notificator: NotificationsService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).do(event => {}, err => {
            if (err instanceof HttpErrorResponse) {
                if (err.status === 400) {
                    this.notificator.set400();
                } else if (err.status === 401) {
                    this.notificator.set401();
                } else if (err.status === 404) {
                    this.notificator.set404();
                } else if (err.status === 500) {
                    this.notificator.set500();
                }
            }
        });
    }
}
