import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Team, Division } from '../../interfaces';
import { EventService, DivisionService, TeamService } from '../../services';

@Component({
    selector: 'bss-division-teams',
    templateUrl: './division-teams.component.html',
    styleUrls: ['./division-teams.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DivisionTeamsComponent implements OnInit, OnChanges {
    @Input() eventId: number;
    @Input() division;
    @Input() moveMode = false;
    @Input() divisions = [];
    targetDivisionId: null;
    targetDivisions = [];
    targetTeams = [];
    teamIds: number[] = [];
    @Output() moved: EventEmitter<any> = new EventEmitter();
    @Output() changeMoveMode: EventEmitter<any> = new EventEmitter();
    isLoading = false;
    @Output() refresh: EventEmitter<any> = new EventEmitter();
    moveToNewMode = false;
    newDivisionName = '';

    constructor(
        private cdr: ChangeDetectorRef,
        private eventService: EventService,
        private divisionService: DivisionService,
        private teamService: TeamService) {}

    ngOnInit() {
        this.divisions.map(division => {
            division.teams
            .sort((a, b) => a.order - b.order);
        });
    }

    ngOnChanges () {
        // Leave out the current division from the list of target divisions
        this.targetDivisions = this.divisions.filter(d => d.id !== this.division.id);
        this.targetTeams = [];
        this.divisions.forEach(d => this.targetTeams = [...this.targetTeams, ...d.teams.filter(team => !team.active)]);
    }

    toggleMoveMode() {
        this.moveMode = !this.moveMode;
        this.changeMoveMode.emit({ moveMode: this.moveMode });
    }

    select(team: Team) {
        const index = this.teamIds.indexOf(team.id);
        if (index > -1) {
            team.selected = false;
            this.teamIds.splice(index, 1);
        } else {
            team.selected = true;
            this.teamIds.push(team.id);
        }
    }

    async bulkMove() {
        if (this.teamIds.length === 0) {
            return;
        }
        this.isLoading = true;
        try {
            if (this.moveToNewMode) {
                const divData = {
                    name: this.targetDivisionId,
                    gender_id: 1
                };
                const resp: any = await this.eventService.addDivision(this.eventId, divData).toPromise();
                this.divisions.push({ ...divData, id: resp.id });
                this.targetDivisionId = resp.id;
            }
            await this.divisionService.moveTeams(this.targetDivisionId, this.teamIds).toPromise();
            this.moved.emit({targetDivisionId: this.targetDivisionId});
            this.toggleMoveMode();
        } catch (err) {
            console.log(err);
        }
        this.isLoading = false;
    }

    cancel() {
        this.teamIds = [];
        this.division.teams.forEach(t => t.selected = false);
        this.moveMode = false;
        this.changeMoveMode.emit({ moveMode: this.moveMode });
    }

    onRenameTeam(team: Team, value) {
        this.isLoading = true;
        this.teamService.rename(this.eventId, team.id, value)
        .subscribe(
            () => {
                team.name = value;
            },
            err => {
                console.error(err);
                this.isLoading = false;
                this.cdr.detectChanges();
            },
            () => {
                this.isLoading = false;
                this.cdr.detectChanges();
            }
        );
    }

    onDropTeam(team: Team) {
        this.isLoading = true;
        this.divisionService.dropTeam(this.division.id, team.id)
        .subscribe(
            () => {
                const teamIndex = this.division.teams.findIndex(t => t.id === team.id);
                this.division.teams.splice(teamIndex, 1);
            },
            err => {
                console.error(err);
                this.cdr.detectChanges();
            },
            () => {
                this.isLoading = false;
                this.cdr.detectChanges();
            }
        );
    }

    /**
     * Replace a team for another one
     * @param {Team} team
     * @param {Team} targetTeam
     */
    onReplaceTeam(team: Team, targetTeam: Team) {
        this.isLoading = true;
        this.divisionService.replaceTeam(this.division.id, team.id, targetTeam.id)
        .subscribe(
            () => this.refresh.emit(),
            err => console.error(err),
            () => this.isLoading = false
        );
    }

    /**
     * Move a team to another division
     * @param {Team} team
     * @param {Division} targetDivision
     */
    onMoveToDivision(team: Team, targetDivision: Division) {
        this.isLoading = true;
        this.divisionService
        .moveTeams(targetDivision.id, [team.id])
        .subscribe(
            () => this.refresh.emit(),
            err => console.error(err),
            () => this.isLoading = false
        );
    }
}
