import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import {
    Template,
    Division,
    ScheduleGame,
    PoolSchedule,
    TemplateSeed,
} from '../../interfaces';
import { InternalBreadcrumbItem } from '../../../shared/interfaces';
import { ConfirmationDialog } from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { PoolService, TemplateService, GameService, AuthService } from '../../services';
import { DivisionsPage } from '../divisions/divisions.page';
import { environment as env } from '../../../../environments/environment';

@Component({
    selector: 'bss-template-pool-schedules',
    templateUrl: './pool-schedules.page.html',
    styleUrls: ['./pool-schedules.page.scss']
})
export class PoolSchedulesPage implements OnInit {
    template: Template;
    poolSchedules: PoolSchedule[];
    division: Division;
    dragging: ScheduleGame;
    isLoading = false;
    breadcrumbItems: InternalBreadcrumbItem[];
    draggingTeam: any = false;
    isBracketOnly: boolean;
    explainerText: string;
    canDeleteGames: Boolean;


    constructor(
        protected ngbModal: NgbModal,
        private poolService: PoolService,
        private templateService: TemplateService,
        private gameService: GameService,
        private authService: AuthService,
        private route: ActivatedRoute) {
    }

    ngOnInit() {
        if (this.route.parent.parent.component === DivisionsPage) {
            this.route.parent.parent.data.subscribe(async data => {
                this.division = data.division;
                this.canDeleteGames = this.division && (this.authService.isAdmin() || env.DELETE_GAMES_OPERATOR_IDS.includes(this.authService.getOperatorId()));
                const tplInfo = data.division.template;
                if (tplInfo.id) {
                    this.template = await this.templateService.getById(tplInfo.id).toPromise() as Template;
                    this.refresh();
                }
            });
        } else {
            this.route.parent.data.subscribe(async (data) => {
                this.template = data.template;
                this.refresh();
            });
        }
    }

    async refresh() {
        this.isLoading = true;
        this.isBracketOnly = (this.template.play_type === 'bracket');
        if (!this.isBracketOnly) {
            try {
                const schedulesData: any = await this.poolService.getSchedules(this.template.id).toPromise();
                this.poolSchedules = schedulesData.schedules;
                this.setBreadcrumbsItems();
            } catch (err) {
                console.error(err);
            }
        }
        this.isLoading = false;
    }

    async deleteGame(game) {
        const modalRef = this.ngbModal.open(ConfirmationDialog);
        modalRef.componentInstance.title =
            `Are you sure you wish to delete game ${game.game_number}? You will not be able to add this game back after deleting.`;
        modalRef.result.then(async response => {
            if (response) {
                this.isLoading = true;
                try {
                    await this.gameService.deleteGame(game.id).toPromise();
                    this.refresh();
                } catch (err) {
                    console.error(err);
                } finally {
                    this.isLoading = false;
                }

            }
        });
    }

    /**
     * Move Teams of a game to another position inside the PoolSchedule
     * @param {PoolSchedule} sourcePoolSchedule
     * @param {number} sourceGamePosition
     * @param {PoolSchedule} targetPoolSchedule
     * @param {number} targetGamePosition
     */
    async move(
        sourcePoolSchedule: PoolSchedule,
        sourceGamePosition: number,
        targetPoolSchedule: PoolSchedule,
        targetGamePosition: number) {
        if (sourcePoolSchedule.id === targetPoolSchedule.id && sourceGamePosition !== targetGamePosition) {
            this.isLoading = true;
            try {
                await this.poolService
                    .move(targetPoolSchedule.id, sourcePoolSchedule.games[sourceGamePosition].id, targetPoolSchedule.games[targetGamePosition].id)
                    .toPromise();

                const poolGamesSubset = sourcePoolSchedule.games.map(p => {
                    return { home: p.home_seed.team, away: p.away_seed.team };
                });
                const deleted = poolGamesSubset.splice(sourceGamePosition, 1);
                poolGamesSubset.splice(targetGamePosition, 0, deleted[0]);
                // poolGamesSubset = poolGamesSubset.filter(game => !!game);
                for (let i = 0; i < poolGamesSubset.length; i++) {
                    sourcePoolSchedule.games[i].home_seed.team = poolGamesSubset[i].home;
                    sourcePoolSchedule.games[i].away_seed.team = poolGamesSubset[i].away;
                }
            } catch (err) {
                console.error(err);
            }
            this.isLoading = false;
        }
    }

    /**
     * Set the breadcrumbs items of the page
     */
    setBreadcrumbsItems() {
        this.breadcrumbItems = [
            { alias: this.division ? 'DIVISIONS' : 'TEMPLATES', link: '../..' },
            { alias: this.division ? this.division.name : this.template.name },
            { alias: 'POOL SCHEDULES', active: true }
        ];
    }

    /**
     * Returns the name of the team if the provided seed has a team linked to it. Otherwise returns seed name
     * @param seed
     */
    getTeamName(seed: TemplateSeed) {
        return seed.team ? seed.team.name : 'Seed ' + seed.overall_rank;
    }

    /**
     * Returns the score of a team if this team is attached in the provided seed
     * @param seed
     */
    getTeamScore(seed: any) {
        return seed.team && typeof (seed.team.score) === 'number' && !isNaN(seed.team.score) ? seed.team.score : '';
    }

    /**
     * Returns a string to explain the reason the delete icon is disabled
     * @param startDateExists
     * @param scoreExists
     */
    createExplainerText(startDateExists: Boolean, scoreExists: Boolean) {
        let popoverText = 'You can\'t delete a game';
        if (startDateExists) { popoverText += ' that is scheduled'; }
        if (startDateExists && scoreExists) { popoverText += ' or'; }
        if (scoreExists && !startDateExists) { popoverText += ' that'; }
        if (scoreExists) { popoverText += ' has a score'; }
        popoverText += '.';
        return popoverText;
    }


}
