import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

@Component({
    selector: 'bss-editable-box',
    templateUrl: './editable-box.component.html',
    styleUrls: ['./editable-box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditableBoxComponent implements OnInit, OnChanges {
    @Input() value = '';
    @Input() isVolatile = false;
    @Input() showLeftIcon = false;
    @Input() isEditable = true;
    @Input() leftIcon = 'fa-sign-out';
    @Output() valueChange: EventEmitter<string> = new EventEmitter();
    editingMode = false;
    currentValue = '';

    constructor(
        private cdr: ChangeDetectorRef
    ) { }

    ngOnInit() {
    }

    save() {
        this.valueChange.emit(this.currentValue);
        this.editingMode = false;
        if (this.isVolatile) {
            this.currentValue = this.value;
        }
    }

    cancel() {
        this.currentValue = this.value;
        this.editingMode = false;
    }

    ngOnChanges() {
        this.currentValue = this.value;
    }

    /**
     * Handle keydown events on input field and delegate to specific methos
     * @param $event
     */
    keydownAction($event) {
        if ($event.key === 'Enter') {
            this.save();
        } else if ($event.key === 'Escape') {
            this.cancel();
        }
    }
}
