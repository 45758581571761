import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DataService } from '../common/data.service';
import { environment as env } from '../../../../environments/environment';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class GameService extends DataService {
    constructor(http: HttpClient, private authService: AuthService) {
        super(`${env.API_BASE_URL}/games`, http, authService);
    }

    getGame(gameId: number) {
        return this.http.get(`${env.API_BASE_URL}/games/${gameId}`);
    }

    getGames(eventId: number) {
        return this.http
            .get(`${env.API_BASE_URL}/events/${eventId}/games`);
    }

    deleteGame(gameId: number) {
        const headers = new HttpHeaders(
            { 'Authorization': `Bearer ${this.authService.getJWT()}` }
        );

        return this.http.delete(`${env.API_BASE_URL}/games/${gameId}`, { headers });
    }

    putGame(game) {
        const headers = new HttpHeaders(
            { 'Authorization': `Bearer ${this.authService.getJWT()}` }
        );

        const gameId = `${game.id}`;

        delete game.id;

        return this.http
            .put(`${env.API_BASE_URL}/games/${gameId}`, game, { headers });
    }

    putGames(games) {
        const headers = new HttpHeaders(
            { 'Authorization': `Bearer ${this.authService.getJWT()}` }
        );

        return this.http
            .put(`${env.API_BASE_URL}/games`, games, { headers });
    }

    scoreGames(eventId: number, divisionId: number, games) {
        const headers = new HttpHeaders(
            { 'Authorization': `Bearer ${this.authService.getJWT()}` }
        );

        return this.http.put(`${env.API_BASE_URL}/events/${eventId}/divisions/${divisionId}/games`, games, { headers });
    }
}
