import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PoolSchedule, Division, ScheduleGame, Team } from '../../../scheduler/interfaces';
import { PoolService, DivisionService } from '../../../scheduler/services';
import { NavbarService } from '../../services';

interface GameData extends ScheduleGame {
    break: boolean;
}

@Component({
    selector: 'bss-public-team-schedule',
    templateUrl: './team-schedule.page.html',
    styleUrls: ['./team-schedule.page.scss']
})
export class TeamSchedulePage implements OnInit {
    gameid: Number;
    games: GameData[];
    isEventReady: boolean;
    eventId: number;
    teamid: number = null;
    team: Team;
    divisionid: number;
    division: Division;
    isLoading = false;
    divisionObservable: Subscription;

    constructor(private route: ActivatedRoute,
        private router: Router,
        public navbarService: NavbarService,
        public modalService: NgbModal,
        private poolService: PoolService,
        private divisionService: DivisionService,
        private datePipe: DatePipe) {
    }

    ngOnInit() {
        this.gameid = this.route.snapshot.params.gameid;
        this.teamid = this.route.snapshot.params.teamid;
        this.divisionid = this.route.parent.snapshot.params.divisionId;
        this.eventId = this.route.parent.parent.snapshot.params.eventId;
        this.refresh();
    }

    async refresh(withLoadingEffect = true) {
        this.isLoading = withLoadingEffect;
        try {
            this.division = await this.divisionService.getById(this.divisionid + '').toPromise() as Division;
            const scheduleData = await this.poolService.getSchedules(this.division.template_id).toPromise();
            const gamesdata = await this.extractTeamsAndDates(this.teamid, scheduleData.schedules);
            this.games = gamesdata.sort((a, b) => a.start_date > b.start_date ? 1 : -1);
            this.setDateBreaks();
        } catch (err) {
            console.log(err);
        }
        finally {
            this.isLoading = false;
        }
    }

    setDateBreaks() {
        let breakDate = new Date(1900, 1, 1, 0, 0, 0);

        for (const g of this.games) {
            let curDate = new Date(g.start_date);
            curDate = new Date(curDate.getFullYear(), curDate.getMonth(), curDate.getDate(), 0, 0, 0, 0);

            if (breakDate.toDateString() !== curDate.toDateString()) {
                g.break = true;
                breakDate = curDate;
            }
        }
    }

    async extractTeamsAndDates(teamid: Number, poolSchedules: PoolSchedule[]) {
        const games = [];
        this.team = null;
        poolSchedules.forEach(ps => {
            ps.games.forEach(g => {
                if (Number(teamid) === Number(g.home_seed.id) || Number(teamid) === Number(g.away_seed.id)) {
                    if (this.team || true && Number(teamid) === Number(g.home_seed.id)) {
                        this.team = g.home_seed.team;
                    } else if (this.team || true && Number(teamid) === Number(g.away_seed.id)) {
                        this.team = g.away_seed.team;
                    }
                    const gd = g as GameData;
                    gd.break = false;
                    games.push(gd);
                }
            });
        });
        return games;
    }
}
