import {
    Component,
    OnInit,
    OnChanges,
    Input,
    EventEmitter,
    Output,
} from '@angular/core';
import {
    TemplateSeed as Seed,
    GameBlock,
    Bracket,
    SourceSelectorOptions,
} from '../../interfaces';

@Component({
    selector: 'bss-source-selector',
    templateUrl: './source-selector.component.html',
    styleUrls: ['./source-selector.component.scss']
})
export class SourceSelectorComponent implements OnInit, OnChanges {
    @Input() options: SourceSelectorOptions;
    v: any;
    seeds: Seed[] = [];
    gameBlocks: GameBlock[] = [];
    @Input() teamName: string;
    @Input() bracketsRef: Bracket[] = [];
    @Output() change = new EventEmitter();
    isReady = true;

    constructor() {
        this.options = {sources: {}};
        this.options.sourceSelection = 'Team...';
        this.options.sources.pools = [];
        this.options.sources.gameBlocks = [];
        this.options.sources.teams = [];
        this.options.sources.teamsByPoints = null;
    }

    ngOnInit() {
        if (this.options.sources.pools && this.options.sources.teams && this.options.sources.teamsByPoints) {
            this.refreshDropdowns();
            this.isReady = true;
        }
    }

    ngOnChanges() {
        this.ngOnInit();
    }

    get allBlocks() {
        if (this.bracketsRef.length > 0) {
            return this.bracketsRef.reduce((acc, x) => acc.concat(x.gameBlocks), []);
        }
        return [];
    }

    refreshDropdowns() {
        if (!this.options.disablePoolAndPoints) {

            let crossedPool;
            this.options.sources.pools = this.options.sources.pools.map(p => {
                if (p.cross_pool && p.cross_pool.id) {
                    crossedPool = this.options.sources.pools
                        .filter(c => c.id === p.cross_pool.id)[0];
                    if (crossedPool) {
                        if (p.crossed == null || !p.crossed) {
                            p.name = p.name + ' + Pool ' + crossedPool.name;
                            p.crossed = true;
                            const currentSeeds = p.seeds
                                .sort((a, b) => a.pool_rank < b.pool_rank ? -1 : 1);
                            const lastRank = currentSeeds[currentSeeds.length - 1];
                            const crossedSeeds = crossedPool.seeds
                                .sort((a, b) => a.pool_rank < b.pool_rank ? -1 : 1);

                            crossedSeeds.map((cs, i) => {
                                cs.pool_rank = lastRank.pool_rank + (i + 1);
                            });
                            p.seeds = p.seeds.concat(crossedSeeds);
                        }
                        const index = this.options.sources.pools.indexOf(crossedPool);
                        if (index > -1) {
                            this.options.sources.pools.splice(index, 1);
                        }
                    }
                }
                return p;
            });

            // for full seeds list
            this.seeds = ([].concat.apply([], this.options.sources.pools.map(p => {
                return p.seeds;
            }))).sort((a, b) => a.overall_rank < b.overall_rank ? -1 : 1);

            // for seeds per pool
            this.options.sources.pools.map(p => {
                p.seeds.sort((a, b) => a.pool_rank < b.pool_rank ? -1 : 1);
            });

        }
        this.gameBlocks = this.options.sources.gameBlocks.sort((a, b) => a.number < b.number ? -1 : 1);
    }

    /**
     * @param event
     * @param type
     * @param label
     * @param value
     */
    onSourceSelectorClick(event, type, label, value) {
        this.change.emit({
            type,
            label,
            value
        });
    }
}
