import { Injectable } from '@angular/core';
import { Settings } from '../../../interfaces';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../../auth/auth.service';
import { environment as env } from '../../../../../environments/environment';

@Injectable()
export class OperatorSettingsService {

    accetableConfiguration = true;

    constructor(private http: HttpClient, private auth: AuthService) {
    }

    /**
     * Return the global settings
     * @returns {Observable<Settings>}
     */
    getSettings(): Observable<Settings> {
        const headers = new HttpHeaders(
            {'Authorization': `Bearer ${this.auth.getJWT()}`}
        );
        return this.http
            .get(env.API_BASE_URL + '/operators/me/page/details', {headers: headers})
            .map((data: any) => {
                return data.page;
            });
    }

    saveSettings(settings) {
        const formData: any = new FormData();
        formData.append('logo_1', settings.logo_1);
        formData.append('logo_2', settings.logo_2);
        formData.append('logo_3', settings.logo_3);
        formData.append('social_media', settings.social_media);
        formData.append('notes', settings.notes);
        const headers = new HttpHeaders(
            {'Authorization': `Bearer ${this.auth.getJWT()}`}
        );
        return this.http
            .post(env.API_BASE_URL + '/operators/me/page/details', formData, {headers});
    }

}
