import { Component, Input } from '@angular/core';
import { InternalBreadcrumbItem } from '../../interfaces';
@Component({
    selector: 'bss-internal-breadcrumbs',
    templateUrl: './internal-breadcrumbs.component.html',
    styleUrls: ['./internal-breadcrumbs.component.scss']
})
export class InternalBreadcrumbsComponent {
    @Input() items: InternalBreadcrumbItem[] = [];

    constructor() { }
}
