import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal, NgbTimepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { MatSlideToggleChange } from '@angular/material';
import * as moment from 'moment';
import { GameService } from '../../services';
import { Game, TemplateSeed, Team } from '../../interfaces';
import * as _ from 'lodash';

@Component({
    selector: 'bss-edit-game-block',
    templateUrl: './edit-game-block.component.html',
    styleUrls: ['./edit-game-block.component.scss']
})
export class EditGameBlockComponent implements OnInit {
    eventDates = [];
    game: Game;
    gameLength: number;
    facilities: any[];
    seeds = <TemplateSeed[]>[];

    public editGameBlockForm = new FormGroup({
        homeTeam: new FormControl(null, {}),
        awayTeam: new FormControl(null, {}),
        homeTeamScore: new FormControl(null, {
            validators: [Validators.min(0)]
        }),
        awayTeamScore: new FormControl(null, {
            validators: [Validators.min(0)]
        }),
        notes: new FormControl(null, {}),
        lockTeamAndGameTime: new FormControl(null, {}),
        status: new FormControl(null, {}),
        startDate: new FormControl(null, {}),
        startTime: new FormControl(null, {}),
        court: new FormControl(null, {})
    });

    constructor(public modal: NgbActiveModal, private gameService: GameService, private config: NgbTimepickerConfig) {
        config.spinners = false;
        config.meridian = true;
    }

    ngOnInit() {
        if (this.game) {
            const game = this.game;

            if (game.seed1) {
                this.seeds.push(game.seed1);
            }

            if (game.seed2) {
                this.seeds.push(game.seed2);
            }

            const startDate = moment.utc(game.start_date);

            const eventDate = this.eventDates.find(eD => {
                return eD.date() === startDate.date();
            });

            this.editGameBlockForm.setValue({
                homeTeam: _.get(game, 'seed1.id', null),
                awayTeam: _.get(game, 'seed2.id', null),
                homeTeamScore: _.get(game, 'seed1.team.score', null),
                awayTeamScore: _.get(game, 'seed2.team.score', null),
                notes: game.notes,
                lockTeamAndGameTime: game.locked,
                status: game.status,
                startDate: eventDate.toISOString() || null,
                startTime: {
                    hour: startDate.hours(),
                    minute: startDate.minutes(),
                    second: 0
                },
                court: game.court.id
            });
        }
    }

    onLockTeamAndGameTimeChange($event: MatSlideToggleChange) {
        const checked = $event.checked;

        this.editGameBlockForm.controls['lockTeamAndGameTime'].setValue(checked);
    }

    onTeamSelect(type: string) {
        const controlName = `${type}Team`;
        const remainingControlName = `${type === 'away' ? 'home' : 'away'}Team`;

        // When either away/home team changes, swap the other team
        const selectedTeam = this.editGameBlockForm.controls[controlName].value;
        const remainingTeam = this.seeds.find(s => s.id !== parseInt(selectedTeam, 10));

        if (selectedTeam && remainingTeam) {
            this.editGameBlockForm.controls[remainingControlName].setValue(remainingTeam.id);
        }
    }

    onSubmit() {
        const values = this.editGameBlockForm.value;
        const seed1Index = this.seeds.findIndex(s => s.id === parseInt(values.homeTeam, 0));
        const seed2Index = this.seeds.findIndex(s => s.id === parseInt(values.awayTeam, 0));

        if (seed1Index !== -1) {
            this.game.seed1 = this.seeds[seed1Index];
            if (this.game.seed1.team) {
                this.game.seed1.team.score = values.homeTeamScore;
            }
        }

        if (seed2Index !== -1) {
            this.game.seed2 = this.seeds[seed2Index];
            if (this.game.seed2.team) {
                this.game.seed2.team.score = values.awayTeamScore;
            }
        }

        this.game.team_1_score = values.homeTeamScore;
        this.game.team_2_score = values.awayTeamScore;
        this.game.notes = values.notes;
        this.game.locked = values.lockTeamAndGameTime;
        this.game.status = values.status;
        this.game.court.id = parseInt(values.court, 0);

        const startTime = values.startTime;
        const startDate = moment.utc(values.startDate);
        startDate.hours(startTime.hour || 0);
        startDate.minutes(startTime.minute || 0);
        startDate.seconds(0);

        const startDateFormatted = startDate.toISOString();
        this.game.start_date = startDateFormatted;

        const endDate = moment.utc(startDate);
        endDate.add(this.gameLength, 'minutes');

        const endDateFormatted = endDate.toISOString();
        this.game.end_date = endDateFormatted;

        this.modal.close(this.game);
    }
}
