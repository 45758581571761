import { Component } from '@angular/core';
import { AuthService } from './scheduler/services/auth/auth.service';
import { OnInit } from '@angular/core/src/metadata/lifecycle_hooks';
import { environment } from '../environments/environment';

@Component({
    selector: 'bss-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [ AuthService ]
})
export class AppComponent implements OnInit {
    enableHotjar = environment.production ? true : false;
    constructor(private auth: AuthService) {
    }

    title = 'bss';

    ngOnInit() {
    }
}
