import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { from } from 'rxjs';
import { TemplateService, TemplatePoolService } from '../../services';
import { TemplatePool, TemplateSeed, TemplatePoolGroup, Template, Division } from '../../interfaces';
import { ConfirmationDialog } from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { AddTemplatePoolComponent } from '../../components/add-template-pool/add-template-pool.component';
import { AddTemplatePoolGroupComponent } from '../../components/add-template-pool-group/add-template-pool-group.component';
import { DivisionsPage } from '../divisions/divisions.page';
import { InternalBreadcrumbItem } from '../../../shared/interfaces';
import { NotificationsService } from '../../../shared/services';

@Component({
    selector: 'bss-template-pools',
    templateUrl: './template-pools.page.html',
    styleUrls: ['./template-pools.page.scss']
})
export class TemplatePoolsPage implements OnInit {
    isLoading = false;
    defaultPools: TemplatePool[];
    defaultGroups: TemplatePoolGroup[] = [];
    pools: TemplatePool[] = [];
    staging: TemplatePool;
    groups: any[];
    stagingBarPosition = 3;
    lastOverallRank = 0;
    template: Template;
    invalidPoolSize = true;
    division: Division;
    breadcrumbItems: InternalBreadcrumbItem[];
    newPoolGroupAssignment = false;
    changeTeamOfSeed = true;
    crossPoolPlayChanged = false;
    isBracketOnly: boolean;

    constructor(
        private poolService: TemplatePoolService,
        private templateService: TemplateService,
        private modalService: NgbModal,
        private route: ActivatedRoute,
        public notificator: NotificationsService) { }

    ngOnInit() {
        this.staging = {
            name: 'Staging',
            visible: false,
            group: null,
            seeds: [],
            crossed: null
        };
        if (this.route.parent.parent.component === DivisionsPage) {
            this.route.parent.parent.data.subscribe(async  data => {
                this.division = data.division;
                this.template = await this.templateService.getById(data.division.template_id).toPromise() as Template;
                this.refresh();
            });
        } else {
            this.route.parent.data.subscribe(data => {
                this.template = data.template;
                this.refresh();
            });
        }
    }

    /**
     * Request Pools and Groups through API
     * @returns {Promise<void>}
     */
    async refresh() {
        this.isLoading = true;
        this.isBracketOnly = (this.template.play_type === 'bracket');
        if (!this.isBracketOnly) {
            try {
                this.defaultPools = await this.poolService.getPools(this.template.id).toPromise();
                this.changeTeamOfSeed = !!this.defaultPools.find(pool => !!pool.seeds.find(seed => !!seed.team));
                this.defaultGroups = await this.poolService.getGroups().toPromise();
                this.setBreadcrumbsItems();
                this.reset();
            } catch (err) {
                console.error(err);
            }
        }
        this.isLoading = false;
    }

    /**
     * Move all seeds to staging area
     */
    clear() {
        this.pools.map(pool => {
            this.staging.seeds = this.staging.seeds.concat(pool.seeds);
            pool.seeds = [];
        });
    }

    /**
     * Rollback all pools and seeds to default (persisted) state
     */
    reset() {
        this.pools = JSON.parse(JSON.stringify(this.defaultPools));
        this.lastOverallRank = 0;
        this.pools.forEach(p => {
            const max = Math.max.apply(null, p.seeds.map(s => s.overall_rank));
            this.lastOverallRank = max > this.lastOverallRank ? max : this.lastOverallRank;
            // Sort seeds by the order property
            p.seeds.sort((s1, s2) => s1.order - s2.order);
            // Set the name of each cross_pool
            if (p.cross_pool && !p.cross_pool.name) {
                const crossPool = this.pools.find(xp => xp.id === p.cross_pool.id);
                if (crossPool) {
                    p.cross_pool.name = crossPool.name;
                    crossPool.cross_pool = { id: p.id, name: p.name };
                }
            }
        });
        this.staging.seeds = [];
        this.groups = JSON.parse(JSON.stringify(this.defaultGroups));
        this.newPoolGroupAssignment = false;
    }

    /**
     * Add a seed to a pool or to staging
     * @param {TemplatePool} pool
     * @param {SeedToPoolEvent} $event
     */
    onAddSeed(pool: TemplatePool, $event: SeedToPoolEvent = null) {
        if ($event) {
            const seedIndex = $event.sourcePool.seeds.findIndex(s => s.id === $event.seed.id);
            const fromSeed = $event.sourcePool.seeds.find(s => s.id === $event.seed.id);
            if (this.changeTeamOfSeed) {
                const fromTeam = fromSeed.team;
                fromSeed.team = pool.seeds[$event.index].team;
                pool.seeds[$event.index].team = fromTeam;
                this.validateSizeOfPools();
                return;
            } else {
                $event.sourcePool.seeds.splice(seedIndex, 1);
                pool.seeds.splice($event.index, 0, $event.seed);

            }
        } else {
            this.lastOverallRank++;
            pool.seeds.push({
                id: 0 - (new Date().getMilliseconds()),
                overall_rank: this.lastOverallRank,
            });
        }
        this.updateOrderProperties();
        this.validateSizeOfPools();
    }

    /**
     * Validate minimum amount of seeds per pool
     */
    validateSizeOfPools() {
        const invalidPools = this.pools.filter(p => p.seeds.length < 3);
        if (invalidPools.length > 0) {
            return this.invalidPoolSize = true;
        }
        return this.invalidPoolSize = false;
    }

    /**
     * Update property order of seeds
     */
    updateOrderProperties() {
        this.pools.forEach(pool => {
            pool.seeds.map((seed: TemplateSeed, index) => {
                const incrIndex = index + 1;
                seed.order = incrIndex;
                seed.pool_rank = incrIndex;
            });
        });
    }

    /**
     * Delete a seed from a pool and send it to staging
     * @param {number} seed
     * @param {TemplatePool} pool
     */
    onDeleteSeed(seed: TemplateSeed, pool: TemplatePool) {
        const seedIndex = pool.seeds.findIndex(s => s.id === seed.id);
        pool.seeds.splice(seedIndex, 1);
        this.staging.seeds.push(seed);
    }

    /**
     * Delete a pool
     * @param {TemplatePool} pool
     */
    onDeletePool(pool: TemplatePool, tpComponent) {
        const modalRef = this.modalService.open(ConfirmationDialog);
        modalRef.componentInstance.title = 'Are you sure that you want to delete this pool?';
        from(modalRef.result)
            .subscribe(yes => {
                if (yes) {
                    tpComponent.removeCrossPoolPlay();
                    if (pool.seeds.length) {
                        while (pool.seeds.length > 0) {
                            if (pool.seeds[0]) {
                                this.staging.seeds.push(pool.seeds[0]);
                                pool.seeds.splice(0, 1);
                            }
                        }
                    }

                    const poolIndex = this.pools.findIndex(p => p.id === pool.id);
                    if (poolIndex > -1) {
                        this.pools.splice(poolIndex, 1);
                    }
                }
            });
    }

    /**
     * Remove unnecessary data to be sent to API
     * @param {TemplatePool[]} pools
     * @returns {TemplatePool[]}
     */
    private cleanPayload(pools: TemplatePool[]): TemplatePool[] {
        const payload = [];
        pools.forEach(pool => {
            if (pool.seeds.length === 0) {
                return;
            }
            const seeds = pool.seeds.map(s => {
                const seed: any = {
                    id: s.id > 0 ? s.id : undefined,
                    overall_rank: s.overall_rank,
                    pool_rank: s.pool_rank,
                    order: s.order
                };
                if (this.changeTeamOfSeed && s.team) {
                    seed.team_id = s.team.id;
                }
                return seed;
            });
            payload.push({
                id: pool.id > 0 ? pool.id : undefined,
                name: pool.name,
                visible: pool.visible,
                seeds: seeds,
                group: pool.group ? { name: pool.group.name } : null,
                cross_pool: pool.cross_pool ? { name: pool.cross_pool.name } : null,
            });
        });
        return payload;
    }

    /**
     * Send request for updating pools
     */
    async save() {
        if (this.staging.seeds.length || this.invalidPoolSize) {
            return;
        }
        this.isLoading = true;
        try {
            const payload = this.cleanPayload(this.pools);
            await this.poolService.save(this.template.id, payload).toPromise();
            this.refresh();
        } catch (err) {
            console.error(err);
        }
        this.isLoading = false;
    }

    /**
     * Open Modal for editing or adding TemplatePool objects
     * @param {TemplatePool} pool
     */
    addOrEditPool(pool: TemplatePool = null) {
        const modalRef = this.modalService.open(AddTemplatePoolComponent);
        modalRef.componentInstance.setup(this.groups, pool);
        from(modalRef.result)
            .subscribe(
                (modifiedPool: TemplatePool) => {
                    if (!modifiedPool) {
                        return;
                    }
                    if (modifiedPool.id) {
                        const poolIndex = this.pools.findIndex(p => p.id === modifiedPool.id);
                        this.pools[poolIndex] = { ...modifiedPool };
                    } else {
                        modifiedPool.seeds = [];
                        modifiedPool.id = 0 - (new Date().getUTCMilliseconds());
                        this.pools.push(modifiedPool);
                    }
                    if (modifiedPool.group) {
                        this.addGroup(modifiedPool.group);
                    }
                    this.validateSizeOfPools();
                }
            );
    }

    /**
     * Add new TemplatePoolGroup object to the list of template pool groups
     * @param {TemplatePoolGroup} group
     */
    addGroup(group: TemplatePoolGroup) {
        if (this.groups.findIndex(g => g.name === group.name) === -1) {
            this.groups.push(group);
        }
    }

    /**
     * Link a Pool to a Group object
     * @param {TemplatePool} pool
     */
    addPoolToGroup(pool: TemplatePool) {
        const modalRef = this.modalService.open(AddTemplatePoolGroupComponent);
        modalRef.componentInstance.setup(this.groups, pool);
        from(modalRef.result)
            .subscribe(
                (modifiedPool: TemplatePool) => {
                    if (modifiedPool && modifiedPool.group) {
                        const poolIndex = this.pools.findIndex(p => p.id === modifiedPool.id);
                        this.pools[poolIndex] = { ...modifiedPool };
                        this.newPoolGroupAssignment = true;
                        this.addGroup(modifiedPool.group);
                        this.validateSizeOfPools();
                    }
                }
            );
    }

    /**
     * Set the breadcrumbs items of the page
     */
    setBreadcrumbsItems() {
        this.breadcrumbItems = [
            { alias: this.division ? 'DIVISIONS' : 'TEMPLATES', link: '../..' },
            { alias: this.division ? this.division.name : this.template.name },
            { alias: 'POOL', active: true },
        ];
    }

    /**
     * Detect when a group has being removed from a pool and set a flag
     * @param pool
     */
    onRemoveGroup(pool) {
        this.newPoolGroupAssignment = true;
        this.validateSizeOfPools();
    }

    onCrossPoolPlayChange($event) {
        this.crossPoolPlayChanged = true;
        this.validateSizeOfPools();
    }

    isSaveButtonDisabled() {
        return (this.staging.seeds.length > 0 || this.invalidPoolSize) && !(this.newPoolGroupAssignment || this.crossPoolPlayChanged);
    }

    clearErrorMessages() {
        this.notificator.clear400();
        this.notificator.clear401();
        this.notificator.clear404();
        this.notificator.clear500();
    }

    /**
     * Returns an array of objects with the name of pools set for Cross Pool Play
     */
    get crossPoolPlays() {
        const crossPools: { left: string, right: string }[] = [];
        this.pools.forEach(p => {
            if (p.cross_pool && !crossPools.find(xp => xp.left === p.name || xp.right === p.name)) {
                crossPools.push({
                    left: p.name,
                    right: p.cross_pool.name,
                });
            }
        });
        return crossPools;
    }
}

interface SeedToPoolEvent {
    seed: TemplateSeed;
    sourcePool: TemplatePool;
    index: number;
}
