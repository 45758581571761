import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment as env } from '../../../../environments/environment';
import { DataService } from '../common/data.service';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class TeamService extends DataService {

    constructor(http: HttpClient, private authService: AuthService) {
        super(`${env.API_BASE_URL}/teams`, http, authService);
    }

    public rename(eventId: number, teamId: number, name: string): Observable<any> {
        const headers = new HttpHeaders(
            {'Authorization': `Bearer ${this.authService.getJWT()}`}
        );
        return this.http
        .put(`${env.API_BASE_URL}/events/${eventId}/teams/${teamId}`, { name }, { headers })
        .catch(this.handleError);
    }
}
